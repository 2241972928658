<div class="label">{{ 'ADDITIONAL.LABEL.proceed' | translate }}</div>

<div class="loading" *ngIf='loading'>
  <fa-icon [icon]="['far', 'circle-notch']" [spin]="true"></fa-icon>
</div>

<div #PaylineWidget class="payline-widget-container" [ngClass]="{'none': loading}">
  <div id="PaylineWidget" class="payline-widget" [attr.data-token]="token" data-template="tab"
        data-event-didshowstate="statusChange" data-event-finalstatehasbeenreached="status" data-embeddedredirectionallowed="false">
  </div>
</div>

