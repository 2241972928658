import { Component, Injectable } from '@angular/core';
import { trigger, transition, style, animate } from "@angular/animations";

var _extract = str => str;
_extract("NOTIFICATION.success");
_extract("NOTIFICATION.info");
_extract("NOTIFICATION.warning");
_extract("NOTIFICATION.error");

@Component({
    selector: 'notification',
    templateUrl: './notification.html',
    styleUrls: ['./notification.scss'],
    animations: [
        trigger('fadeIn', [
            transition(':enter', [style({ opacity: '0' }), animate('.5s ease-out', style({ opacity: '1' }))])
        ]),
        trigger('fadeOut', [
            transition(':leave', [style({ opacity: '1' }), animate('.5s ease-out', style({ opacity: '0' }))])
        ])
    ]
})
export class Notification {
    public notifications: Array<any> = [];

    constructor() {}

    public addNotification(title: string, type: string, duration: number, closeIcon: boolean) {
        let id = Math.random().toString()
        this.notifications.push({
            id: id,
            title: title,
            type: type, // success, info, warning, error
            icon: type === 'success' ? 'check' : type === 'info' ? 'info' : type === 'warning' ? 'exclamation-triangle' : 'times',
            closeIcon: closeIcon,
            style: ''
        });

        if (duration !== 0) {
            setTimeout(() => {
                this.removeNotification(id);
            }, duration);
        }
    }

    public removeNotification(id: string) {
        this.notifications.map((value, index) => {
            if (value.id === id) {
                this.notifications.splice(index, 1);
            }
            return true;
        });
    }

    public clearNotifications() {
        this.notifications = [];
    }
}

@Injectable({
    providedIn: 'root'
})
export class NotificationManager {

    constructor() {}

    private notification: Notification;

    public setNotificationElement(el: Notification) {
        this.notification = el;
    }

    public addNotification(title: string, type: string, duration: number, closeIcon: boolean) {
        if (this.notification) this.notification.addNotification(title, type, duration, closeIcon);
    }

    public clearNotifications() {
        if (this.notification) this.notification.clearNotifications();
    }
}
