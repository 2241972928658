<div class="header" *ngIf="reservation">
    <div class="title">{{ 'RESERVATION.reservation_id' | translate:{value:reservation?.id} }}</div>
    <div class="subtitle">
        {{ 'RESERVATION.STATUS.WAITING_FOR_DEPOSIT'| translate }}
    </div>
</div>

<!-- Dates -->
<div class="card" *ngIf="reservation">
    <div class="dates">
        <fa-icon class="calendar" [icon]="['fas', 'calendar-day']"></fa-icon>
        <div class="date">
            <span>{{ reservation?.start | date:'dd MMM yyy' }}</span>
            <span>{{ reservation?.start | date:'HH:mm' }}</span>
        </div>
        <fa-icon class="arrow" [icon]="['far', 'long-arrow-right']"></fa-icon>
        <div class="date">
            <span>{{ reservation?.end | date:'dd MMM yyy' }}</span>
            <span>{{ reservation?.end | date:'HH:mm' }}</span>
        </div>
    </div>
</div>

<!-- Deposit -->
<div class="card deposit-infos warning-card" *ngIf="reservationID">
    <deposit [reservation-id]="reservationID" [opened]="cards['deposit']"></deposit>
</div>

<!-- Payment -->
<div class="payment">
    <div class="label">{{ 'RESERVATION.CLIENT.CONFIRM.deposit' | translate }}</div>
    <div class="checkbox">
        <label (click)="cguAccepted = !cguAccepted">
            <span class="checkmark">
                <fa-icon [icon]="['far', 'check']" [ngClass]="{ 'checked': cguAccepted }"></fa-icon>
            </span>
            {{ 'RESERVATION.CLIENT.DETAILS.accept' | translate }}
        </label>
        <a style="margin-left: 5px;" href="assets/help/CONDITIONS_GENERALES_DE_LOCATION_DECEMBRE_2021.pdf " target="_blank">{{ 'RESERVATION.CLIENT.DETAILS.cgv' | translate }}</a>
    </div>
    <div class="checkbox">
        <label (click)="nameConfrmed = !nameConfrmed">
            <span class="checkmark">
                <fa-icon [icon]="['far', 'check']" [ngClass]="{ 'checked': nameConfrmed }"></fa-icon>
            </span>
            {{ 'RESERVATION.CLIENT.DETAILS.confrmed' | translate }}
        </label>
    </div>
    <div class="confirm" *ngIf="reservationID">
        <div class="overlay" *ngIf="!cguAccepted || !nameConfrmed" (click)="warningCGU()"></div>
        <transaction #refAnanasSeed type="deposit" [prices]="prices" [reservation-id]="reservationID" (CGUClicked)="clickedCGU($event)"></transaction>
    </div>
</div>

<!-- Pricing -->
<div class="card pricing" *ngIf="reservationID">
    <pricing [reservation-id]="reservationID" [opened]="cards['pricing']"></pricing>
</div>

<!-- Vehicle -->
<div class="card vehicle-infos" *ngIf="vehicleID">
    <vehicle [vehicle-id]="vehicleID" [opened]="cards['vehicle']"></vehicle>
</div>

<!-- Location -->
<div class="card agency-infos" *ngIf="accessID">
    <access-point [id]="accessID"></access-point>
</div>
