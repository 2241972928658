<div class="check-documents" [ngClass]="style.ngClass" *ngIf="(documentStatus !== 'VERIFIED' || profileStatus !== 'VALID' || path === 'user') && !loading">
    <div class="infos" [ngClass]="{ 'centered': documentStatus === 'VERIFIED' && profileStatus === 'VALID' }">
        <div class="label">
            <div class="icon" *ngIf="style.icon">
                <fa-icon [icon]="['far', style.icon]"></fa-icon>
            </div>
            <div class="text">
                <span>{{ 'DOCUMENTS.' + documentStatus | translate }}</span>
                <span *ngIf="profileStatus !== 'VALID'">{{ 'DOCUMENTS.' + profileStatus | translate }}</span>
            </div>
        </div>
        <hr class="separator" *ngIf="documentStatus === 'NOT_VERIFIED' || documentStatus === 'EXPIRED' || profileStatus === 'MISSING_INFORMATIONS'">
        <div class="label-action">
            <span *ngIf="documentStatus === 'NOT_VERIFIED' || documentStatus === 'EXPIRED'">{{ 'DOCUMENTS.verify_documents' | translate }}</span>
            <span *ngIf="profileStatus === 'MISSING_INFORMATIONS'">{{ 'DOCUMENTS.fill_profile' | translate }}</span>
        </div>
    </div>
    <div class="button-action" *ngIf="(documentStatus === 'NOT_VERIFIED' || documentStatus === 'EXPIRED') && path === 'user'" (click)="checkDocuments()">
        <fa-icon [icon]="['far', 'arrow-right']" *ngIf="!action"></fa-icon>
        <fa-icon [icon]="['far', 'sync']" [spin]="true" *ngIf="action"></fa-icon>
    </div>
    <div class="button-action" *ngIf="(documentStatus === 'NOT_VERIFIED' || documentStatus === 'EXPIRED' || profileStatus === 'MISSING_INFORMATIONS') && path !== 'user'" (click)="goToProfile()">
        <fa-icon [icon]="['far', 'arrow-right']"></fa-icon>
    </div>
</div>
