import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';

import { AccessPoint } from 'src/app/interfaces/models/access';

@Injectable({
    providedIn: "root"
})
export class AccessService {
    constructor(private http: HttpClient) {}

    public getAccess(id: string): Observable<AccessPoint> {
        return this.http.get<AccessPoint>(environment.BFF + "access/" + id);
    }

    public getAccessList(): Observable<AccessPoint[]> {
        return this.http.get<AccessPoint[]>(environment.BFF + "accesslist");
    }

    public getBookingCategoryAvailable(input: any) : Observable<any[]> {
        return this.http.post<any[]>(environment.BFF + "bookingcategoryavailability", input);
    }
}
