import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import * as moment from 'moment';

import { BookingService } from 'src/app/services/booking';
import { ItemService } from 'src/app/services/item';
import { ReservationService } from 'src/app/services/reservation';
import { HeaderMenuService } from 'src/app/services/header-menu';

import { Categories } from 'src/app/interfaces/categories';
import { TransactionService } from 'src/app/services/transaction';

@Component({
    selector: 'list',
    templateUrl: './list.html',
    styleUrls: ['./list.scss']
})
export class ClientListComponent implements OnInit {
    public loading: boolean = true;
    public categories = Categories;

    public reservations: any = {
        current: [],
        future: [],
        previous: [],
        canceled: []
    };

    constructor(
        private router: Router,
        private bookingService: BookingService,
        private itemService: ItemService,
        private reservationService: ReservationService,
        private headerMenuService: HeaderMenuService,
        private transactionService: TransactionService
    ) {}

    async ngOnInit() {
        this.headerMenuService.setShowMenu(true);
        this.headerMenuService.setHeader({
            color: 'default',
            showLogo: true,
            showBack: false,
            showIcon: true
        });

        this.getReservations();
    }

    getReservations() {
        this.reservationService.getReservationList().subscribe((response) => {
            console.log("Reservation list :", response.result);
            let reservations = response.result;
            if (!reservations) return this.loading = false;

            let today = moment(new Date());

            
            // Check if attribute.name == ISVLSBOOKING in reservations
            if (reservations.filter(r => r.attributes?.filter(a => a.name === "ISVLSBOOKING").length).length) {
                reservations = reservations.filter(r => r.attributes?.filter(a => a.name === "ISVLSBOOKING").length == 0);
                
             }
             this.getVehicles(reservations).then((reservations) => {

                // Separate reservations in 4 lists
                for (let i = 0; i < reservations.length; i++) {
                    if (reservations[i].status === 'CLOSED') {
                        this.reservations.previous.push(reservations[i]);
                    } else if (reservations[i].status === 'CANCELED') {
                        this.reservations.canceled.push(reservations[i]);
                    } else if (reservations[i].status === 'OPEN') {
                        if (today.isAfter(reservations[i].start) && today.isBefore(reservations[i].end)) {
                            this.reservations.current.push(reservations[i]);
                        } else {
                            reservations[i].warning = 'late';
                            this.reservations.current.push(reservations[i]);
                        }
                    } else if (reservations[i].status === 'WAITING') {
                        this.isDepositPaid(reservations[i].id).then((isPaid) => {
                            if (today.isAfter(reservations[i].start) && today.isBefore(reservations[i].end)) {
                                if (!isPaid) {
                                    reservations[i].warning = 'deposit';
                                    this.reservations.future.push(reservations[i]);
                                } else {
                                    this.reservations.current.push(reservations[i]);
                                }
                            } else if (today.isBefore(reservations[i].start)) {
                                if (!isPaid) reservations[i].warning = 'deposit';
                                this.reservations.future.push(reservations[i]);
                            } else {
                                reservations[i].warning = 'expired';
                                this.reservations.previous.push(reservations[i]);
                            }
                        });
                    }

                    // CLOSED       User has done the check-out and is no more allowed to use the item. Item is available 5 min after closing a reservation for other reservations.
                    // CANCELED 	An unlocked status used to cancel a reservation. Item is available 5 min after closing a reservation for other reservations.
                    // OPEN         Reservation is notified paid user has done the check-in and is allowed to use the item.
                    // WAITING      Reservation is waiting for payment or has been notified paid but reservation start date has not arrived yet.
                    // RESERVED     Used to confirm the completion of a reservation with or without a payment. No other reservations are allowed for this same item and timeframe.

                    // 'late': status === 'OPEN, date dépassée
                    // 'deposit': status === 'WAITING', en cours,
                    // 'expired': status === 'WAITING', passée
                }

                // Ordering the entries
                this.reservations.current = this.reservations.current.sort((a, b) => moment(a.start).diff(b.start) * -1);
                this.reservations.previous = this.reservations.previous.sort((a, b) => moment(a.start).diff(b.start) * -1);
                this.reservations.future = this.reservations.future.sort((a, b) => moment(a.start).diff(b.start) * -1);

                // Showing results
                this.loading = false;
            });
             
            
        });
    }

    getVehicles(reservations): Promise<any> {
        return new Promise<any>(async (resolve) => {
            let promises = [];
            for (let i = 0; i < reservations.length; i++) {
                // Get Vehicle if defined or Category
                if (moment(reservations[i].start).isValid() && moment(reservations[i].end).isValid()) {
                    if (reservations[i].itemId && reservations[i].itemId !== 'NOT_DEFINED') {
                        promises.push(this.itemService.getItem({ id: reservations[i].itemId }).toPromise().then((response) => {
                            reservations[i].vehicleInfos = response.result;
                        }));
                    } else if (reservations[i].bookingId) {
                        promises.push(this.bookingService.getBooking({ id: reservations[i].bookingId }).toPromise().then((response) => {
                            reservations[i].category = response.result.category;
                        }));
                    }
                }
            }

            Promise.all(promises).then(() => {
                resolve(reservations);
            });
        });
    }

    checkIfIsiracNumber(reservations){
        if (reservations.filter(r => r.attributes?.filter(a => a.name === "CLIENT_RESERVATION_ID").length).length) {
            reservations = reservations.filter(r => r.attributes?.filter(a => a.name === "CLIENT_RESERVATION_ID").length == 0);
            
        }
    }

    seeBooking(reservation) {
        // TODO: replace by better logic once status READY is integrated in API
        if (reservation.status === "WAITING") {
            if (reservation.warning === 'deposit') {
                this.router.navigate(['deposit'], { queryParams: { id: reservation.id } });
            } else {
                this.router.navigate(['detail'], { queryParams: { id: reservation.id } });
            }
        } else {
            this.router.navigate(['detail'], { queryParams: { id: reservation.id } });
        }
    }

    // TODO: remove once status READY is integrated in API
    isDepositPaid(id: string): Promise<boolean> {
        return new Promise<boolean>((resolve) => {
            this.transactionService.getTransactionList({ id: id }).toPromise().then(response => {
                if (response.length > 0) {
                    if (response.filter(t => t.type === "DEPOSIT" && t.amount.value < 0).length) resolve(true);
                    else resolve(false);
                } else {
                    resolve(false);
                }
            });
        });
    }
}
