import { Injectable } from '@angular/core';
import { Moment } from 'moment';

@Injectable({
    providedIn: 'root',
})
export class ReservationStorage {
    private dates: {
        startDate: Moment;
        endDate: Moment;
    };
    private searchInfos: {
        onlyPresto: boolean;
        categories: any[];
    };
    private agency: any = null;
    private vehicle: any = null;
    private options: any = null;

    private storage = window.localStorage;

    constructor() {
        if (this.storage) {
            this.dates = {
                startDate: JSON.parse(this.storage.getItem('startDate')),
                endDate: JSON.parse(this.storage.getItem('endDate')),
            };
            this.agency = JSON.parse(this.storage.getItem('agency'));
            this.vehicle = JSON.parse(this.storage.getItem('vehicle'));
            this.options = JSON.parse(this.storage.getItem('options'));
        }
    }

    public getDates(): any {
        return this.dates;
    }
    public setDates(newStartDate, newEndDate): void {
        this.dates = {
            startDate: newStartDate,
            endDate: newEndDate,
        };
        this.storage.setItem('startDate', JSON.stringify(newStartDate));
        this.storage.setItem('endDate', JSON.stringify(newEndDate));
    }

    public getAgency(): any {
        return this.agency;
    }
    public setAgency(newAgency): void {
        this.agency = newAgency;
        this.storage.setItem('agency', JSON.stringify(newAgency));
    }

    public getVehicle(): any {
        return this.vehicle;
    }
    public setVehicle(newVehicle): void {
        this.vehicle = newVehicle;
        this.storage.setItem('vehicle', JSON.stringify(newVehicle));
    }

    public getOptions(): any {
        return this.options;
    }
    public setOptions(newOptions): void {
        this.options = newOptions;
        this.storage.setItem('options', JSON.stringify(newOptions));
    }
    public getCategory(): any {
        return JSON.parse(this.storage.getItem('category'));
    }
    public setCategory(newcategory): void {
        this.storage.setItem('category', JSON.stringify(newcategory));
    }

    public makeReservation() {}

    public getBooking(): any {
        return JSON.parse(this.storage.getItem('booking'));
    }

    public setBooking(newBooking): void {
        this.storage.setItem('booking', JSON.stringify(newBooking));
    }

    public cleanInformations(): void {
        this.storage.removeItem('dates');
        this.storage.removeItem('agency');
        this.storage.removeItem('options');
        this.storage.removeItem('category');
    }
}
