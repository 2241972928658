import {
  Component,
  ViewChild,
  ElementRef,
  OnInit,
  Input
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { Transactions } from 'src/app/interfaces/models/additional';

import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';

import { NotificationManager } from 'src/app/notification/notification';
import { LinkService } from 'src/app/services/link';
import { TransactionService } from 'src/app/services/transaction';
import { LanguageService } from 'src/app/services/language';

import { environment } from 'src/environments/environment';
import { BookingService } from 'src/app/services/booking';

interface Window {
  onStatusChange: Function;
}

@Component({
  selector: 'app-link-payment-widget',
  templateUrl: './payment-widget.html',
  styleUrls: ['./payment-widget.scss'],
})
export class LinkPaymentWidgetComponent implements OnInit {
  @Input() total: number;
  @Input() transactionsData;
  @ViewChild('PaylineWidget', { static: false }) paylineWidget: ElementRef;

  public reservationID: number;
  public token: string;
  public window: Window;
  public loading: boolean;
  public counter = 0

  public transactionDone: boolean = false;

  constructor(
    private translateService: TranslateService,
    private route: ActivatedRoute,
    private router: Router,
    private transaction: TransactionService,
    private additionalPayment: LinkService,
    private languageService: LanguageService,
    private notificationManager: NotificationManager,
    private bookingService: BookingService,
  ) {
    this.reservationID = this.route.snapshot.queryParams.reservationID;
    this.token = this.route.snapshot.queryParams.token;
  }

  ngOnInit(): void {
    this.loading = false;

    window['onStatusChange'] = (params) => {
      console.log(params.state);
      switch (params.state) {
        case "PAYMENT_METHODS_LIST":
          this.additionalPayment.verifyTokenPaymentLink({ token: this.token, reservationID: this.reservationID, transactions: this.transactionsData }).toPromise().then((res) => {
            console.log(res)
          }).catch((e) => {
            console.log(e)
            this.router.navigate(['map'])
          })
          break;
        case "PAYMENT_SUCCESS":
            this.loading = true;
            this.recurse(this)
           
          break;
        case "PAYMENT_FAILURE":
          localStorage.reservationID = this.reservationID;
          localStorage.error = "true";
          break;
        case "TOKEN_EXPIRED":
          this.router.navigate([`additional-payment`], { queryParams: { reservationID: this.reservationID } });
          break;
      };
    }

    this.initLink();
  }

  async initLink() {
    let input = {
      reservationId: this.reservationID,
      AppInformation: {
        onSuccess: window.location.origin + "/additional-payment/confirm",
        onCancel: window.location.href,
        language: this.languageService.getLang().type.toLowerCase(), // enum [en, fr]
        colorDepth: 48,
        screen: {
          width: 500,
          height: 500
        }
      }
    };

    this.additionalPayment.getLinkToken(input).subscribe(res => {
      if (res.token === '') {
        this.setWidget();
      } else {
        this.token = res.token;
        this.setWidget();
      }
    });

  }

  setWidget() {
    let script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.text = `function statusChange(status) { window['onStatusChange'](status) }; function status(status) { window['onStatus'](status) };`;
    this.paylineWidget.nativeElement.appendChild(script);

    let library = document.createElement('script');
    library.src = environment.paylineBaseUrl + '/scripts/widget-min.js';
    library.type = 'text/javascript';
    library.async = false;
    this.paylineWidget.nativeElement.appendChild(library);

    let style = document.createElement('link');
    style.setAttribute('href', environment.paylineBaseUrl + '/styles/widget-min.css');
    style.setAttribute('rel', 'stylesheet');
    this.paylineWidget.nativeElement.appendChild(style);
  }

  recurse(self) {
    this.counter++;
    if (this.counter <= 30) {
      this.bookingService.getSessionByResa(self.reservationID).toPromise().then((res) => {
        console.log(JSON.stringify(res))
        if (res.linkTransactionPaid) {
          self.loading = false;
          localStorage.reservationID = this.reservationID;
          localStorage.setItem("transactionsDetails", JSON.stringify(this.transactionsData));
          localStorage.total = this.total;
          localStorage.done = true;
        } else if (res.error) {
          self.loading = false;
          this.notificationManager.addNotification(this.translateService.instant("INTERFACES.ERROR.API.unknown"), "error", 5000, true);
          this.router.navigate(['map']);
        } else {
          setTimeout(() => {
            this.recurse(self)
          }, 1000);
        }
       
      }).catch(e => {
        setTimeout(() => {
          this.recurse(self)
        }, 1000);
      });

    } else {
      self.loading = false;
      this.router.navigate(['list-bookings'])
    }

  }

}
