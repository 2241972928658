import {
    Component,
    OnInit,
    AfterViewInit,
    ViewChild,
    ChangeDetectorRef,
    HostListener
} from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { FormControl } from '@angular/forms';

import { LanguageService } from './services/language';
import { HeaderMenuService } from './services/header-menu';

import { Notification, NotificationManager } from './notification/notification';
import { ReservationService } from './services/reservation';
import { TransactionService } from 'src/app/services/transaction';
import { UserService } from './services/user';
import { KeycloakService } from 'keycloak-angular';

import { environment } from 'src/environments/environment';
import * as moment from 'moment';
import { SessionService } from './services/session';
import { ReservationStorage } from './services/reservation.storage';
import { ModalManager } from './modal/global/global';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'carmoov-front',
    templateUrl: './carmoov-front.component.html',
    styleUrls: ['./carmoov-front.component.scss']
})
export class CarmoovFrontComponent implements OnInit, AfterViewInit {
    @ViewChild('notification', { static: false }) notificationEl: Notification;

    public isLoggedIn: boolean;
    public userGroup: string;
    public startDate : any;

    // @HostListener('document:visibilitychange') visibilitychange() {
    //     if (!document.hidden) {
    //         if (this.startDate !== null) {
    //             if((moment(this.startDate).valueOf()-Date.now()) < -(15*60*1000)){
    //                 this.modalManager.openModal(this.translateService.instant("GENERIC.warning"), this.translateService.instant("RESERVATION.search-expire"), {},false, false, async () => {
    //                     console.log("yes")
    //                 }, () => {
    //                     localStorage.removeItem('startDate')
    //                     this.router.navigate(['map'])
    //                 });
    //             }
    //         }
    //     }
    // }

    public headerMenuData: {
        showMenu: boolean;
        header: {
            color: string;
            showLogo: boolean;
            showBack: boolean;
            showIcon: boolean;
        };
        menuOptions: {
            mode: string;
            disableClose: boolean;
            opened: boolean;
        };
    };

    public languageList: any;
    public currentLanguage = new FormControl();
    public alerts: any = {
        waitingForDepositNumber: 0,
        lateReservationNumber: 0,
        isProfileValid: true
    };

    constructor(
        private router: Router,
        private location: Location,
        private cdRef: ChangeDetectorRef,
        private languageService: LanguageService,
        private headerMenuService: HeaderMenuService,
        private notificationManager: NotificationManager,
        private keycloakService: KeycloakService,
        private userService: UserService,
        private reservationService: ReservationService,
        private transactionService: TransactionService,
        private sessionService: SessionService,
        private reservationStorage: ReservationStorage,
        private modalManager : ModalManager,
        private translateService: TranslateService,
    ) {
        this.languageList = this.languageService.getLanguageList();
        let currentLanguage = this.languageService.getLang();
        for (let i = 0; i < this.languageList.length; i++) {
            if (currentLanguage.type === this.languageList[i].type) {
                this.currentLanguage.setValue(this.languageList[i]);
            }
        }

        this.languageService.getObservable().subscribe((lang) => {
            for (let i = 0; i < this.languageList.length; i++) {
                if (lang.type === this.languageList[i].type) {
                    this.currentLanguage.setValue(this.languageList[i]);
                }
            }
        });

        this.headerMenuData = this.headerMenuService.getHeaderMenuData();
        this.headerMenuService.getHeaderMenuObserver().subscribe((data) => {
            this.headerMenuData = data;
            this.cdRef.detectChanges();
        });

        window.addEventListener("resize", () => {
            this.headerMenuService.setMenuOptions(this.isLoggedIn);
        });

        this.router.routeReuseStrategy.shouldReuseRoute = () => {
            return false;
        };

        this.keycloakService.isLoggedIn().then(logged => {
            console.log('keycolck=================================================+++>', logged)
            if (logged) {
                let startNavigation = localStorage.getItem('startNavigation');
                if (startNavigation == null) {
                    localStorage.setItem('startNavigation', Date.now().toString());
                } else {
                    if ((Date.now() - parseInt(startNavigation)) > (12*60*60*1000)) {
                        // this.modalManager.openModal(this.translateService.instant("GENERIC.warning"), this.translateService.instant("RESERVATION.session-expire"), {},false, false, async () => {
                        //     console.log("yes")
                        // }, () => {
                        //     localStorage.clear();
                        //     //this.keycloakService.clearToken()
                        //     this.keycloakService.logout(environment.URL_BACK);
                        // });
                    } else {
                        localStorage.setItem('startNavigation', Date.now().toString()); 
                    }
                }
            }

            if (logged === false) {
                this.keycloakService.clearToken();
                // localStorage.removeItem('sessionId');
                let sessionId = localStorage.getItem('sessionId');
                if (sessionId === null) {
                    this.sessionService.getSessionId().subscribe(id =>{
                        localStorage.setItem('sessionId', id.sessionId);
                    });
                }
            }
        });
    }

    async ngOnInit() {

        this.startDate = this.reservationStorage.getDates().startDate;
        

        this.router.events.subscribe(async (event) => {
            this.isLoggedIn = await this.keycloakService.isLoggedIn();
            this.userGroup = localStorage.getItem('userGroup');
            if (event['routerEvent']) {
                let removeMenuOnPages = !event['routerEvent'].url.startsWith('/document') && !event['routerEvent'].url.startsWith('/payment') && !event['routerEvent'].url.startsWith('/vehicle');
                this.headerMenuService.setMenuOptions(this.isLoggedIn && removeMenuOnPages);
            }
        });

        // Handle Payment token to call for force update
        let getQueryStringValue = (key) => {
            return decodeURIComponent(window.location.search.replace(new RegExp("^(?:.*[&\\?]" + encodeURIComponent(key).replace(/[\.\+\*]/g, "\\$&") + "(?:\\=([^&]*))?)?.*$", "i"), "$1"));
        }
        let token = getQueryStringValue('token');
        if (token) {
            let bookingId = localStorage.getItem('idBooking');
            let reservationId = localStorage.getItem('reservationId');
            let payment = localStorage.getItem('payment');
            let deposit = localStorage.getItem('deposit');
            let TREEDS = localStorage.getItem('3DS');
            // paydeposit remove
            let email_deposit = localStorage.getItem('email_deposit');

            // console.log("if (token): ", bookingId, reservationId, payment, deposit);

            const urlParams = new URLSearchParams(location.search); urlParams.delete('token');
            let PAYMENT_FAILURE = localStorage.getItem("PAYMENT_FAILURE");
            const url = `${location.href.split('?')[0]}`;
            let path = url.split('/');
            let urlPath = path[path.length - 1];
            console.log("urlPath=============================================================>", urlPath);
            if (urlPath == 'deposit' && PAYMENT_FAILURE !== "true") {
                if (TREEDS != "true"){
                    if (payment === "true" ) {
                        
                        this.router.navigate(['deposit'], { queryParams: { id: reservationId } });
                    } else {
                        // paydeposit condition
                        // can delete if statement except the line inside else
                        if (payment === "deposit") {
                            localStorage.removeItem("email_deposit");
                            this.router.navigate(['token/deposit', { queryParams: { id: reservationId, email : email_deposit }}]);
                            localStorage.setItem('payment', "false");
                        } else {
                            // keep the line under (?????????????????????????????????????????????????????????????????????)
                            this.router.navigate(['payment'], { queryParams: { id: bookingId } });
                        }
                    }
                }


            } else if (urlPath == 'detail' && PAYMENT_FAILURE !== "true") {
                // console.log("detail 3DS =========>");
                if (deposit == "true") {
                    localStorage.removeItem('startDate')
                    this.router.navigate(['detail'], { queryParams: { id: reservationId } });
                    localStorage.setItem('deposit', "false");
                    localStorage.setItem('3DS', "false");
                } else {
                    this.router.navigate(['deposit'], { queryParams: { id: reservationId } });
                }
            } else if (PAYMENT_FAILURE === "true") {
                console.log("urlPath=============================================================>", urlPath);
                if (urlPath == 'deposit'){
                    localStorage.setItem("PAYMENT_FAILURE", "false");
                    this.router.navigate(['vehicle'])
                }else if (urlPath == 'detail'){
                    localStorage.setItem("PAYMENT_FAILURE", "false");
                    this.router.navigate(['list-bookings'])
                }

                
            }
        }
        this.keycloakService.isLoggedIn().then(logged => {
            if (logged === true) {
                this.checkMenuAlerts();
            }
        })
        // this.checkMenuAlerts();
    }

    ngAfterViewInit() {
        this.notificationManager.setNotificationElement(this.notificationEl);
    }

    back() {
        this.location.back();
    }

    toggleMenu(menu) {
        if (!this.headerMenuData.menuOptions.disableClose) {
            menu.toggle();
        }
    }

    checkMenuAlerts() {
        this.userService.getUser().toPromise().then((response) => {
            let user = response.result;
            if (!user.address || !user.phoneNumber || !user.gender || !user.documents.length) {
                this.alerts.isProfileValid = false;
            } else if (user.documents.length) {
                for (let i = 0; i < user.documents.length; i++)
                    if (!user.documents[i].expirationDatetime && user.documents[i].status !== "VERIFIED") {
                        this.alerts.isProfileValid = false;
                    }
            }

            this.reservationService.getReservationList().subscribe((response) => {
                let reservations = response.result;
                let today = moment(new Date());
                if (reservations.filter(reservation => reservation.attributes?.filter(attribute => attribute.name === "ISVLSBOOKING").length).length) {
                    if (reservations.length > 0) {
                        reservations = reservations.filter(reservation => reservation.attributes?.filter(attribute => attribute.name === "ISVLSBOOKING").length == 0);
                    }

                    for (let i = 0; i < reservations.length; i++) {
                        if (reservations[i].status === 'OPEN') {
                            if (!today.isAfter(reservations[i].start) || !today.isBefore(reservations[i].end)) {
                                this.alerts.lateReservationNumber += 1;
                            }
                        } else if (reservations[i].status === 'WAITING') {
                            this.isDepositPaid(reservations[i].id).then((isPaid) => {
                                if (!isPaid && (today.isBefore(reservations[i].start) || (today.isAfter(reservations[i].start) && today.isBefore(reservations[i].end))))
                                    this.alerts.waitingForDepositNumber += 1;
                            });
                        }
                    }
                }
            });
        }).catch((e) => {
            this.alerts.isProfileValid = false;
        });
    }

    // TODO: remove once status READY is integrated in API
    isDepositPaid(id: string): Promise<boolean> {
        return new Promise<boolean>((resolve) => {
            this.transactionService.getTransactionList({ id: id }).toPromise().then(response => {
                if (response.length > 0) {
                    if (response.filter(t => t.type === "DEPOSIT" && t.amount.value < 0).length) resolve(true);
                    else resolve(false);
                } else {
                    resolve(false);
                }
            });
        });
    }

    onAuthChange($event, menu) {
        if (!$event || !$event.detail || !$event.detail.user) {
            this.router.navigate(['login']);
            menu.close();
        }
    }

    onLanguageChange() {
        this.languageService.setLang(this.currentLanguage.value['type']);
    }

    onActivate() {
        window.scroll(0,0);
        document.querySelector("mat-sidenav-content").scrollTo(0, 0);
    }

    doSignIn() {
        //localStorage.clear();
        this.keycloakService.clearToken()
        this.keycloakService.login();
    }


    doSignOut() {
        localStorage.clear();
        this.keycloakService.clearToken()
        this.keycloakService.logout(environment.URL_BACK);
    }
}
