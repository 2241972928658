import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';

@Injectable()
export class BookingService {

    constructor(private http: HttpClient) {}

    public getBooking(input: any): Observable<any> {
        return this.http.get<any>(environment.BFF + "booking/" + input.id);
    }

    public getPrice(input: any) : Observable<any[]> {
        return this.http.post<any[]>(environment.BFF + "booking/price", input);
    }

    public getSession(id: string): Observable<any> {
        return this.http.get<any>(environment.BFF + "booking/" + id + "/getsession");
    }
    public getSessionByResa(id: string): Observable<any> {
        return this.http.get<any>(environment.BFF + "booking/" + id + "/getsessionresa");
    }

    public createBooking(input: any): Observable<any> {
        return this.http.post<any>(environment.BFF + "booking", input);
    }

    public updateBooking(input: any): Observable<any> {
        return this.http.put<any>(environment.BFF + "booking/"+input.id,{});
    }

    public addOptionToSession(input: any): Observable<any> {
        return this.http.post<any>(environment.BFF + "booking/addoptiontosession", input);
    }

    public itemAvailability(input: any): Observable<any> {
        return this.http.post<any>(environment.BFF + "bookingitemavailability", input);
    }

    public itemListAvailability(input: any): Observable<any> {
        return this.http.post<any>(environment.BFF + "bookingitemlistavailability", input);
    }
}
