import { Component, OnInit, Input } from '@angular/core';

import PricingDetails from "src/app/components/link/pricing.json";
import { Transactions } from 'src/app/interfaces/models/additional';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
    selector: 'app-link-payment-pricing',
    templateUrl: './pricing.html',
    styleUrls: ['./pricing.scss'],
})
export class LinkPaymentPricingComponent implements OnInit {
    @Input() total: number;
    @Input() transactionsData;

    public reservationID: string;
    public details: any = {};

    public addedDriver = [];
    public newDriverLength = 0;

    public done: boolean = false;
    public isOpened: boolean = true;

    constructor(
        private route : ActivatedRoute,
        private router: Router,
    ) {}

    ngOnInit(): void {
        if (localStorage.done) {
            this.done = localStorage.done;
            this.reservationID = localStorage.reservationID;
            localStorage.removeItem('done');
            localStorage.removeItem('reservationID');
        } else {
            this.reservationID = this.route.snapshot.queryParams.reservationID;
        }

        // console.log(this.transactionsData);

        this.setDetails(this.transactionsData);
        this.affectTransactionToDriver(this.transactionsData);
    }

    setDetails(datas: Transactions[]) {
        // console.log("setDetails data :", datas);

        datas.forEach(t => {
            for (let r in PricingDetails) {
                let reason = PricingDetails[r];
                if (t.label.indexOf(reason.label) === 0 ) {
                    if (t.amount.value > 0) {
                        let actualValue = this.details[reason.name];
                        if (actualValue) {
                            actualValue.amount += t.amount.value / 100;
                            let newQuantity = this.getQuantity(t);
                            actualValue.datas.quantity += newQuantity.quantity;
                            actualValue.datas.duration += newQuantity.duration;
                        } else {
                            let newDetail = reason;
                            newDetail.datas = {};
                            newDetail.amount = t.amount.value/100;
                            let newQuantity = this.getQuantity(t);
                            newDetail.datas.quantity = newQuantity.quantity;
                            newDetail.datas.duration = newQuantity.duration;

                            this.details[reason.name] = newDetail;
                        }
                    }
                }
            }
        });
    }

    getQuantity(transac: Transactions) {
        if (transac.label.indexOf("ADDED_DRIVER") === 0 || transac.label.indexOf("NEW_DRIVER") === 0) {
            let indexQ = transac.label.indexOf("Q");
            let indexX = transac.label.indexOf("X");
            let indexL = transac.label.indexOf("L");
            let quantity = parseFloat(transac.label.substring(indexQ+1,  indexX));
            let duration = parseFloat(transac.label.substring(indexX+1, indexL));
            let length = parseFloat(transac.label.substring(indexL+1));
            return {quantity : quantity, duration : duration, length : length};
        } else {
            let index = transac.label.indexOf("Q");
            let quantity = 0;
            if (index > 0) {
                quantity = parseFloat(transac.label.substring(index + 1));
            }
            // let res = (Math.round(parseFloat(quantity) * 100) / 100);
            return {quantity : quantity, duration : 0};
        }
    };

    affectTransactionToDriver(liste) {
        liste.forEach((item) => {
            // ADDED_DRIVER case
            if (item.label.indexOf("ADDED_DRIVER") === 0) {
                let itemQuantity = this.getQuantity(item)
                for (let i = 0; i < itemQuantity.quantity; i++) {
                    if (this.addedDriver[i] && this.addedDriver[i].amount){
                        this.updateDriverObject(item, itemQuantity, i);
                    } else {
                        let index = i;
                        this.createDriverObject(item, itemQuantity, i)
                    }
                }
            } else {
                // NEW_DRIVER case
                if (item.label.indexOf("NEW_DRIVER") === 0) {
                    let itemQuantity = this.getQuantity(item)
                    // init null object for previous driver
                    for (let i = 0; i < itemQuantity.length - 1; i++) {
                        if (!this.addedDriver[i]) {
                            this.addedDriver[i] = {};
                        }
                    }
                    // multiple new driver in the same transaction case
                    if (itemQuantity.quantity > 1) {
                        for (let i = 0; i < itemQuantity.quantity; i++){
                            let index = itemQuantity.length + i - 1;
                            if (this.addedDriver[index]) {
                                this.updateDriverObject(item, itemQuantity, index);
                            } else {
                                this.createDriverObject(item, itemQuantity, index)
                            }
                        }
                    } else {
                    // default case
                    let index = itemQuantity.length-1
                        if (this.addedDriver[index]) {
                            this.updateDriverObject(item, itemQuantity, index);
                        } else {
                            this.createDriverObject(item, itemQuantity, index);
                        }
                    }
                }
            }
        });
    }

    createDriverObject(item, value, index){
        this.addedDriver[index] = {
            amount : (item.amount.value / value.quantity) / 100,
            quantity : 1,
            duration : value.duration
        }
    }

    updateDriverObject(item, value, index) {
        this.addedDriver[index].amount += (item.amount.value / value.quantity) / 100;
        this.addedDriver[index].quantity += 1;
        this.addedDriver[index].duration += value.duration;
    }

    toggleCard() {
        this.isOpened = !this.isOpened;
    }

    goToKey() {
        this.router.navigate(['detail'], { queryParams: {  id: this.reservationID } });
    }
}
