// Angular modules
import "@angular/compiler";
import { CommonModule } from '@angular/common';
import { HttpClientModule, HttpClientJsonpModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, APP_INITIALIZER } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { GoogleMapsModule } from '@angular/google-maps'
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';

// Library modules
import { Ng2TelInputModule } from 'ng2-tel-input';
import { NgxMaskModule } from 'ngx-mask';
import { CalendarModule } from 'primeng/calendar';
import { NgPipesModule } from 'ngx-pipes';
import * as innerHeight from 'ios-inner-height';
import { NgxImageCompressService } from 'ngx-image-compress';

// Main modules
import { AppRoutingModule } from './app-routing.module';
import { CarmoovFrontComponent } from './carmoov-front.component';

// New BFF Services
import { AccessService } from './services/access';
import { BookingService } from './services/booking';
import { BoxService } from './services/box';
import { CheckService } from './services/check';
import { ItemService } from './services/item';
import { OptionService } from './services/option';
import { PlanningService } from './services/planning';
import { ReservationService } from './services/reservation';
import { SessionService } from "./services/session";
import { TransactionService } from './services/transaction';
import { UbbleService } from './services/ubble';
import { UserService } from './services/user';
import { initializer } from './services/keycloakInit';
import { LinkService } from './services/link';
import { PayDepositService } from './services/paydeposit';

import TrackingService from './utils/trackingService';

// Resolvers
import { ReservationResolver } from './resolvers/reservation.resolver';

// Utilities
import { GMapLoader } from './services/gmapLoader';
import { HeaderMenuService } from './services/header-menu';
import { AuthGuardKeycloak } from './services/authGuardKeycloak'


// Directives
import { ImageCompressed } from './directives/img-cpr';
import { HeaderInterceptorService } from './interceptor/interceptor'

// Modals & Notifications
import { GlobalModal, ModalManager } from './modal/global/global';
import { Notification, NotificationManager } from './notification/notification';
import { ErrorManager } from './error/error';

// Pipes
import { AgencyPipe, CategoryPipe, PlatePipe, NamePipe, BookingPipe, MarkerPipe } from './services/filter.pipe';

// Angular Material import
import { MatInputModule } from "@angular/material/input";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatButtonModule } from "@angular/material/button";
import { MatDialogModule } from "@angular/material/dialog";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatCardModule } from "@angular/material/card";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatChipsModule } from "@angular/material/chips";
import { MatListModule } from "@angular/material/list";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatSelectModule } from "@angular/material/select";
import { MatTableModule } from "@angular/material/table";
import { MatRadioModule } from "@angular/material/radio";
import { MatDatepickerModule } from "@angular/material/datepicker"
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import {MatBadgeModule} from '@angular/material/badge';

let MaterialModules = [
    MatInputModule,
    MatSidenavModule,
    MatButtonModule,
    MatDialogModule,
    MatTooltipModule,
    MatCardModule,
    MatExpansionModule,
    MatCheckboxModule,
    MatChipsModule,
    MatChipsModule,
    MatListModule,
    MatProgressBarModule,
    MatSelectModule,
    MatMomentDateModule,
    MatAutocompleteModule,
    MatTableModule,
    MatRadioModule,
    MatDatepickerModule,
    MatBadgeModule
];

// FontAwesome
import { FontAwesomeModule, FaIconLibrary, FaConfig } from '@fortawesome/angular-fontawesome';

import {
    faPen as fasPen,
    faInfoCircle as fasInfoCircle,
    faCalendarDay as fasCalendarDay,
    faRoad as fasRoad,
    faPlusCircle as fasPlusCircle,
    faMinusCircle as fasMinusCircle,
    faKey as fasKey,
    faBan as fasBan,
    faUser as fasUser,
    faMapMarkerAlt as fasMapMarkerAlt,
    faDirections as fasDirections,
    faCameraAlt as fasCameraAlt,
    faTimesCircle as fasTimesCircle,
    faUnlockAlt as fasUnlockAlt,
    faLockAlt as fasLockAlt,
    faChevronCircleLeft as fasChevronCircleLeft,
    faChevronCircleRight as fasChevronCircleRight
} from '@fortawesome/pro-solid-svg-icons';

import {
    faPhoneSquareAlt as falPhoneSquareAlt,
    faEnvelopeSquare as falEnvelopeSquare,
    faFax as falFax,
    faMoneyBillWave as falMoneyBillWave
} from '@fortawesome/pro-light-svg-icons';

import {
    faCheckCircle as farCheckCircle,
    faPlayCircle as farPlayCircle,
    faPauseCircle as farPauseCircle,
    faTimesCircle as farTimesCircle,
    faLongArrowRight as farLongArrowRight,
    faAngleUp as farAngleUp,
    faAngleDown as farAngleDown,
    faArrowRight as farArrowRight,
    faCheck as farCheck,
    faCircleNotch as farCircleNotch,
    faLongArrowLeft as farLongArrowLeft,
    faBars as farBars,
    faExclamationCircle as farExclamationCircle,
    faExclamationTriangle as farExclamationTriangle,
    faCalendarAlt as farCalendarAlt,
    faCarSide as farCarSide,
    faUserCircle as farUserCircle,
    faPowerOff as farPowerOff,
    faTimes as farTimes,
    faHistory as farHistory,
    faMapMarkedAlt as farMapMarkedAlt,
    faCars as farCars,
    faCalendarEdit as farCalendarEdit,
    faAddressBook as farAddressBook,
    faQuestionCircle as farQuestionCircle,
    faPhone as farPhone,
    faQuestionSquare as farQuestionSquare,
    faSearch as farSearch,
    faLocation as farLocation,
    faMapMarked as farMapMarked,
    faAngleRight as farAngleRight,
    faCaretDown as farCaretDown,
    faPlus as farPlus,
    faToggleOn as farToggleOn,
    faToggleOff as farToggleOff,
    faEye as farEye,
    faEyeSlash as farEyeSlash,
    faSync as farSync,
    faSearchPlus as farSearchPlus,
    faSpinner as farSpinner,
    faInfo as farInfo,
    faClock as farClock,
    faCalendarCheck as farCalendarCheck,
    faHourglassHalf as farHourglassHalf
} from '@fortawesome/pro-regular-svg-icons';

// Locale & Languages
import { TranslateModule } from '@ngx-translate/core';
import { registerLocaleData } from '@angular/common';
import localeFr from '@angular/common/locales/fr';
registerLocaleData(localeFr, 'fr');

import { LanguageService } from './services/language';

export const MY_DATE_FORMATS = {
    parse: { dateInput: 'DD/MM/YYYY HH:mm' },
    display: { dateInput: 'DD MMM YYYY', monthYearLabel: 'MMM YYYY' },
};

// Access
import { AccessPointComponent } from './components/access/access-point/access-point';
import { AccessPointMapComponent } from './components/access/access-point-map/access-point-map';

// Check
import { DeclareCheckComponent } from './components/check/declare-check/declare-check';

// Documents
import { DocumentsComponent } from './components/documents/documents';

// Help
import { HelpComponent } from './components/help/help';

// Key
import { KeyComponent } from './components/key/key';

// Reservation
import { ClientListComponent } from './components/reservation/list/list';
import { ConfirmDepositComponent } from './components/reservation/confirm-deposit/confirm-deposit';
import { ConfirmPaymentComponent } from './components/reservation/confirm-payment/confirm-payment';
import { DetailReservationComponent } from './components/reservation/details/details';

import { PayDepositComponent } from './components/paydeposit/paydeposit/paydeposit';
import { PayDepositTransactionComponent } from './components/paydeposit/paydeposit-transaction/paydeposit-transaction';
import { PayDepositDetailComponent } from './components/paydeposit/paydeposit-detail/paydeposit-detail';

// Transaction
import { TransactionComponent } from './components/transaction/transaction';
import { PricingComponent } from './components/transaction/pricing/pricing';
import { DepositComponent } from './components/transaction/deposit/deposit';

// User
import { UserComponent } from './components/user/user';

// Vehicle
import { SelectVehicleComponent } from './components/vehicle/select-vehicle/select-vehicle';
import { VehicleComponent } from './components/vehicle/vehicle/vehicle';

// Others
import { CheckPDFComponent } from './components/pdf/check.pdf';
import { LinkPaymentComponent } from './components/link/payment/payment';
import { LinkPaymentWidgetComponent } from './components/link/payment-widget/payment-widget';
import { LinkPaymentConfirmationComponent } from './components/link/confirmation/confirmation';
import { LinkPaymentPricingComponent } from './components/link/pricing/pricing';
import { PDFService } from "./services/pdf";
import { environment } from '../environments/environment';



// TODO: Factory in a factory folder
export function sessionIdFactory(sessionService: SessionService) {
    return () => sessionService.addSessionId();
}

@NgModule({
    imports: [
        CommonModule,
        HttpClientModule,
        HttpClientJsonpModule,
        FormsModule,
        ReactiveFormsModule,
        GoogleMapsModule,
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        Ng2TelInputModule,
        FontAwesomeModule,
        NgxMaskModule.forRoot(),
        TranslateModule.forRoot(),
        NgPipesModule,
        CalendarModule,
        KeycloakAngularModule,
        ...MaterialModules,

    ],
    declarations: [
        CarmoovFrontComponent,
        AccessPointComponent,
        AccessPointMapComponent,
        DeclareCheckComponent,
        DocumentsComponent,
        HelpComponent,
        KeyComponent,
        ClientListComponent,
        ConfirmDepositComponent,
        ConfirmPaymentComponent,
        DetailReservationComponent,
        TransactionComponent,
        PricingComponent,
        DepositComponent,
        UserComponent,
        SelectVehicleComponent,
        VehicleComponent,
        CheckPDFComponent,
        GlobalModal,
        Notification,
        MarkerPipe,
        AgencyPipe,
        CategoryPipe,
        PlatePipe,
        NamePipe,
        BookingPipe,
        ImageCompressed,
        LinkPaymentComponent,
        LinkPaymentWidgetComponent,
        LinkPaymentConfirmationComponent,
        LinkPaymentPricingComponent,
        PayDepositComponent,
        PayDepositTransactionComponent,
        PayDepositTransactionComponent,
        PayDepositDetailComponent
    ],
    providers: [
        SessionService,
        { provide: APP_INITIALIZER, useFactory: sessionIdFactory, deps: [SessionService], multi: true },
        {
            provide: APP_INITIALIZER,
            useFactory: initializer,
            multi: true,
            deps: [KeycloakService]
          },
        AuthGuardKeycloak,
        TransactionService,
        AccessService,
        BoxService,
        CheckService,
        UserService,
        ItemService,
        BookingService,
        ReservationService,
        UbbleService,
        PlanningService,
        OptionService,
        ModalManager,
        NotificationManager,
        ErrorManager,
        AgencyPipe,
        CategoryPipe,
        LanguageService,
        PlatePipe,
        NgxImageCompressService,
        GMapLoader,
        HeaderMenuService,
        LinkService,
        PayDepositService,
        { provide: LOCALE_ID, useValue: 'fr-FR' },
        { provide: HTTP_INTERCEPTORS, useClass: HeaderInterceptorService, multi: true },
        ReservationResolver,
        PDFService,
        TrackingService,
    ],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
    bootstrap: [CarmoovFrontComponent]
})

export class AppModule {
    constructor(
        library: FaIconLibrary,
        config: FaConfig
    ) {
        // Add free font-awesome css for some libraries needing specific icon replacement
        this.addLink({
            node: "link",
            href: "https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.11.2/css/all.min.css",
            rel: "stylesheet",
            type: "text/css"
        });

        this.addLink({
            node: "script",
            src: "https://static.axept.io/sdk.js",
            type: "text/javascript",
            data: {
                id: "5e67ad0167a7eb1aea499795",
                "cookies-domain": environment.cookieDomain,
                "cookies-version":"rentacar_prisme-base",
            }
        })

        this.reziseMaxHeight();
        window.addEventListener('resize', this.reziseMaxHeight);

        // FAIcon imports
        // config.fallbackIcon = fasBan;
        library.addIcons(
            // Solid
            fasUser, fasInfoCircle, fasCalendarDay, fasRoad, fasPlusCircle, fasMinusCircle, fasKey, fasBan, fasPen, fasMapMarkerAlt,
            fasDirections, fasCameraAlt, fasTimesCircle, fasUnlockAlt, fasLockAlt, fasChevronCircleLeft, fasChevronCircleRight,
            // Light
            falPhoneSquareAlt, falEnvelopeSquare, falFax, falMoneyBillWave,
            // Regular
            farLongArrowLeft, farBars, farExclamationCircle, farExclamationTriangle, farCalendarAlt, farCarSide, farUserCircle,
            farPowerOff, farTimes, farHistory, farMapMarkedAlt, farCars, farCalendarEdit, farAddressBook, farQuestionCircle, farPhone,
            farQuestionSquare, farSearch, farLocation, farMapMarked, farAngleRight, farCaretDown, farPlus, farToggleOn, farToggleOff,
            farAngleUp, farAngleDown, farArrowRight, farCheck, farCircleNotch, farCheckCircle, farPlayCircle, farPauseCircle,
            farTimesCircle, farLongArrowRight, farEye, farEyeSlash, farSync, farSearchPlus, farSpinner, farInfo, farClock, farCalendarCheck,
            farHourglassHalf
        );
    }

    addLink(input: any) {
        let node = document.createElement(input.node);

        if (input.href) node.href = input.href;
        if (input.src) node.src = input.src;
        if (input.rel) node.rel = input.rel;
        if (input.type) node.type = input.type;
        if (input.data) Object.entries(input.data).forEach(([key, val]) => node.setAttribute(`data-${key}`, val));

        document.getElementsByTagName('head')[0].appendChild(node);
    }

    reziseMaxHeight() {
        let isiOS = () => {
            var iDevices = ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'];
            if (!!navigator.platform) {
                while (iDevices.length) {
                    if (navigator.platform === iDevices.pop()) {
                        return true;
                    }
                }
            }
            return false;
        };

        let vh = innerHeight() - (isiOS() ? 85 : 0);
        // console.log("ios-inner-height: ", innerHeight());
        document.documentElement.style.setProperty('--vh', `${vh}px`);
    }
}
