import { Injectable } from '@angular/core';

import {
    CheckoutStepParams,
    PageProps,
    Rudderanalytics,
    RudderOptions,
    RudderProductSpec,
    UserSearchParams,
  } from "../interfaces/analytics";
import { TranslateService } from '@ngx-translate/core';

export declare const rudderanalytics: Rudderanalytics;


const getAbTestCookies = (): Record<string, string> => {
    if (typeof document === "undefined") {
      return {};
    }
    const abTestsValues = {};
    // Match cookies starting with 'ab-' up to the '=' sign, capture cookie name with and without '='
    const matchRegex = RegExp(/(^ab-([a-zA-Z-]*))=/);
    const decodedCookie = decodeURIComponent(document.cookie);
    const cookiesArray = decodedCookie.split(";");
    for (let i = 0; i < cookiesArray.length; i++) {
      let currentCookie = cookiesArray[i];
      while (currentCookie.startsWith(" ")) {
        currentCookie = currentCookie.substring(1);
      }
      const matchArray = currentCookie.match(matchRegex);
      if (matchArray && matchArray.length >= 3) {
        // matchArray[0] = Cookie name with the '=' sign
        // matchArray[1] = Cookie name without the '=' sign
        // matchArray[2] = Cookie name without the '=' sign and without the ab- prefix
        abTestsValues[matchArray[2]] = currentCookie.substring(
          matchArray[0].length,
          currentCookie.length
        );
      }
    }

    return abTestsValues;
  };

const getStartEndAgencies = (
    agency,
  ) => {
    return {
      startAgency: `${agency.id}]] ${agency.name}`,
      endAgency: `${agency.id}]] ${agency.name}`
    };
  };

  @Injectable({
    providedIn: 'root',
})
class TrackingService {
    private static _rudderanalytics: Rudderanalytics;

    constructor(private translateService: TranslateService) {
    }

    private static log = (...args: unknown[]) => {
      console.log(args);

      return;
    };

    private static getAbTests() {
        return {
          abTests: JSON.stringify({
            ...getAbTestCookies(),
          }),
        };
      }

    public static get rudderanalytics() {
      if (
        // If we've disabled the Rudder SDK and we're not in a production environment
        // Just log the event to the console
        typeof rudderanalytics === "undefined" &&
        process.env.NODE_ENV !== "production"
      ) {
        TrackingService._rudderanalytics = {
          identify: TrackingService.log,
          page: TrackingService.log,
          track: TrackingService.log,
          reset: TrackingService.log,
          group: TrackingService.log,
          alias: TrackingService.log,
        };
      } else {
        this._rudderanalytics = rudderanalytics;
      }

      return TrackingService._rudderanalytics;
    }

    static trackPageView(
        category?: string,
        name?: string,
        pageProps?: PageProps,
        options?: RudderOptions
      ) {
        TrackingService.rudderanalytics.page(category, name, pageProps, options);
      }

    private buildSearchParams(
        category,
        agency,
        booking
      ): UserSearchParams {
        const { startAgency, endAgency } = getStartEndAgencies(
            agency
          );
        return {
          formAgency: startAgency,
          formAgencyReturn: endAgency,
          formDateGo: booking.start / 1000,
          formDateReturn: booking.end / 1000,
          formType: 'VLS',
          withoutLicense: false,
          formJourney: "trajet courte durée",
          ...TrackingService.getAbTests(),
        };
      }

    private buildProductObject(
        category,
        reservationStorage,
        booking,
      ): RudderProductSpec {
        const { startAgency, endAgency } = getStartEndAgencies(
            reservationStorage.agency
        );

        return {
          product_id: category.id,
          name: `VLS ${this.translateService.instant(category.title) ?? ""} trajet courte durée`,
          image_url: `${document.location.origin}/assets/img/cube_pic/${category.id}.png`,
          price: booking.bookingPrice.paymentPrice / 100,
          variant: 'VLS',
          brand: category.carTypes.join(', ') + ' ou similaire',
          service: 'trajet courte durée',
          formDateGo: booking.start,
          formDateReturn: booking.end,
          formAgency: startAgency,
          formAgencyReturn: endAgency,
          transmission: this.translateService.instant(category.transmission),
          isSelfService: true,
        };
      }

    private buildCheckoutStepParams(
        category, reservationStorage, booking, currency = 'EUR'
      ): CheckoutStepParams {
        return {
          ...this.buildSearchParams(category, reservationStorage.agency, booking),
          currency,
          total: booking.bookingPrice.paymentPrice / 100,
          checkout_id: category.idBooking,
          ...reservationStorage.options,
          category: 'VLS',
          resultPageVersion: "cube",
        };
      }

    public trackVehicleRecap(category, reservationStorage, booking) {
        const product = this.buildProductObject(category, reservationStorage, booking);

        TrackingService.rudderanalytics.track("Product Viewed", {
            ...this.buildCheckoutStepParams(category, reservationStorage, booking),
            ...product,
          });

        TrackingService.rudderanalytics.track("Checkout Started", {
            ...this.buildCheckoutStepParams(category, reservationStorage, booking),
            products: [product],
          });

        TrackingService.rudderanalytics.track("Checkout Step Viewed", {
            step: 1,
            ...this.buildCheckoutStepParams(category, reservationStorage, booking),
            product,
          });
      }

    public trackPaymentConfirmStep(category, reservationStorage, booking) {
        TrackingService.rudderanalytics.track("Checkout Step Viewed", {
            step: 2,
            ...this.buildCheckoutStepParams(category, reservationStorage, booking),
            product: this.buildProductObject(category, reservationStorage, booking),
          });
      }

      public trackProductAdded(category, reservationStorage, booking) {
        TrackingService.rudderanalytics.track("Product Added", {
            ...this.buildCheckoutStepParams(category, reservationStorage, booking),
            products: [this.buildProductObject(category, reservationStorage, booking)],
            cart_id: category.idBooking,
          });
      }

    public trackDepositStep(reservation) {
        TrackingService.rudderanalytics.track("Checkout Step Viewed", {
            step: 3,
            checkout_id: reservation.bookingId,
            order_id: reservation.id,
          });
      }

    public trackPaymentSuccess(booking, currency = 'EUR') {
        const bookingId = localStorage.getItem('idBooking');
        const orderId = localStorage.getItem('reservationId');
        const category = JSON.parse(localStorage.getItem('category'));
        const reservation = {
            agency: JSON.parse(localStorage.getItem('agency')),
            options: JSON.parse(localStorage.getItem('options')),
        };

        TrackingService.rudderanalytics.track("Payment Info Entered", {
            ...this.buildCheckoutStepParams(category, reservation, booking),
            products: [this.buildProductObject(category, reservation, booking)],
          });

        TrackingService.rudderanalytics.track("Payment Success", {
            checkout_id: bookingId,
            order_id: orderId,
            currency,
            total: booking.bookingPrice.paymentPrice / 100,
            ...this.buildCheckoutStepParams(category, reservation, booking),
            products: [this.buildProductObject(category, reservation, booking)]
          });
      }

    public trackDepositSuccess(booking, currency = 'EUR') {
        const bookingId = localStorage.getItem('idBooking');
        const orderId = localStorage.getItem('reservationId');
        const category = JSON.parse(localStorage.getItem('category'));
        const reservation = {
            agency: JSON.parse(localStorage.getItem('agency')),
            options: JSON.parse(localStorage.getItem('options')),
        };

        TrackingService.rudderanalytics.track("Order Completed", {
            checkout_id: bookingId,
            order_id: orderId,
            currency,
            total: booking.bookingPrice.paymentPrice / 100,
            depositAmount: booking.bookingPrice.depositPrice / 100,
            ...this.buildCheckoutStepParams(category, reservation, booking),
            products: [this.buildProductObject(category, reservation, booking)]
          });
      }
}

export default TrackingService;
