<h1 mat-dialog-title class="title">
    {{ data.title }}
    <fa-icon class="close" *ngIf="!data.needConfirm" [icon]="['far', 'times']" (click)="onNoClick()"></fa-icon>
</h1>

<div mat-dialog-content class="body" [ngClass]="{'fixed-height': data.fixedHeight}">
    <div class="template" #template></div>
    <div class="warning" *ngIf="templateData.warning">
        <fa-icon [icon]="['far', 'exclamation-triangle']"></fa-icon>
        <div class="content">
            {{ templateData.warning }}
        </div>
    </div>
</div>

<div mat-dialog-actions class="actions" *ngIf="data.needConfirm">
    <button class="confirm-button reverse-button" (click)="onNoClick()">{{ 'GENERIC.cancel' | translate }}</button>
    <button class="confirm-button" (click)="onReturnData(data)" cdkFocusInitial>{{ 'GENERIC.continue'| translate }}</button>
</div>
