import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { environment } from 'src/environments/environment';

@Injectable()
export class BoxService {

    constructor(private httpClient: HttpClient) {}

    public getBox(input: any) {
        return this.httpClient.get<any>(environment.BFF + 'box/' + input.id);
    }

    public getBoxList() {
        return this.httpClient.get<any>(environment.BFF + 'box');
    }

    public setDoorsLock(input: any) {
        let action = input.lock ? 'close' : 'open';
        return this.httpClient.get<any>(environment.BFF + 'box/' + input.id + '/doors/' + action);
    }

    public setEngineLock(input: any) {
        let action = input.lock ? 'close' : 'open';
        return this.httpClient.get<any>(environment.BFF + 'box/' + input.id + '/engine/' + action);
    }
}
