import { Component, Inject, Injectable, ViewChild, ElementRef, AfterViewInit, ChangeDetectorRef } from "@angular/core";
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
    selector: 'global-modal',
    templateUrl: './global.html',
    styleUrls: ['./global.scss']
})
export class GlobalModal implements AfterViewInit {
    @ViewChild('template', { static: false }) templateContainer: ElementRef;
    public template;
    public templateData;

    constructor(
        public dialogRef: MatDialogRef<GlobalModal>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private cdRef: ChangeDetectorRef
    ) {
        this.template = data.template;
        this.templateData = data.templateData;
    }

    ngAfterViewInit() {
        this.templateContainer.nativeElement.innerHTML = this.template;
        this.templateData = this.templateData;
        this.cdRef.detectChanges();
    }

    onNoClick(): void {
        this.dialogRef.close();
    }

    onReturnData() {
        this.dialogRef.close(this.templateData);
    }
}

@Injectable({
    providedIn: 'root'
})
export class ModalManager {
    constructor(
        public dialog: MatDialog
    ) {}

    openModal(title: string, template: string, templateData: any = null, needConfirm: boolean = false, fixedHeight: boolean = false, acceptCallback: any = null, refuseCallback: any = null) {
        setTimeout(() => {
            const dialogRef = this.dialog.open(GlobalModal, {
                width: '95%',
                data: {
                    title: title,
                    template: template,
                    templateData: templateData,
                    needConfirm: needConfirm,
                    fixedHeight: fixedHeight
                }
            });

            dialogRef.afterClosed().subscribe((accept) => {
                if (accept && acceptCallback) {
                    acceptCallback(accept);
                } else if (refuseCallback) {
                    refuseCallback();
                }
            });
        }, 100);
    }
}
