<div class="header" *ngIf="reservation">
    <div class="title">{{ 'RESERVATION.reservation_id' | translate:{value:reservation?.id} }} - {{ vehicleID }}</div>
    <div class="subtitle" *ngIf="reservation?.status !== 'WAITING'">{{ 'RESERVATION.STATUS.RESERVATION_' + reservation?.status | translate }}</div>
</div>

<!-- Dates -->
<div class="card" *ngIf="reservation">
    <div class="dates">
        <fa-icon class="calendar" [icon]="['fas', 'calendar-day']"></fa-icon>
        <div class="date">
            <span>{{ reservation?.start | date:'dd MMM yyy' }}</span>
            <span>{{ reservation?.start | date:'HH:mm' }}</span>
        </div>
        <fa-icon class="arrow" [icon]="['far', 'long-arrow-right']"></fa-icon>
        <div class="date" [ngClass]="{'warning': isBookingNow === 'late'}">
            <span>{{ reservation?.end | date:'dd MMM yyy' }}</span>
            <span>{{ reservation?.end | date:'HH:mm' }}</span>
        </div>
    </div>
</div>

<!-- Documents verification -->
<div class="user-infos" *ngIf="status !== 'VERIFIED'">
    <documents path="details"></documents>
</div>

<!-- Actions -->
<div class="loading-action" *ngIf="loading">
    <fa-icon [icon]="['far', 'circle-notch']" [spin]="true"></fa-icon>
</div>
<div *ngIf="!loading">
<div class="action" *ngIf="reservation?.status === 'OPEN' || reservation?.status === 'WAITING'">
    
    <div class="warning" *ngIf="isBookingNow === 'not-yet' && status === 'VERIFIED' && missingPaye === false">
        <fa-icon [icon]="['far', 'clock']"></fa-icon> {{ 'RESERVATION.CLIENT.DETAILS.not_yet' | translate }}
    </div>

    <div class="expired" *ngIf="isBookingNow === 'expired'">
        <fa-icon [icon]="['far', 'hourglass-half']"></fa-icon> {{ 'RESERVATION.CLIENT.LIST.reservation_expired' | translate }}
    </div>

    <div class="warning" *ngIf="missingPaye === true && reservation?.status !== 'OPEN' && reservation?.status !== 'CANCEL' && isBookingNow !== 'expired' ">
        <fa-icon [icon]="['fal', 'money-bill-wave']"></fa-icon> {{ 'RESERVATION.CLIENT.LIST.missing_paye' | translate }}
    </div>

    <div class="warning" *ngIf="!isVehicleAvailable && isBookingNow === 'now' && reservation?.status == 'WAITING'">{{ 'RESERVATION.CLIENT.LIST.vehicle_not_available' | translate }}</div>

    <!-- Go to Key: Can be done when the booking has started or just at the start to make the -->
    <div class="confirm" *ngIf="reservation?.status === 'OPEN' || ((isBookingNow === 'now' || isBookingNow === 'late') && status === 'VERIFIED' && !missingPaye && isVehicleAvailable)">
        <button class="confirm-button" (click)="goToKey()">
            {{ 'RESERVATION.CLIENT.DETAILS.continue_booking' | translate }}
        </button>
    </div>

    <!-- External link to simulate click -->
    <a #externalLink style="opacity: 0;"></a>
</div>
</div>

<!-- Pricing -->
<div class="card pricing" *ngIf="reservationID && missingPaye === true ">
    <pricing [reservation-id]="reservationID" [opened]="cards['pricing']"></pricing>
</div>

<!-- Location -->
<div class="card agency-infos" *ngIf="accessID">
    <access-point [id]="accessID" [opened]="cards['location']"></access-point>
</div>

<!-- Vehicle -->
<div class="card vehicle-infos" *ngIf="vehicleID">
    <vehicle [vehicle-id]="vehicleID" [opened]="cards['vehicle']"></vehicle>
</div>

<!-- Pricing -->
<div class="card pricing" *ngIf="reservationID && missingPaye === false">
    <pricing [reservation-id]="reservationID" [opened]="cards['pricing']"></pricing>
</div>

<!-- Deposit -->
<div class="card deposit-infos" *ngIf="reservationID">
    <deposit [reservation-id]="reservationID" [opened]="cards['deposit']"></deposit>
</div>

<div class="card checks" *ngIf="false">
    <div class="confirm" *ngIf="false">
        <button class="confirm-button" (click)="viewCheck('IN')">
            {{ 'RESERVATION.CLIENT.DETAILS.view_start_check' | translate }}
        </button>
    </div>
    <div class="confirm" *ngIf="false">
        <button class="confirm-button" (click)="viewCheck('OUT')">
            {{ 'RESERVATION.CLIENT.DETAILS.view_end_check' | translate }}
        </button>
    </div>
</div>
