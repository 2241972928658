var _extract = str => str;

export const VehicleParts = [
    _extract("VEHICLE_PART.right_rear_wing"),
    _extract("VEHICLE_PART.left_rear_wing"),
    _extract("VEHICLE_PART.right_front_wing"),
    _extract("VEHICLE_PART.left_front_wing"),
    _extract("VEHICLE_PART.right_sill"),
    _extract("VEHICLE_PART.left_sill"),
    _extract("VEHICLE_PART.calender"),
    _extract("VEHICLE_PART.bonnet"),
    _extract("VEHICLE_PART.ignition_key"),
    _extract("VEHICLE_PART.left_taillight"),
    _extract("VEHICLE_PART.right_taillight"),
    _extract("VEHICLE_PART.right_rear_light"),
    _extract("VEHICLE_PART.left_rear_light"),
    _extract("VEHICLE_PART.right_front_light"),
    _extract("VEHICLE_PART.left_front_light"),
    _extract("VEHICLE_PART.vehicle_interior"),
    _extract("VEHICLE_PART.right_rear_wheel"),
    _extract("VEHICLE_PART.left_rear_wheel"),
    _extract("VEHICLE_PART.right_front_wheel"),
    _extract("VEHICLE_PART.left_front_wheel"),
    _extract("VEHICLE_PART.left_rear_rim"),
    _extract("VEHICLE_PART.left_front_rim"),
    _extract("VEHICLE_PART.rear_bumper"),
    _extract("VEHICLE_PART.front_bumper"),
    _extract("VEHICLE_PART.right_rear_door"),
    _extract("VEHICLE_PART.left_rear_door"),
    _extract("VEHICLE_PART.right_front_door"),
    _extract("VEHICLE_PART.left_front_door"),
    _extract("VEHICLE_PART.rear_windshield"),
    _extract("VEHICLE_PART.right_mirror"),
    _extract("VEHICLE_PART.left_mirror"),
    _extract("VEHICLE_PART.rearview_mirror"),
    _extract("VEHICLE_PART.spare_tire_puncture_protection_kit"),
    _extract("VEHICLE_PART.roof"),
    _extract("VEHICLE_PART.security_kit"),
    _extract("VEHICLE_PART.radio_antenna"),
    _extract("VEHICLE_PART.vehicle_papers"),
    _extract("VEHICLE_PART.windshield")
];

export const DamageTypes = [
    _extract("DAMAGE_TYPE.impact"),
    _extract("DAMAGE_TYPE.stripe"),
    _extract("DAMAGE_TYPE.stripe_and_sink"),
    _extract("DAMAGE_TYPE.deep_scratch"),
    _extract("DAMAGE_TYPE.damaged_fabric"),
    _extract("DAMAGE_TYPE.stained_fabric"),
    _extract("DAMAGE_TYPE.missing")
];
