<div class="loading" *ngIf="loading">
    <fa-icon [icon]="['far', 'circle-notch']" [spin]="true"></fa-icon>
</div>

<div class="page" *ngIf="!loading">
    <div class="banner">
        <img src="../../../assets/img/banner.svg" alt="Banner">   
    </div>
    <div class="subpage">
        <div class="header-infos">
            <div class="header-infos-left">
                <div class="row caps">{{ 'CHECK.title' | translate }}</div>
                <div class="row" *ngIf="data?.category">{{ data?.plate }} - {{ categories[data?.category].title | translate }}</div>
                <div class="row underlined" *ngIf="data?.type === 'IN'">{{ 'PDF.CHECK.start' | translate }}</div>
                <div class="row underlined" *ngIf="data?.type === 'OUT'">{{ 'PDF.CHECK.end' | translate }}</div>
            </div>
            <div class="header-infos-right">
                <div class="left">
                    <div class="row">{{ 'GENERIC.customer' | translate }}: {{ data?.name }}</div>
                    <div class="row">{{ 'ACCESS_POINT.title' | translate }}: <i class="caps">{{ data?.accessPoint }}</i></div>
                    <div class="row">{{ 'FORM.placeholder.reservation_id' | translate }}: {{ reservationID }}</div>
                </div>
                <div class="right">
                    <div class="row">{{ 'PDF.CHECK.km_included' | translate }}: {{ data?.kmIncluded }}km</div>
                    <div class="row">{{ 'PDF.CHECK.start' | translate }}: {{ data?.start_date | date: 'dd/MM/yyyy à HH:mm' }}</div>
                    <div class="row">{{ 'PDF.CHECK.end' | translate }}: {{ data?.end_date | date: 'dd/MM/yyyy à HH:mm' }}</div>
                </div>
            </div>
        </div>
        <table class="start-infos">
            <thead>
                <th colspan="3">{{ 'PDF.CHECK.start_title' | translate }}</th>
            </thead>
            <tbody>
                <td>
                    <b>{{ 'GENERIC.km' | translate }}:</b> {{ data?.start?.km }}km
                </td>
                <td>
                    <b>{{ 'GENERIC.fuel' | translate }}:</b> {{ data?.start?.fuel }}L
                </td>
                <td>
                    <b>{{ 'PDF.CHECK.validation_date' | translate }}:</b> {{ data?.start?.validation | date: 'dd/MM/yyyy HH:mm' }}
                </td>
            </tbody>
        </table>
        <table class="end-infos" *ngIf="data?.type === 'OUT'">
            <thead>
                <th colspan="3">{{ 'PDF.CHECK.end_title' | translate }}</th>
            </thead>
            <tbody>
                <td>
                    <div>
                        <b>{{ 'GENERIC.km' | translate }}:</b> {{ data?.end?.km }}km
                    </div>
                    <div [ngClass]="{ 'warning': data?.km_total > data?.kmIncluded }">
                        <b>{{ 'PDF.CHECK.km_total' | translate }}:</b> {{ data?.km_total }}km
                    </div>
                </td>
                <td>
                    <div [ngClass]="{ 'warning': (data?.end?.fuel + 1) < data?.start?.fuel }">
                        <b>{{ 'GENERIC.fuel' | translate }}:</b> {{ data?.end?.fuel }}L
                    </div>
                </td>
                <td>
                    <div [ngClass]="{ 'warning': data?.end?.late }">
                        <b>{{ 'PDF.CHECK.validation_date' | translate }}:</b> {{ data?.end?.validation | date: 'dd/MM/yyyy HH:mm' }}
                    </div>
                    <div>
                        <b *ngIf="data?.end?.late">{{ 'PDF.CHECK.expected_duration' | translate }}:</b>
                        <b *ngIf="!data?.end?.late">{{ 'PDF.CHECK.duration' | translate }}:</b>
                        {{ data?.duration }} jour(s)
                    </div>
                    <div *ngIf="data?.end?.late" [ngClass]="{ 'warning': data?.end?.late }">
                        <b>{{ 'PDF.CHECK.duration' | translate }}:</b> {{ data?.end?.realDuration }} jour(s)
                    </div>
                </td>
            </tbody>
        </table>
        <table class="vehicle-infos">
            <thead>
                <th colspan="2" *ngIf="data?.type === 'IN'">
                    {{ 'PDF.CHECK.vehicle_state' | translate }}
                </th>
            </thead>
            <tbody>
                <td>
                    <div>
                        <b>{{ 'PDF.CHECK.existing_damages' | translate }}:</b>
                    </div>
                    <div>
                        <ul class="list">
                            <li *ngFor="let damage of previousDamages; let i = index">
                                <div class="damage" *ngIf="i < 5">
                                    <div class="damage-pic" *ngIf="damage.preview" [img-cpr]="damage.preview"></div>
                                    <div class="damage-pic" *ngIf="!damage.preview"></div>
                                    <div class="damage-label">
                                        <div class="part">{{ damage.itemPart | translate }}</div>
                                        <div class="type">{{ damage.type | translate }}</div>
                                    </div>
                                </div>
                            </li>
                            <li class="no-damage" *ngIf="!previousDamages?.length">
                                {{ 'PDF.CHECK.no_existing_damage' | translate }}
                            </li>
                        </ul>
                    </div>
                </td>
                <td>
                    <div>
                        <b>{{ 'PDF.CHECK.new_damages' | translate }}:</b>
                    </div>
                    <div>
                        <ul class="list">
                            <li *ngFor="let damage of newDamages; let j = index">
                                <div class="damage" *ngIf="j < 5">
                                    <div class="damage-pic" *ngIf="damage.preview" [img-cpr]="damage.preview"></div>
                                    <div class="damage-pic" *ngIf="!damage.preview"></div>
                                    <div class="damage-label">
                                        <div class="part">{{ damage.itemPart | translate }}</div>
                                        <div class="type">{{ damage.type | translate }}</div>
                                    </div>
                                </div>
                            </li>
                            <li class="no-damage" *ngIf="!newDamages?.length">
                                {{ 'PDF.CHECK.no_new_damage' | translate }}
                            </li>
                        </ul>
                    </div>
                </td>
            </tbody>
        </table>
        <div class="footer-infos">
            <div class="legal-infos"></div>
            <div class="page-count">1 / {{ pageCount.length + 1 }}</div>
        </div>
    </div>
</div>

<!-- Additional pages -->
<div class="page" *ngFor="let pageN of pageCount">
    <div class="banner">
        <img src="../../../assets/img/banner.svg" alt="Banner">
    </div>
    <div class="subpage">
        <table class="vehicle-infos">
            <thead>
                <th colspan="2">
                    {{ 'PDF.CHECK.vehicle_state' | translate }}
                </th>
            </thead>
            <tbody>
                <td>
                    <div>
                        <b>{{ 'CHECK.DECLARE.existing_damages' | translate }}:</b>
                    </div>
                    <div>
                        <ul class="list">
                            <li *ngFor="let damage of pageN.damages">
                                <div class="damage">
                                    <div class="damage-pic" *ngIf="damage.preview" [img-cpr]="damage.preview"></div>
                                    <div class="damage-pic" *ngIf="!damage.preview"></div>
                                    <div class="damage-label">
                                        <div class="part">{{ damage.itemPart | translate }}</div>
                                        <div class="type">{{ damage.type | translate }}</div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </td>
                <td>
                    <div>
                        <b>{{ 'PDF.CHECK.new_damages' | translate }}:</b>
                    </div>
                    <div>
                        <ul class="list">
                            <li *ngFor="let damage of pageN.newDamages">
                                <div class="damage">
                                    <div class="damage-pic" *ngIf="damage.preview" [img-cpr]="damage.preview"></div>
                                    <div class="damage-pic" *ngIf="!damage.preview"></div>
                                    <div class="damage-label">
                                        <div class="part">{{ damage.itemPart | translate }}</div>
                                        <div class="type">{{ damage.type | translate }}</div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                </td>
            </tbody>
        </table>
        <div class="footer-infos">
            <div class="legal-infos">
                <span *ngIf="pageCount.length + 1 === pageN.id"><!-- {{ 'PDF.CHECK.legal' | translate }} --></span>
            </div>
            <div class="page-count">{{ pageN.id }} / {{ pageCount.length + 1 }}</div>
        </div>
    </div>
</div>
