import { Component, OnChanges, Input } from '@angular/core';
import { BookingService } from 'src/app/services/booking';
import { TransactionService } from 'src/app/services/transaction';

@Component({
    selector: 'deposit',
    templateUrl: './deposit.html',
    styleUrls: ['./deposit.scss']
})
export class DepositComponent implements OnChanges {
    @Input('reservation-id') reservationID: string;
    @Input('booking-id') bookingID: string;
    @Input('opened') opened: boolean;

    public isOpened: boolean = true;
    public price: number = 0;
    public loading: boolean = true;

    constructor(
        private bookingService: BookingService,
        private transactionService: TransactionService
    ) {}

    ngOnChanges() {
        if (this.reservationID) {
            this.transactionService.getTransactionList({ id: this.reservationID }).toPromise().then((transactions) => {
                // console.log("getTransactionList: ", transactions);
                this.price = transactions.filter(t => t.type === 'DEPOSIT' && t.amount.value > 0)[0]?.amount.value;
                this.loading = false;
            });
        } else if (this.bookingID) {
            this.bookingService.getSession(this.bookingID).toPromise().then((res) => {
                let booking = res;
                this.price = booking.bookingPrice.depositPrice;
                this.loading = false;
            })
        }

        if (this.opened !== null) {
            this.isOpened = this.opened;
        }
    }

    toggleCard() {
        this.isOpened = !this.isOpened;
    }
}
