import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { ErrorManager } from 'src/app/error/error';

@Injectable()
export class HeaderInterceptorService implements HttpInterceptor {

    constructor(private errorManager: ErrorManager) {}

    private getAccessToken = () => {
        let keys = Object.entries(localStorage);
        for (let i = 0; i < keys.length; i++) {
             if (keys[i][0].includes('accessToken')) {
                return keys[i][1];
            }
        }
        return '';
    };

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        const token = this.getAccessToken();
        let newHeaders = req.headers;
        let sessionId = localStorage.getItem('sessionId');

        if (sessionId) {
            newHeaders = newHeaders.append('Session-Id',  sessionId);
        }

        if (token) {
            newHeaders = newHeaders.append('Authorization', 'Bearer ' + token);
        }

        req = req.clone({ headers: newHeaders, withCredentials: true });
        return next.handle(req).pipe(map(response => {
            if (response instanceof HttpResponse) {
                return response;
            }
        }), catchError((error: any) => {
            // console.log("catchError: ", error);
            this.errorManager.handleCode(error.status);
            return throwError(error);
        }));
    }
}
