import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'category',
    pure: false,
})
export class CategoryPipe implements PipeTransform {
    transform(items: any[], categories: string): any {
        if (categories.length < 2) {
            return items;
        } else {
            let categoriesList = categories.split(/ |;|,/);
            return items.filter((item) => {
                for (let i = 0; i < categoriesList.length; i++) {
                    if (item.category.toLowerCase() === categoriesList[i].toLowerCase()) {
                        return true;
                    }
                }
                return false;
            });
        }
    }
}

@Pipe({
    name: 'agency',
    pure: false,
})
export class AgencyPipe implements PipeTransform {
    transform(items: any[], agencies: string): any {
        if (agencies.length < 2) {
            return items;
        } else {
            let agenciesList = agencies.split(/ |;|,/);
            return items.filter((item) => {
                if (!item.accessPoint) return false;
                for (let i = 0; i < agenciesList.length; i++) {
                    if (
                        item.accessPoint.id.toLowerCase().replace('_', '').includes(agenciesList[i].toLowerCase().replace('_', '')) ||
                        item.accessPoint.name.toLowerCase().replace('_', '').includes(agenciesList[i].toLowerCase().replace('_', ''))
                    ) {
                        return true;
                    }
                }
                return false;
            });
        }
    }
}

@Pipe({
    name: 'plate',
    pure: false,
})
export class PlatePipe implements PipeTransform {
    transform(items: any[], plate: string): any {
        if (!plate || plate.length < 3) {
            return items;
        } else {
            return items.filter((item) => {
                return item.id.toLowerCase().replace(/\-/g, '').includes(plate.toLowerCase().replace(/\-/g, ''));
            });
        }
    }
}

@Pipe({
    name: 'booking',
    pure: false,
})
export class BookingPipe implements PipeTransform {
    transform(items: any[], booking: string): any {
        if (!booking) {
            return items;
        } else {
            return items.filter((item) => {
                return item.toLowerCase().includes(booking.toLowerCase());
            });
        }
    }
}

@Pipe({
    name: 'name',
    pure: false,
})
export class NamePipe implements PipeTransform {
    transform(items: any[], name: string): any {
        if (!name) {
            return items;
        } else {
            return items.filter((item) => {
                let comparison =
                    item['firstname'].toLowerCase().includes(name.toLowerCase()) ||
                    item['lastname'].toLowerCase().includes(name.toLowerCase()) ||
                    item['email'].toLowerCase().includes(name.toLowerCase());
                return comparison;
            });
        }
    }
}

@Pipe({
    name: "marker",
    pure: false
})
export class MarkerPipe implements PipeTransform {
    checkHours(date, openingHours) {
        let isOpen = false;
        if (openingHours) {
            for (let i = 0; i < openingHours.length; i++) {
                let min = date.clone().hours(openingHours[i].from.split(':')[0]).minutes(openingHours[i].from.split(':')[1] || 0);
                let max = date.clone().hours(openingHours[i].to.split(':')[0]).minutes(openingHours[i].to.split(':')[1] || 0);
                isOpen = date.isBetween(min, max, 'minutes');
                if (isOpen) {
                    break;
                }
            }
        }
        return isOpen;
    }

    transform(items: any[], onlyPresto, startDate, endDate): any {
        if (onlyPresto) {
            items = items.filter(item => item.type !== "counter");
        }

        if (startDate) {
            items = items.filter(item => {
                let dayOfTheWeek = startDate.day() || 7;
                for (let i = 0; i < item.schedule.length; i++) {
                    if (item.schedule[i].id === dayOfTheWeek) {
                        return item.schedule[i].allDay || this.checkHours(startDate, item.schedule[i].hours);
                    }
                }
                return false;
            });
        }
        if (endDate) {
            items = items.filter(item => {
                let dayOfTheWeek = endDate.day() || 7;
                for (let i = 0; i < item.schedule.length; i++) {
                    if (item.schedule[i].id === dayOfTheWeek) {
                        return item.schedule[i].allDay || this.checkHours(endDate, item.schedule[i].hours);
                    }
                }
                return false;
            });
        }

        return items.filter(item => {
            return item.status === "OPEN";
        });
    }
}
