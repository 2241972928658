import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

declare global {
    interface Window {
        rudderWriteKey: string;
        cookieDomain: string;
    }
}

if (environment.production) {
    enableProdMode();
    if (window) {
        window.console.log = window.console.warn = window.console.info = () => {
            // Don't log anything.
        };
        window.rudderWriteKey = "2LSU0IoBdUKdTDIVGNqHDc30Ofq"
    }
} else {
    if (window) {
        // Dev write key
        window.rudderWriteKey = "2LSTntMfwBcetpggC1GKkvH9hvt"
    }
}

platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch((err) => console.log(err));
