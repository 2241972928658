<div class="title" *ngIf="step !== 'detail'">
    <span *ngIf="type === 'IN'">{{ 'CHECK.title_in' | translate }}</span>
    <span *ngIf="type === 'OUT'">{{ 'CHECK.title_out' | translate }}</span>
</div>

<div class="loading" *ngIf="loading">
    <fa-icon [icon]="['far', 'circle-notch']" [spin]="true"></fa-icon>
</div>

<div class="start-edl" *ngIf="step === 'start'">
    <div class="step">{{ 'CHECK.DECLARE.step' | translate }} 1</div>
    <div class="step-explained" *ngIf="type === 'IN'">
        {{ 'CHECK.DECLARE.step_1_IN' | translate }}
    </div>
    <div class="step-explained" *ngIf="type === 'OUT'">
        {{ 'CHECK.DECLARE.step_1_OUT' | translate}}
        <span style='font-weight:bold;color:#FD7E14'> {{ 'CHECK.DECLARE.step_1_OUT_1' | translate}}</span>
        {{ 'CHECK.DECLARE.step_1_OUT_2' | translate}}
        
    </div>

    <div class="step">{{ 'CHECK.DECLARE.step' | translate }} 2</div>
    <div class="step-explained" *ngIf="type === 'IN'">
        {{ 'CHECK.DECLARE.step_2_IN' | translate }}<br>
        {{ 'CHECK.DECLARE.step_2_IN_1' | translate }}
    </div>
    <div class="step-explained" *ngIf="type === 'OUT'">
        {{ 'CHECK.DECLARE.step_2_OUT' | translate }}<br>
        {{ 'CHECK.DECLARE.step_2_OUT_1' | translate }}
    </div>

    <div class="step">{{ 'CHECK.DECLARE.step' | translate }} 3</div>
    <div class="step-explained" *ngIf="type === 'IN'">
        {{ 'CHECK.DECLARE.step_3_IN' | translate }}
    </div>
    <div class="step-explained" *ngIf="type === 'OUT'">
        {{ 'CHECK.DECLARE.step_3_OUT' | translate }}
    </div>

    <div class="confirm">
        <button class="confirm-button" (click)="step = 'list'">{{ 'GENERIC.start' | translate }}</button>
    </div>
</div>

<div class="list-damages" *ngIf="step === 'list'">
    <div class="disclaimer" *ngIf="!newDamages.length">{{ 'CHECK.DECLARE.disclamer' | translate }}</div>

    <div class="disclaimer-warning" *ngIf="type === 'OUT'">
        <fa-icon [icon]="['far', 'exclamation-circle']"></fa-icon>
        <div>{{ 'CHECK.DECLARE.out_disclamer' | translate }}</div>
    </div>

    <div class="list" *ngIf="newDamages.length">
        <div class="list-label" (click)="showNewDamages = !showNewDamages">
            <div class="label">
                <span class="light-blue">{{ newDamages.length }}</span>
                <span *ngIf="newDamages.length === 1">{{ 'CHECK.DECLARE.new_damage' | translate }}</span>
                <span *ngIf="newDamages.length > 1">{{ 'CHECK.DECLARE.new_damages' | translate }}</span>
            </div>
            <div class="icon">
                <fa-icon [icon]="['far', 'angle-down']" *ngIf="showNewDamages"></fa-icon>
                <fa-icon [icon]="['far', 'angle-right']" *ngIf="!showNewDamages"></fa-icon>
            </div>
        </div>
        <div class="list-content" *ngIf="showNewDamages">
            <div class="new-damage" *ngFor="let damage of newDamages; let i = index">
                <div class="damage-pic" [style.background-image]="damage.preview">
                    <fa-icon class="zoom" [icon]="['far', 'search-plus']" (click)="showDetail(newDamages, i)"></fa-icon>
                    <fa-icon class="close" [icon]="['far', 'times']" (click)="removeDamage(i)"></fa-icon>
                </div>
                <div class="damage-label">
                    <div class="type">{{ damageTypes[damage.type] | translate }}</div>
                    -
                    <div class="part">{{ vehicleParts[damage.part] | translate }}</div>
                </div>
            </div>
        </div>
    </div>

    <div class="list" *ngIf="damages.length">
        <div class="list-label" (click)="showDamages = !showDamages">
            <div class="label">
                <span class="light-blue">{{ damages.length }}</span>
                <span *ngIf="damages.length === 1">{{ 'CHECK.DECLARE.current_damage' | translate }}</span>
                <span *ngIf="damages.length > 1">{{ 'CHECK.DECLARE.current_damages' | translate }}</span>
            </div>
            <div class="icon">
                <fa-icon [icon]="['far', 'angle-down']" *ngIf="showDamages"></fa-icon>
                <fa-icon [icon]="['far', 'angle-right']" *ngIf="!showDamages"></fa-icon>
            </div>
        </div>
        <div class="list-content" *ngIf="showDamages">
            <div class="disclaimer">{{ 'CHECK.DECLARE.existing_damages' | translate }}</div>

            <div class="old-damage" *ngFor="let damage of damages; let i = index">
                <div class="damage-pic" [style.background-image]="damage.preview">
                    <fa-icon class="zoom" [icon]="['far', 'search-plus']" (click)="showDetail(damages, i)"></fa-icon>
                </div>
                <div class="damage-label">
                    <div class="type">{{ damage.type | translate }}</div>
                    -
                    <div class="part">{{ damage.itemPart | translate }}</div>
                </div>
            </div>
        </div>
    </div>

    <div class="confirm">
        <button class="confirm-button reverse-button" (click)="declareDamage()">
            {{ 'CHECK.DECLARE.add_damage' | translate }}
        </button>
        <button class="confirm-button" (click)="submitEDL()">
            <span *ngIf="!buttonAction">{{ 'GENERIC.confirm' | translate }}</span>
            <fa-icon [spin]="true" [icon]="['far', 'spinner']" *ngIf="buttonAction"></fa-icon>
        </button>
    </div>
</div>

<div class="declare-damage" *ngIf="step === 'declare'">
    <div class="label">{{ 'CHECK.DECLARE.declare_damage' | translate }}</div>

    <div class="form" [formGroup]="newDamageForm">
        <div class="input-text" [ngClass]="{ 'input-error': newDamageForm.controls.damage_type.touched && newDamageForm.controls.damage_type.status === 'INVALID' }">
            <div class="input-label">{{ 'CHECK.DECLARE.damage_type' | translate }}</div>
            <div class="input-group">
                <select formControlName="damage_type">
                    <option *ngFor="let type of damageTypes" [ngValue]="type">
                        {{ type | translate }}
                    </option>
                </select>
                <div class="input-suffix">
                    <fa-icon [icon]="['far', 'angle-down']"></fa-icon>
                </div>
            </div>
            <div class="error" *ngIf="newDamageForm.controls.damage_type.touched && newDamageForm.controls.damage_type.errors?.required">
                <fa-icon [icon]="['fas', 'times-circle']"></fa-icon>
                {{ 'CUSTOM_ERROR.required' | translate }}
            </div>
        </div>

        <div class="input-text" [ngClass]="{ 'input-error': newDamageForm.controls.damage_part.touched && newDamageForm.controls.damage_part.status === 'INVALID' }">
            <div class="input-label">{{ 'CHECK.DECLARE.damage_part' | translate }}</div>
            <div class="input-group">
                <select formControlName="damage_part">
                    <option *ngFor="let type of vehicleParts" [ngValue]="type">
                        {{ type | translate }}
                    </option>
                </select>
                <div class="input-suffix">
                    <fa-icon [icon]="['far', 'angle-down']"></fa-icon>
                </div>
            </div>
            <div class="error" *ngIf="newDamageForm.controls.damage_part.touched && newDamageForm.controls.damage_part.errors?.required">
                <fa-icon [icon]="['fas', 'times-circle']"></fa-icon>
                {{ 'CUSTOM_ERROR.required' | translate }}
            </div>
        </div>

        <div class="input-picture">
            <div class="input-label">{{ 'CHECK.DECLARE.input_picture' | translate }}</div>
            <div class="icon-picture" [style.background-image]="newDamageFormValues.damage_pic.value?.preview">
                <div class="camera-button" *ngIf="!newDamageFormValues.damage_pic.value?.preview">
                    <fa-icon [icon]="['fas', 'camera-alt']" *ngIf="!newDamageFormValues.damage_pic.value?.previewData"></fa-icon>
                    <fa-icon [icon]="['far', 'spinner']" [spin]="true" *ngIf="newDamageFormValues.damage_pic.value?.previewData === 'loading'"></fa-icon>
                </div>
                <div class="camera-label" *ngIf="!newDamageFormValues.damage_pic.value?.preview">
                    {{ 'CHECK.DECLARE.take_a_pic' | translate }}
                </div>
            </div>
            <input type="file" accept="image/x-png,image/jpeg" (change)="getFile('damage', $event)" />
        </div>
    </div>
    <div class="confirm">
        <button class="confirm-button" [disabled]="!newDamageForm.valid" (click)="addDamage()">
            {{ 'CHECK.DECLARE.add_damage' | translate }}
        </button>
    </div>
</div>

<div class="detail-damage" *ngIf="step === 'detail'">
    <div class="damage-picture" [style.background-image]="listDetails[indexDetails].preview"></div>
    <div class="damage-infos">
        <div class="navigation">
            <div class="counter">
                {{ 'CHECK.damage' | translate }} {{ indexDetails + 1 }} / {{ listDetails.length }}
            </div>
            <div class="prev-next">
                <fa-icon [icon]="['fas', 'chevron-circle-left']" [ngClass]="{ disabled: indexDetails === 0 }" (click)="prev()">
                </fa-icon>
                <fa-icon [icon]="['fas', 'chevron-circle-right']" [ngClass]="{ disabled: indexDetails === listDetails.length - 1 }" (click)="next()"></fa-icon>
            </div>
        </div>
        <div class="type">
            {{ damageTypes[listDetails[indexDetails].type] || listDetails[indexDetails].type | translate }}
        </div>
        <div class="part">
            {{ vehicleParts[listDetails[indexDetails].part] || listDetails[indexDetails].view | translate }}
        </div>
    </div>
    <div class="confirm">
        <button class="confirm-button" (click)="closeDetail()">
            {{ 'GENERIC.close' | translate }}
        </button>
    </div>
</div>

<div class="pdf-gen">
    <check-pdf *ngIf="isEdl" [check-id]="checkID" [reservation-id]="reservationID" [type]="type" [generate]="true" (event)="pdfEvent($event)"></check-pdf>
</div>
