<mat-sidenav-container class="menu-container">
    <mat-sidenav class="menu" #menu [disableClose]="headerMenuData.menuOptions?.disableClose" [mode]="headerMenuData.menuOptions?.mode" [opened]="headerMenuData.menuOptions?.opened">
        <div class="top">
            <div class="menu-close">
                <div class="menu-close-icon" *ngIf="!headerMenuData.menuOptions?.disableClose" (click)="toggleMenu(menu)">
                    <fa-icon [icon]="['far', 'times']"></fa-icon>
                </div>
            </div>
            <div class="list">
                <a class="list-link" routerLink="/list-bookings" routerLinkActive="active" (click)="toggleMenu(menu)" *ngIf="isLoggedIn">
                    <div class="list-link-item">
                        <div class="list-link-item-logo">
                            <fa-icon [icon]="['far', 'calendar-check']"></fa-icon>
                        </div>
                        <div class="list-link-item-title">
                            <span>{{ 'MENU.reservation_list' | translate }}</span>
                            <span class="subtitle" *ngIf="alerts.waitingForDepositNumber === 1 && !alerts.lateReservationNumber">{{ 'MENU.reservation_waiting_for_deposit' | translate }}</span>
                            <span class="subtitle" *ngIf="alerts.waitingForDepositNumber > 1 && !alerts.lateReservationNumber">{{ 'MENU.reservation_waiting_for_deposit_plural' | translate:{value: alerts.waitingForDepositNumber} }}</span>
                            <span class="subtitle" *ngIf="alerts.lateReservationNumber === 1">{{ 'MENU.late_reservation' | translate }}</span>
                            <span class="subtitle" *ngIf="alerts.lateReservationNumber > 1">{{ 'MENU.late_reservation_plural' | translate:{value: alerts.lateReservationNumber} }}</span>
                        </div>
                    </div>
                </a>
                <!-- <a class="list-link" routerLink="/map" routerLinkActive="active" (click)="toggleMenu(menu)">
                    <div class="list-link-item">
                        <div class="list-link-item-logo">
                            <fa-icon [icon]="['far', 'car-side']"></fa-icon>
                        </div>
                        <div class="list-link-item-title">
                            <span>{{ 'MENU.search-map' | translate }}</span>
                        </div>
                    </div>
                </a> -->
                <a class="list-link" routerLink="/profile" routerLinkActive="active" (click)="toggleMenu(menu)" *ngIf="isLoggedIn">
                    <div class="list-link-item">
                        <div class="list-link-item-logo">
                            <fa-icon [icon]="['far', 'user-circle']"></fa-icon>
                        </div>
                        <div class="list-link-item-title">
                            <span>{{ 'MENU.profile' | translate }}</span>
                            <span class="subtitle" *ngIf="!alerts.isProfileValid">{{ 'MENU.invalid_profile' | translate }}</span>
                        </div>
                    </div>
                </a>
            </div>
        </div>
        <div class="bottom">
            <div class="language-selection">
                <div class="input-text">
                    <div class="input-group">
                        <div class="input-prefix">
                            <div class="iti__flag {{ currentLanguage?.value?.flag }}"></div>
                        </div>
                        <select class="prefixed" [formControl]="currentLanguage" (change)="onLanguageChange()">
                            <option *ngFor="let lang of languageList; let i = index" [ngValue]="lang">
                                {{ lang.label }}
                            </option>
                        </select>
                        <div class="input-suffix">
                            <fa-icon [icon]="['far', 'angle-down']"></fa-icon>
                        </div>
                    </div>
                </div>
            </div>
            <div class="help">
                <div class="call-center">
                    <fa-icon [icon]="['far', 'phone']"></fa-icon>
                    <span [innerHTML]="'MENU.call_center' | translate"></span>
                    <br>
                    <a href="tel:+33762933630">07 62 93 36 30</a>
                </div>
                <div class="pdf-downloads" routerLink="/help" (click)="toggleMenu(menu)">
                    <fa-icon [icon]="['far', 'question-square']"></fa-icon>
                    <div class="label">{{ 'HELP.title' | translate }}</div>
                </div>
            </div>
            <div class="logout" *ngIf="isLoggedIn">
                <div class="confirm">
                    <button class="confirm-button" (click)="doSignOut()">
                        <span>{{ 'AUTH.SIGN_OUT.sign_out' | translate }}</span>
                    </button>
                </div>
            </div>
        </div>
    </mat-sidenav>

    <mat-sidenav-content>
       <div class="header" [ngClass]="headerMenuData.header.color" *ngIf="headerMenuData.showMenu">
            <div class="back" *ngIf="headerMenuData.header.showBack" (click)="back()">
                <fa-icon [icon]="['far', 'long-arrow-left']"></fa-icon>
            </div>
            <div class="logo" *ngIf="headerMenuData.header.showLogo"></div>
            <div class="menu-toggle" (click)="toggleMenu(menu)" *ngIf="!headerMenuData.menuOptions?.disableClose && isLoggedIn">
                <fa-icon [icon]="['far', 'bars']"></fa-icon>
            </div>
            <div class="menu-toggle" (click)="doSignIn()" *ngIf="!isLoggedIn && headerMenuData.header.showIcon">
                <fa-icon [icon]="['fas', 'user']"></fa-icon>
            </div>
        </div>
        <router-outlet (activate)="onActivate()"></router-outlet>
    </mat-sidenav-content>
</mat-sidenav-container>

<notification #notification></notification>
