import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import * as uuidv3 from 'uuid/v3';

@Injectable()
export class CheckService {
    constructor(
        private http: HttpClient
    ) {}

    public getCheck(input): Observable<any> {
        return this.http.get<any>(environment.BFF + "check/" + input.id);
    }

    public getCheckList(input): Observable<any> {
        return this.http.get<any>(environment.BFF + "checklist/" + input.id);
    }

    public createCheck(input): Observable<any> {
        return this.http.post<any>(environment.BFF + "check", input);
    }

    public validateCheck(input): Observable<any> {
        return this.http.put<any>(environment.BFF + "check/" + input.id + "?key="+input.key, {});
    }

    public updateCheck(input): Observable<any> {
        return this.http.put<any>(environment.BFF + "check/" + input.id +"/update?key="+input.key, {});
    }

    public getDamage(input): Observable<any> {
        return this.http.get<any>(environment.BFF + "damage/" + input.id);
    }

    public getDamageList(): Observable<any> {
        return this.http.get<any>(environment.BFF + "damage");
    }

    public createDamage(input): Observable<any> {
        return this.http.post<any>(environment.BFF + "damage", input);
    }

    // Others
    uploadImage(dataURI: string, part: string, type: string): Observable<any>  {
        let fileName: string = uuidv3(
            part.replace('VEHICLE_PART.', '') + "_" + type.replace('DAMAGE_TYPE.', '') + "_" + new Date().getTime() + "_" + Math.random(),
            uuidv3.DNS
        );

        let dataURItoBlob = (dataURI) => {
            // Convert base64/URLEncoded data component to raw binary data held in a string
            let byteString;
            if (dataURI && dataURI.split(',')[0].indexOf('base64') >= 0)
                byteString = atob(dataURI.split(',')[1]);
            else if (dataURI)
                byteString = unescape(dataURI.split(',')[1]);
            else
                console.log("Data URI is undefined ?");

            // Separate out the mime component
            let mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

            // Write the bytes of the string to a typed array
            let ia = new Uint8Array(byteString.length);
            for (let i = 0; i < byteString.length; i++) {
                ia[i] = byteString.charCodeAt(i);
            }

            return new Blob([ia], { type: mimeString });
        };

        let file = dataURItoBlob(dataURI);

        const formData = new FormData();
        formData.append("file", file, fileName);

        return this.http.post<any>(environment.BFF +'file/public', formData);
    }

    uploadPdf(pdf, id): Observable<any> {

        let fileName: string = uuidv3(
             new Date().getTime() + "_" + Math.random(),
            uuidv3.DNS
        );

        const formData = new FormData();
        formData.append("file", pdf, fileName+".pdf");

        return this.http.post<any>(environment.BFF +'file/pdf?id='+id, formData);
    }
}
