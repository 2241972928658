<div class="loading" *ngIf="loading">
    <fa-icon [icon]="['far', 'circle-notch']" [spin]="true"></fa-icon>
</div>
<form class="profile-form" [formGroup]="profileInfosForm" (ngSubmit)="updateProfile()" *ngIf="!loading">
    <div class="title">{{ 'USER.title' | translate }}</div>

    <div class="card">
        <documents path="user" [updated]="submitted"></documents>
    </div>

    <div class="form-card">
        <div class="form-card-title">{{ 'USER.personal_informations' | translate }}</div>
        <div class="input-radios" [ngClass]="{ 'input-error':  profileInfosForm.controls.gender.status === 'INVALID' }">
            <div class="input-radios-horizontal">
                <label class="input-radio">
                    <input type="radio" value="M" formControlName="gender" [disabled]= "isReadOnly" />
                    {{ 'INTERFACES.GENDER.male' | translate }}
                </label>
                <label class="input-radio">
                    <input type="radio" value="F" formControlName="gender" [disabled]= "isReadOnly" />
                    {{ 'INTERFACES.GENDER.female' | translate }}
                </label>
            </div>
            <div class="error" *ngIf="profileInfosForm.controls.gender.errors?.required">
                <fa-icon [icon]="['fas', 'times-circle']"></fa-icon>
                {{ 'CUSTOM_ERROR.required' | translate }}
            </div>
        </div>
        <div class="input-text" [ngClass]="{ 'input-error': profileInfosForm.controls.family_name.status === 'INVALID' }">
            <div class="subtitle">{{ 'USER.family_name' | translate }}</div>
            <input class="uppercase" type="text" formControlName="family_name" [readonly]= "isReadOnly" />
            <div class="error" *ngIf="profileInfosForm.controls.family_name.errors?.required">
                <fa-icon [icon]="['fas', 'times-circle']"></fa-icon>
                {{ 'CUSTOM_ERROR.required' | translate }}
            </div>
        </div>
        <div class="input-text" [ngClass]="{ 'input-error': profileInfosForm.controls.given_name.status === 'INVALID' }">
            <div class="subtitle">{{ 'USER.given_name' | translate }}</div>
            <input class="capitalize" type="text" formControlName="given_name" [readonly]= "isReadOnly" />
            <div class="error" *ngIf="profileInfosForm.controls.given_name.errors?.required">
                <fa-icon [icon]="['fas', 'times-circle']"></fa-icon>
                {{ 'CUSTOM_ERROR.required' | translate }}
            </div>
        </div>
        <div class="input-text input-phone" [ngClass]="{ 'input-error':profileInfosForm.controls.phone.status === 'INVALID' }">
            <div class="subtitle">{{ 'USER.phone' | translate }}</div>
            <input type="text" formControlName="phone" ng2TelInput [ng2TelInputOptions]="{ initialCountry: 'fr' }" (keypress)="numericOnly($event)"/>
            <div class="error" *ngIf="profileInfosForm.controls.phone.errors?.required">
                <fa-icon [icon]="['fas', 'times-circle']"></fa-icon>
                {{ 'CUSTOM_ERROR.required' | translate }}
                
            </div>
            <div class="error" *ngIf="profileInfosForm.controls.phone?.invalid &&  !profileInfosForm.controls.phone.errors?.required">
                <fa-icon [icon]="['fas', 'times-circle']"></fa-icon>
                {{ 'CUSTOM_ERROR.phone_invalid' | translate }}
            </div>
        </div>
    </div>
    <div class="form-card">
        <div class="form-card-title">{{ 'USER.address' | translate }}</div>
        <div class="input-text" [ngClass]="{ 'input-error': profileInfosForm.controls.street_address.status === 'INVALID' }">
            <div class="subtitle">{{ 'USER.street_address' | translate }}</div>
            <input type="text" #addressAutocomplete formControlName="street_address" (keyup.enter)="addressAutocomplete.blur()" />
            <div class="error" *ngIf="profileInfosForm.controls.street_address.errors?.required">
                <fa-icon [icon]="['fas', 'times-circle']"></fa-icon>
                {{ 'CUSTOM_ERROR.required' | translate }}
            </div>
        </div>
        <div class="input-text">
            <div class="subtitle">{{ 'USER.additional' | translate }}</div>
            <input type="text" formControlName="additional" />
        </div>
        <div class="input-text" [ngClass]="{ 'input-error': profileInfosForm.controls.post_code.status === 'INVALID' }">
            <div class="subtitle">{{ 'USER.post_code' | translate }}</div>
            <input type="text" formControlName="post_code"  maxlength="12"/>
            <div class="error" *ngIf=" profileInfosForm.controls.post_code.errors?.required">
                <fa-icon [icon]="['fas', 'times-circle']"></fa-icon>
                {{ 'CUSTOM_ERROR.required' | translate }}
            </div>
        </div>
        <div class="input-text" [ngClass]="{ 'input-error': profileInfosForm.controls.city.status === 'INVALID' }">
            <div class="subtitle">{{ 'USER.city' | translate }}</div>
            <input type="text" formControlName="city"  />
            <div class="error" *ngIf="profileInfosForm.controls.city.errors?.required">
                <fa-icon [icon]="['fas', 'times-circle']"></fa-icon>
                {{ 'CUSTOM_ERROR.required' | translate }}
            </div>
        </div>
        <div class="input-text" [ngClass]="{ 'input-error':  profileInfosForm.controls.country.status === 'INVALID'}">
            <div class="subtitle">{{ 'USER.country' | translate }}</div>
            <select class="country" formControlName="country" (change)="onCountryChange()">
                <option *ngFor="let country of countries;  let i = index" [value]="countries[i].name" >
                    {{ countries[i].name }}
                </option>
            </select>
            <div class="error" *ngIf="profileInfosForm.controls.country.errors?.required">
                <fa-icon [icon]="['fas', 'times-circle']"></fa-icon>
                {{ 'CUSTOM_ERROR.required' | translate }}
            </div>
        </div>
    </div>

    <div class="form-card">
        <div class="form-card-title">{{ 'USER.ids' | translate }}</div>
        <div class="subtitle">{{ 'USER.sign_in_id' | translate }}</div>
        <div class="email">{{ profile.email }}</div>
    </div>

    <div class="actions">
        <div class="confirm">
            <button class="confirm-button" [disabled]="!profileInfosForm.valid || profileInfosForm.pristine || submitted" type="submit">
                <span *ngIf="!submitted">{{ 'GENERIC.edit' | translate }}</span>
                <fa-icon *ngIf="submitted" [icon]="['far', 'sync']" [spin]="true"></fa-icon>
            </button>
        </div>
    </div>
</form>
