<div class="title">Réservation {{ reservationID }}</div>

<div *ngIf='payment==="false"'>{{ 'ADDITIONAL.LABEL.upToDate' | translate }}</div>
<div class="containerButton" *ngIf='payment==="false"'>
  <button class="confirm-button" (click)="goToKey()">
    {{ 'ADDITIONAL.LABEL.goToLocation'  | translate }}
</button>
  <!-- <a href="list-bookings" class="confirm-button">{{ 'ADDITIONAL.LABEL.goToLocations' | translate }}</a> -->
</div>

<app-link-payment-pricing class="card" *ngIf='payment=="true"' [total]='total' [transactionsData]='transactionsData' [opened]="false"></app-link-payment-pricing>
<app-link-payment-widget *ngIf='payment==="true"' [total]='total' [transactionsData]='transactionsData'></app-link-payment-widget>