import { Component, OnChanges, Input } from '@angular/core';
import { Router } from '@angular/router';

import { UbbleService } from 'src/app/services/ubble';
import { UserService } from 'src/app/services/user';

import * as moment from "moment";

var _extract = str => str;

// Document verification Status
_extract("DOCUMENTS.VERIFIED");
_extract("DOCUMENTS.NOT_VERIFIED");
_extract("DOCUMENTS.WAITING_FOR_VERIFICATION");
_extract("DOCUMENTS.MISSING_INFORMATIONS");
_extract("DOCUMENTS.EXPIRED");

@Component({
    selector: 'documents',
    templateUrl: './documents.html',
    styleUrls: ['./documents.scss']
})
export class DocumentsComponent implements OnChanges {
    @Input() path: string;
    @Input() updated: boolean;

    public documentStatus: string;
    public profileStatus: string;
    public style: any = {
        ngClass: "",
        icon: ""
    };

    public loading: boolean = true;
    public action: boolean = false;

    constructor(
        private router: Router,
        private userService: UserService,
        private ubbleService: UbbleService
    ) {}

    ngOnChanges() {
        this.userService.getUser().toPromise().then((response) => {
            // console.log("documents - user.documents: ", response.result);
            this.setProfileStatus(response.result);
            // console.log("documents - profileStatus: ", this.profileStatus);
            this.setDocumentStatus(response.result.documents);
            // console.log("documents - documentStatus: ", this.documentStatus);
            this.setStyle();
            this.loading = false;
        });
    }

    private setProfileStatus(user) {
        if (!user.address || !user.gender || !user.phoneNumber) {
            this.profileStatus = "MISSING_INFORMATIONS";
        } else {
            this.profileStatus = "VALID";
        }
    }

    private setDocumentStatus(documents) {
        console.log(documents);
        if (!documents?.length) return this.documentStatus = "NOT_VERIFIED";
        let docs = [];
        for (let i = 0; i < documents.length; i++) {
            // console.log(moment(documents[i].expirationDatetime), moment());
            if (
                documents[i].status === "PENDING_VERIFICATION"
                || documents[i].status === "VERIFICATION_FAILURE"
                || documents[i].status === "VERIFICATION_INITIATED"
            ) docs.push(documents[i].status);
            else if (documents[i].expirationDatetime != undefined && moment(documents[i].expirationDatetime).isBefore(moment()) ) docs.push("EXPIRED");
        }

        if (docs.length === 0) {
            this.documentStatus = "VERIFIED";
        } else if (docs.includes("EXPIRED")) {
            this.documentStatus = "EXPIRED";
        } else if (docs.includes("VERIFICATION_FAILURE") || docs.includes("VERIFICATION_INITIATED")) {
            this.documentStatus = "NOT_VERIFIED";
        } else if (docs.includes("PENDING_VERIFICATION")) {
            this.documentStatus = "WAITING_FOR_VERIFICATION";
        }
    }

    private setStyle() {
        if ((this.documentStatus === "VERIFIED" || this.documentStatus === "WAITING_FOR_VERIFICATION") && this.profileStatus === "VALID") {
            this.style.ngClass = "blue";
            this.style.icon = this.documentStatus === "VERIFIED" ? "check-circle" : "hourglass-half";
        } else if (this.documentStatus === "EXPIRED") {
            this.style.ngClass = "orange";
            this.style.icon = "clock";
        } else if (this.documentStatus === "NOT_VERIFIED" && this.profileStatus === "MISSING_INFORMATIONS") {
            this.style.ngClass = "red";
        } else if (this.documentStatus === "NOT_VERIFIED" || this.profileStatus === "MISSING_INFORMATIONS") {
            this.style.ngClass = "orange";
        }
    }

    public checkDocuments() {
        if (this.action) return;
        this.action = true;
        this.ubbleService.getLink(window.location.href).toPromise().then((response) => {
            let url = response.data.attributes["identification-url"];
            // console.log("ubbleService.getLink: ", response);
            this.action = false;
            if (url) this.openLink(url);
        });
    }

    private openLink(url) {
        const link = document.createElement('a');
        link.href = url;
        link.click();
    }

    public goToProfile() {
        this.router.navigate(['profile']);
    }
}
