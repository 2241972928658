import { Component, OnInit } from '@angular/core';

import { Transactions } from 'src/app/interfaces/models/additional';
import { TranslateService } from '@ngx-translate/core';

import { NotificationManager } from 'src/app/notification/notification';

import { ActivatedRoute, Router } from '@angular/router';

import { TransactionService } from 'src/app/services/transaction';
import { HeaderMenuService } from 'src/app/services/header-menu';
import { stringify } from 'querystring';

@Component({
    selector: 'app-additional-payment',
    templateUrl: './payment.html',
    styleUrls: ['./payment.scss'],
})
export class LinkPaymentComponent implements OnInit {

    public reservationID: number;
    public transactionsData: Transactions[] = [];
    public total: number = 0;
    public payment: string;
    private deposit : boolean

    constructor(
        private route : ActivatedRoute,
        private transaction : TransactionService,
        private headerMenuService: HeaderMenuService,
        private translateService: TranslateService,
        private notificationManager: NotificationManager,
        private router: Router
    ) {}

    ngOnInit(): void {

        this.headerMenuService.setShowMenu(true);
        this.headerMenuService.setHeader({
            color: 'default',
            showLogo: true,
            showBack: false,
            showIcon: true
        });

        this.reservationID = this.route.snapshot.queryParams.reservationID;

        this.transaction.getTransactionList({ id : this.reservationID }).subscribe(res => {
            // console.log("response : ", res);
            if (res === null) {
                this.notificationManager.addNotification(this.translateService.instant("INTERFACES.ERROR.API.-31100"), "error", 5000, true);
                this.router.navigate(['map']);
            } else {
                this.sortTransactions(res);
                this.deposit = this.getBalance(res)
                if (this.total <= 0) {
                    this.payment = "false";
                } else {
                    this.payment = "true";
                    if (localStorage.done) {
                        localStorage.removeItem('done');
                    }
                }
            }
        });
    }

    getBalance(liste) {
        console.log(liste)
        let total = 0;
        liste.forEach(item => {
            if (item.type === "DEPOSIT") {
                total += item.amount.value;
            }
        });
        return total > 0 ? true : false;
    }

    sortTransactions(transaction) {
        for (const trans of transaction) {
            if (trans.type === "PAYMENT" ) {
                console.log(stringify(transaction))
                if (transaction.some(t => t.label === trans.id) || transaction.some(t => t.id === trans.label)) {
                    continue;
                } else {
                    this.transactionsData.push(trans);
                    this.total += (trans.amount.value / 100);
                }
            }
        }
    }


    goToKey() {

        if (this.deposit ){
            this.router.navigate(['deposit'], { queryParams: {  id: this.reservationID } });
        }else{
            this.router.navigate(['detail'], { queryParams: {  id: this.reservationID } });
        }
        
    }
        
}
