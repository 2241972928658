import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root',
})
export class GMapLoader {

    public isLoaded: Promise<boolean>;

    constructor(private httpClient: HttpClient) {
        this.isLoaded = this.httpClient.jsonp("https://maps.googleapis.com/maps/api/js?key=" + environment.gmapApiKey + "&libraries=places", "callback").pipe(
            map(() => true),
            catchError((error) => {
                console.error("Map Error: ", error);
                return of(false);
            })
        ).toPromise();
    }
}
