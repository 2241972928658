import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from "src/environments/environment";

@Injectable()
export class TransactionService {

    constructor(private http: HttpClient) {}

    public getTransactionList(input: any): Observable<any> {
        return this.http.get<any>(environment.BFF + "transactions/" + input.id);
    }

    public getTokenPayment(input: any): Observable<any> {
        return this.http.post<any>(environment.BFF + "transaction/payment/token", input);
    }

    public getTokenDeposit(input: any): Observable<any> {
        return this.http.post<any>(environment.BFF + "transaction/deposit/token", input);
    }

    public verifyTokenPayment(input: any): Observable<any> {
        return this.http.post<any>(environment.BFF + "transaction/payment/verify", input);
    }

    public verifyTokenDeposit(input: any): Observable<any> {
        return this.http.post<any>(environment.BFF + "transaction/deposit/verify", input);
    }
    public verifyToken(input: any): Observable<any> {
        return this.http.post<any>(environment.BFF + "transaction/deposit/verifytoken", input);
    }
    public verifyBalance(input: any): Observable<any> {
        return this.http.get<any>(environment.BFF + "transaction/"+input+"/verifybalance");
    }
}
