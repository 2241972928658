<div *ngIf="!loading">
    <div class="label" (click)="toggleCard()">
        {{ 'RESERVATION.CLIENT.DETAILS.pricing' | translate }}
        <fa-icon [icon]="['far', isOpened ? 'angle-up' : 'angle-down']"></fa-icon>
    </div>

    <div class="price" *ngIf="isOpened">
        <div class="total">
            <div class="name">{{ 'TRANSACTION.PRICING.paid' | translate }}</div>
            <div *ngIf="paymentDeposit" class="orange">
                {{ price.paid | number:'1.2' }}{{ 'GENERIC.euros' | translate }}
            </div>
            <div *ngIf="!paymentDeposit" class="value">
                {{ price.paid }}{{ 'GENERIC.euros' | translate }}
            </div>
        </div>
        <div *ngIf="paymentDeposit" class="signal mb-10">
            {{ 'TRANSACTION.PRICING.waitingPayment' | translate }}
        </div>
        <div class="total mb-10">
            <div class="name">{{ 'TRANSACTION.PRICING.total' | translate }}</div>
            <div class="value">{{ price.total }}{{ 'GENERIC.euros' | translate }}</div>
        </div>
        <div class="rental" *ngIf="price.rental !== price.total">
            <div class="name">- {{ 'TRANSACTION.PRICING.rental' | translate }}</div>
            <div class="value">{{ price.rental }}{{ 'GENERIC.euros' | translate }}</div>
        </div>
        <div class="addedDriver" *ngIf="price.extension > 0">
            <div class="name">- {{ 'TRANSACTION.PRICING.extension' | translate }}</div>
            <div class="value">{{ price.extension }}{{ 'GENERIC.euros' | translate }}</div>
        </div>
        <!-- <div class="addedDriver">
            <div class="name">- {{ 'TRANSACTION.PRICING.addedKM' | translate }}</div>
            <div class="value">{{ price.addedKM }}{{ 'GENERIC.euros' | translate }}</div>
        </div> -->
        <div class="addedDriver" *ngIf="price.addedDriver > 0">
            <div class="name">- {{ 'TRANSACTION.PRICING.addedDrivers' | translate }}</div>
            <div class="value">{{ price.addedDriver }}{{ 'GENERIC.euros' | translate }}</div>
        </div>
        <div class="addedDriver" *ngIf="price.securityPack > 0">
            <div class="name">- {{ 'TRANSACTION.PRICING.securityPack' | translate }}</div>
            <div class="value">{{ price.securityPack }}{{ 'GENERIC.euros' | translate }}</div>
        </div>
        <button class="goToPayment" *ngIf="paymentDeposit && reservationID" (click)="goToPayment()">
            {{ 'TRANSACTION.PRICING.pay' | translate }} {{ price.paid | number:'1.2' }} €
        </button>
    </div>
    <div class="infos" *ngIf="isOpened">
        <div class="includedKM">
            <div class="name">{{ 'TRANSACTION.INFOS.includedKM' | translate }}</div>
            <div class="value">{{ includedKM + addedKM }} km</div>
        </div>
        <div class="addedDriver" *ngIf="extension > 0">
            <div class="name">{{ 'TRANSACTION.INFOS.extension' | translate }}</div>
            <div class="value">{{ extension }} {{ 'ADDITIONAL.UNITY.day' | translate }}</div>
        </div>
        <div class="addedDriver" *ngIf="addedDriver > 0">
            <div class="name">{{ 'TRANSACTION.PRICING.addedDrivers' | translate }}</div>
            <div class="value">{{ addedDriver }}</div>
        </div>
        <div class="addedDriver" *ngIf="securityPack > 0">
            <div class="name">{{ 'TRANSACTION.INFOS.securityPack' | translate }}</div>
            <div class="value">{{ securityPack }} {{ 'ADDITIONAL.UNITY.day' | translate }}</div>
        </div>
    </div>

    <div class="label mb-10" *ngIf="isOpened  && price.totalExcess > 0">
        {{ 'TRANSACTION.LABEL.additionalFees' | translate }}
    </div>

    <div class="price" *ngIf="isOpened && price.totalExcess > 0">
        <div class="total">
            <div class="name">{{ 'TRANSACTION.PRICING.paid' | translate }}</div>
            <div [ngClass]="price.paidExcess == price.totalExcess ? 'value' : 'orange'">
                {{ price.paidExcess }}{{ 'GENERIC.euros' | translate }}
            </div>
        </div>
        <div *ngIf="price.paidExcess != price.totalExcess" class="signal mb-10">
            {{ 'TRANSACTION.PRICING.waitingPayment' | translate }}
        </div>
        <div class="total mb-10">
            <div class="name">{{ 'TRANSACTION.PRICING.total' | translate }}</div>
            <div class="value">{{ price.totalExcess }}{{ 'GENERIC.euros' | translate }}</div>
        </div>
        <div *ngFor="let item of excessList" class="details">
            <div class="rental" *ngIf="item.affichage">
                <div class="name">- {{ item.affichage | translate }}</div>
                <div class="value">{{ item.value }}{{ 'GENERIC.euros' | translate }}</div>
            </div>
            <div class="quantity mb-5" *ngIf="item.unity && item.quantity > 0">+{{ item.quantity }} {{ item.unity | translate }}</div>
            <div class="quantity mb-5" *ngIf="item.unity && item.quantity < 0">{{ item.quantity }} {{ item.unity | translate }}</div>
        </div>
        <button class="goToPayment" *ngIf="price.paidExcess != price.totalExcess" (click)="goToPayment()">{{ 'TRANSACTION.PRICING.pay' | translate }} 
            {{ (price.totalExcess - price.paidExcess) + (price.total - price.paid) | number:'1.2' }} €
        </button>
    </div>

</div>
