<div>
    <div class="label" (click)="toggleCard()">
        {{ 'RESERVATION.CLIENT.DETAILS.deposit_label' | translate }}
        <fa-icon [icon]="['far', isOpened ? 'angle-up' : 'angle-down']"></fa-icon>
    </div>

    <div class="price" *ngIf="isOpened">
        <div class="name">{{ 'TRANSACTION.DEPOSIT.amount' | translate }}:</div>
        <div class="value">900 {{ 'GENERIC.euros' | translate }}</div>
    </div>

    <div class="info" *ngIf="isOpened">{{ 'RESERVATION.CLIENT.DETAILS.deposit_info' | translate }}</div>
    <div class="info" *ngIf="isOpened">{{ 'RESERVATION.CLIENT.DETAILS.deposit_required' | translate }}</div>
    <div class="info" *ngIf="isOpened">{{ 'RESERVATION.CLIENT.DETAILS.deposit_warning' | translate }}</div>
</div>
