import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { Location } from '@angular/common';

import { TranslateService } from '@ngx-translate/core';
import { NgxImageCompressService } from 'ngx-image-compress';

import { CheckService } from 'src/app/services/check';
import { ReservationService } from 'src/app/services/reservation';

import { EDLStorage } from 'src/app/services/edl.storage';
import { HeaderMenuService } from 'src/app/services/header-menu';
import { ModalManager } from 'src/app/modal/global/global';
import { NotificationManager } from 'src/app/notification/notification';

import { VehicleParts, DamageTypes } from 'src/app/interfaces/check.infos';

import { environment } from 'src/environments/environment';
import * as moment from 'moment';

var _extract = str => str;

@Component({
    selector: 'declare-check',
    templateUrl: './declare-check.html',
    styleUrls: ['./declare-check.scss']
})
export class DeclareCheckComponent implements OnInit {
    public type: string; // 'IN', 'OUT'
    public agencyID: string;
    public vehicleID: string;
    public reservationID: string;

    public newDamageForm = new FormGroup({
        damage_type: new FormControl(null, [Validators.required]),
        damage_part: new FormControl(null, [Validators.required]),
        damage_pic: new FormControl(null, [Validators.required]),
    });
    get newDamageFormValues() {
        return {
            damage_type: this.newDamageForm.get('damage_type'),
            damage_part: this.newDamageForm.get('damage_part'),
            damage_pic: this.newDamageForm.get('damage_pic'),
        };
    }

    // Attributes
    public step: string;
    public newDamages = [];
    public damages = [];
    public buttonAction: boolean = false;
    public returnPic = [{
        label: _extract("INTERFACES.CHECK_INFOS.SIDE.front"),
        previewData: null,
        preview: null,
        url: null,
    }, {
        label: _extract("INTERFACES.CHECK_INFOS.SIDE.passenger"),
        previewData: null,
        preview: null,
        url: null,
    }, {
        label: _extract("INTERFACES.CHECK_INFOS.SIDE.back"),
        previewData: null,
        preview: null,
        url: null,
    }, {
        label: _extract("INTERFACES.CHECK_INFOS.SIDE.driver"),
        previewData: null,
        preview: null,
        url: null,
    }];
    public check: any;
    public checkID: string;
    public isEdl = false

    // View Modifiers
    public showNewDamages: boolean = true;
    public showDamages: boolean = false;
    public retPicDone: boolean = false;
    public listDetails: any = [];
    public indexDetails: number = null;
    public vehicleParts = VehicleParts;
    public damageTypes = DamageTypes;
    private currentAction: boolean = false;
    public loading = true;

    constructor(
        private _activatedRoute: ActivatedRoute,
        private router: Router,
        private translate: TranslateService,
        private modalManager: ModalManager,
        private imageCompress: NgxImageCompressService,
        private sanitization: DomSanitizer,
        private checkService: CheckService,
        private reservationService: ReservationService,
        private edlStorage: EDLStorage,
        private headerMenuService: HeaderMenuService,
        private notificationManager: NotificationManager,
        private location: Location
    ) {}

    ngOnInit() {
        this.headerMenuService.setShowMenu(true);
        this.headerMenuService.setHeader({
            color: 'default',
            showLogo: false,
            showBack: true,
            showIcon: true
        });

        this._activatedRoute.queryParams.subscribe((params) => {
            this.type = params['type'];
            this.agencyID = params['agency'];
            this.vehicleID = params['vehicle'];
            this.reservationID = params['booking'];
        });

        this.inputsLoaded().then(() => {
            this.getExistingDamages().then(() => {
                this.newDamages = this.setPreviewPictures(this.edlStorage.getCurrentNewDamages());
                if (this.type === 'IN') {
                    this.step = 'start';
                } else if (this.type === 'OUT') {
                    this.step = 'start';
                }

                this.loading = false;
            });
        });
    }

    inputsLoaded = (): Promise<any> => {
        return new Promise<any>((resolve) => {
            let check = setInterval(() => {
                if (this.vehicleID && this.type && this.agencyID && this.reservationID) {
                    clearInterval(check);
                    resolve(true);
                }
            }, 500);
        });
    };

    setPreviewPictures(pictures) {
        for (let i = 0; i < pictures.length; i++) {
            pictures[i].preview = this.sanitization.bypassSecurityTrustStyle(`url(${pictures[i].previewData})`);
        }
        return pictures;
    }

    async getExistingDamages() {
        return new Promise<any>((resolve) => {
            this.checkService.getCheckList({ id: this.vehicleID }).toPromise().then((response) => {
                let checkList = response.result;

                // Filtering checks that aren't null
                checkList = checkList.filter((damage) => { return !!damage; });

                // Ordering damage from recent to ancient
                checkList = checkList.sort((prev, next) => {
                    return moment(prev.createdAt).diff(next.createdAt) * -1;
                });

                // Extracting damages
                for (let i = 0; i < checkList.length; i++) {
                    if (checkList[i].damages) {
                        this.damages.push(...checkList[i].damages);
                    }
                }

                // Setting preview picture
                for (let j = 0; j < this.damages.length; j++) {
                    this.damages[j].preview = this.sanitization.bypassSecurityTrustStyle(
                        `url(https://s3.eu-west-1.amazonaws.com/io.carmoov.damages.${environment.branch}/public/${this.damages[j].mediaPath})`
                    );
                }

                resolve(true);
            });
        });
    }

    declareDamage() {
        this.newDamageFormValues.damage_type.setValue(this.damageTypes[0]);
        this.newDamageFormValues.damage_part.setValue(this.vehicleParts[0]);
        this.step = 'declare';
    }

    addDamage() {
        if (this.currentAction) {
            return;
        }
        this.currentAction = true;

        this.newDamages.push({
            part: this.newDamageFormValues.damage_part.value,
            type: this.newDamageFormValues.damage_type.value,
            previewData: this.newDamageFormValues.damage_pic.value['previewData'],
            preview: this.newDamageFormValues.damage_pic.value['preview'],
        });
        this.newDamageForm.reset();
        this.edlStorage.setCurrentNewDamages(this.newDamages);
        this.step = 'list';
        this.currentAction = false;
    }

    removeDamage(index) {
        this.newDamages.splice(index, 1);
        this.edlStorage.setCurrentNewDamages(this.newDamages);
    }

    showDetail(list, index) {
        this.listDetails = list.items || list;
        this.indexDetails = index;
        this.step = 'detail';
    }

    next() {
        if (this.indexDetails < this.listDetails.length - 1) {
            this.indexDetails += 1;
        }
    }
    prev() {
        if (this.indexDetails > 0) {
            this.indexDetails -= 1;
        }
    }

    closeDetail() {
        this.listDetails = [];
        this.indexDetails = null;
        this.step = 'list';
    }

    getFile(type, event) {
        if (type === 'damage') {
            this.newDamageFormValues.damage_pic.setValue({ preview: null, previewData: 'loading' });
        } else {
            this.returnPic[type].previewData = 'loading';
        }

        if (event.target.files && event.target.files[0]) {
            const reader = new FileReader();
            reader.readAsDataURL(event.target.files[0]);
            reader.onload = async () => {
                this.assignImageTo(type, await this.compressFile(reader.result));
            };
        }
    }

    getImageDimensions(file) {
        return new Promise(function (resolved, rejected) {
            var i = new Image();
            i.onload = function () {
                resolved({ w: i.width, h: i.height });
            };
            i.src = file;
        });
    }

    compressFile(image: any, minSize: boolean = false) {
        return new Promise<any>(async (resolve) => {
            if (!minSize) {
                // Reducing max size to attain max 500px
                let dim = await this.getImageDimensions(image);
                let ratio = (500 / (dim['w'] > dim['h'] ? dim['w'] : dim['h'])) * 100;
                if (ratio > 100) ratio = 100;
                this.imageCompress.compressFile(image, Math.round(ratio), 100).then((result) => {
                    resolve(this.compressFile(result, true));
                });
            } else if (this.imageCompress.byteCount(image) > 250000) {
                // Reducing quality to attain max 250ko
                let ratio = (250000 / this.imageCompress.byteCount(image)) * 100;
                this.imageCompress.compressFile(image, 100, Math.round(ratio)).then((result) => {
                    resolve(this.compressFile(result, true));
                });
            } else {
                resolve(image);
            }
        });
    }

    assignImageTo(type, image) {
        let damage_pic = {};
        if (type === 'damage') {
            damage_pic['previewData'] = image;
            damage_pic['preview'] = this.sanitization.bypassSecurityTrustStyle(`url(${damage_pic['previewData']})`);
            this.newDamageFormValues.damage_pic.setValue(damage_pic);
        } else {
            this.returnPic[type].previewData = image;
            this.returnPic[type].preview = this.sanitization.bypassSecurityTrustStyle(
                `url(${this.returnPic[type].previewData})`
            );
            this.areRetPicDone();
        }

    }

    async areRetPicDone() {
        this.retPicDone =
            this.returnPic[0].preview !== null &&
            this.returnPic[1].preview !== null &&
            this.returnPic[2].preview !== null &&
            this.returnPic[3].preview !== null;
    }

    submitEDL() {
        if (this.buttonAction) {
            return;
        }
        this.buttonAction = true;

        let title = "", template = "", templateDate = {};
        if (this.type === "IN") {
            title = this.translate.instant("CHECK.DECLARE.confirm_check_in");
            template = this.translate.instant("CHECK.DECLARE.template_in");
        } else if  (this.type === "OUT") {
            title = this.translate.instant("CHECK.DECLARE.confirm_check_out");
            template = this.translate.instant("CHECK.DECLARE.template_out");
            templateDate = { warning: this.translate.instant("CHECK.DECLARE.warning_out") };
        }

        this.modalManager.openModal(title, template, templateDate, true, false, async () => {
            this.validateEDL();
        }, () => {
            this.buttonAction = false;
        });
    }

    validateEDL() {
        // Create check
        this.checkService.createCheck({ "itemId": this.vehicleID, "validated": false }).toPromise().then(async (check) => {
            // Add damages
            this.checkID = check.id;
            for (let i = 0; i < this.newDamages.length; i++) {
                // Upload images to S3 bucket
                this.newDamages[i].url = await this.checkService.uploadImage(
                    this.newDamages[i].previewData,
                    this.newDamages[i].part,
                    this.newDamages[i].type
                ).toPromise();

                let key = this.newDamages[i].url.fileId.split('/')[1];

                await this.checkService.createDamage({
                    "checkId": check.id,
                    "mediaPath": key,
                    "type": this.newDamages[i].type,
                    "itemPart": this.newDamages[i].part,
                    "description" : "" // TODO: Add input for description ???
                }).toPromise().then(() => {});
            }

            // Generate PDF
            this.isEdl = true;
        });
    }

    pdfEvent(response) {
        // console.log("declare-check - pdfEvent: ", response);
        if (response.fileId) {
            // Add pdf file ID to check
            this.checkService.updateCheck({ id: this.checkID, key: response.fileId }).toPromise().then(() => {
                // Validate Check & either open or close the reservation
                this.checkService.validateCheck({ id: this.checkID, key: response.fileId }).toPromise().then(() => {
                    if (this.type === 'IN') {
                        this.reservationService.openReservation({ "id": this.reservationID, "checkInId": this.checkID }).toPromise().then(() => {
                            // console.log("Reservation opened !");
                            this.endEDL();
                        });
                    } else if (this.type === 'OUT') {
                        this.reservationService.closeReservation({ "id": this.reservationID, "checkOutId": this.checkID }).toPromise().then(() => {
                            // console.log("Reservation opened !");
                            this.endEDL();
                        });
                    }
                });
            }).catch((error) => {
                console.log("updateCheck error: ", error);
            });
        } else {
            this.notificationManager.addNotification(this.translate.instant("CHECK.DECLARE.failed_pdf_upload"), "error", 3000, true);
            this.currentAction = false;
            this.buttonAction = false;
            this.isEdl = false;
        }
    }

    endEDL() {
        // Clear
        this.edlStorage.clearStorage();
        this.notificationManager.addNotification(this.translate.instant("CHECK.DECLARE.pdf_sent_notification"), "success", 3000, true);
        this.currentAction = false;
        this.buttonAction = false;

        if (this.type === "OUT") {
            this.notificationManager.addNotification(this.translate.instant("CHECK.DECLARE.end_booking_notification"), "success", 3000, true);
            this.router.navigate(["list-bookings"]);
        } else {
            this.location.back();
        }
    }
}
