<div *ngIf="!loading">
    <div class="label" (click)="toggleCard()">
        {{ 'RESERVATION.CLIENT.DETAILS.vehicle' | translate }}
        <fa-icon [icon]="['far', isOpened ? 'angle-up' : 'angle-down']"></fa-icon>
    </div>

    <div class="main-infos" *ngIf="isOpened">
        <div class="category-infos">
            <div class="category">{{ category.title | translate }}</div>
            <div class="seats">
                <fa-icon [icon]="['fas', 'user']"></fa-icon>
                <span class="bold-blue">{{ category.seats || 4 }} </span>
                <span>{{ 'RESERVATION.CLIENT.DETAILS.seats' | translate }}</span>
            </div>
        </div>
        <div class="type" *ngIf="vehicle">{{ vehicle.id }} - {{ vehicle.brand + " " + vehicle.model }}</div>
        <div class="type" *ngIf="!vehicle">{{ category.carTypes.join(', ') }} {{ 'RESERVATION.CLIENT.DETAILS.or_similar' | translate }}</div>
    </div>

    <hr class="separator" *ngIf="isOpened">

    <div class="sub-infos" *ngIf="isOpened">
        <div class="transmission">
            <div class="name">{{ 'RESERVATION.CLIENT.DETAILS.transmission' | translate }}</div>
            <div class="value">{{ category.transmission | translate }}</div>
        </div>
        <div class="engine">
            <div class="name">{{ 'RESERVATION.CLIENT.DETAILS.engine' | translate }}</div>
            <div class="value">
                <span *ngFor="let type of category.fuelType">{{ type | translate }} </span>
            </div>
        </div>
        <div class="doors">
            <div class="name">{{ 'RESERVATION.CLIENT.DETAILS.doors' | translate }}</div>
            <div class="value">{{ category.doors || 5 }}</div>
        </div>
    </div>
</div>
