import { Component, OnChanges, Input, HostListener } from '@angular/core';
import { ItemService } from 'src/app/services/item';
import { Categories } from 'src/app/interfaces/categories';

@Component({
    selector: 'vehicle',
    templateUrl: './vehicle.html',
    styleUrls: ['./vehicle.scss']
})
export class VehicleComponent implements OnChanges {
    @Input('category-id') categoryID: string;
    @Input('vehicle-id') vehicleID: string;
    @Input('opened') opened: boolean;

    public category: any;
    public vehicle: any;
    public isOpened: boolean = true;
    public loading: boolean = true;

    constructor(private itemService: ItemService) {}

    ngOnChanges() {
        // console.log("Vehicle: ", this.categoryID, this.vehicleID);
        if (this.categoryID) {
            this.category = Categories[this.categoryID];
            this.loading = false;
        } else if (this.vehicleID) {
            this.itemService.getItem({ id: this.vehicleID }).toPromise().then((res) => {
                this.vehicle = res.result;
                this.category = Categories[this.vehicle.category];
                this.loading = false;
            });
        }
        if (this.opened !== null) {
            this.isOpened = this.opened;
        }
    }

    toggleCard() {
        this.isOpened = !this.isOpened;
    }
}
