export const environment = {
    production: true,
    branch: 'prod',

    doPayLoadingTimeout: 10000,
    //******************************************************************* PROD ********************************************************/
    paylineBaseUrl: "https://payment.payline.com",//'https://homologation-payment.payline.com',
    BFF: 'https://bff.carmoov.io/v1/',
    URL_BACK: 'https://libre-service.rentacar.fr/',

    //******************************************************************* PREPROD ********************************************************/
    // URL_BACK:"https://preprod.carmoov.io/map",
    // paylineBaseUrl:'https://homologation-payment.payline.com',
    // BFF:"https://bff-preprod.carmoov.io/v1/",
    keycloak: {
        realm: 'rac_cust',
        url: 'https://login.rentacar.fr/auth/',
        clientId: 'presto',
    },
    cognito: {
        // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
        identityPoolId: 'eu-west-1:ea8ef5b4-f557-409d-a5c9-59155f3158cd',
        // REQUIRED - Amazon Cognito Region
        region: 'eu-west-1',
        // OPTIONAL - Amazon Cognito User Pool ID
        userPoolId: 'eu-west-1_w3TQjTKyL',
        agentsPoolId: 'eu-west-1_7KvK8YbtM',
        // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
        userPoolWebClientId: '8l7hf8dhpujimtnd92uaa1fht',
        agentsPoolWebClientId: '2r4pqtra1jgmpbsrah06f0vtas',
        mandatorySignIn: false,
    },
    appSync: {
        region: 'eu-west-1',
    },
    // Google Map
    // Prod
    gmapApiKey: 'AIzaSyD8Q2yG6r6TD2Bgbw-I8DvmIyWKG9BvocY',
    cookieDomain: "rentacar.fr",
    //src="https://maps.googleapis.com/maps/api/js?libraries=places&key="
    //Dev Preprod
    // gmapApiKey: 'AIzaSyBeMbV_XKTc2DWMsL_RSA059Qis4tY10iE',//'AIzaSyC5IP0Pr5YJjoO_lg470cLm5e6D1KC8Wg8',
};
