import { Component, OnChanges, Input, ChangeDetectorRef } from '@angular/core';
import { GMapLoader } from 'src/app/services/gmapLoader';
import { AccessService } from 'src/app/services/access';
import { ItemService } from 'src/app/services/item';

@Component({
    selector: 'access-point',
    templateUrl: './access-point.html',
    styleUrls: ['./access-point.scss']
})
export class AccessPointComponent implements OnChanges {
    @Input('id') ID: string; // Access ID
    @Input('opened') opened: boolean;
    @Input('plate') plate : string 

    public loading: boolean = true;
    public access: any;
    public gmapLink: string;
    public userDistance: string = "";
    public isOpened: boolean = true;

    constructor(
        private gmapLoader: GMapLoader,
        private accessService: AccessService,
        private cdRef: ChangeDetectorRef,
        private itemService: ItemService
    ) {}

    ngOnChanges() {
        if (this.ID) {
            this.accessService.getAccess(this.ID).subscribe((response) => {
                this.access = this.formatResponse(response, "fr");
                // console.log(response);
                // console.log("getAccess: ", this.access);https://www.google.com/maps/dir/48.8393892,2.516198/48.8435556,2.5216242/
                this.gmapLink = "https://www.google.com/maps/place/" + this.access.coordinates.latitude + ',' + this.access.coordinates.longitude //'https://www.google.com/maps?daddr=' + this.access.coordinates.latitude + ',' + this.access.coordinates.longitude;
                this.getDistance();
                this.loading = false;
            });
        } else if (this.plate !== null){

           this.itemService.getItem({id: this.plate}).subscribe(res =>{
               console.log("<<<<<<<<<<<<<<<<<<",res.result.accessId)
               this.accessService.getAccess(res.result.accessId).subscribe((response) => {
                this.access = this.formatResponse(response, "fr");
                // console.log(response);
                // console.log("getAccess: ", this.access);https://www.google.com/maps/dir/48.8393892,2.516198/48.8435556,2.5216242/
                this.gmapLink = "https://www.google.com/maps/place/" + this.access.coordinates.latitude + ',' + this.access.coordinates.longitude //'https://www.google.com/maps?daddr=' + this.access.coordinates.latitude + ',' + this.access.coordinates.longitude;
                this.getDistance();
                this.loading = false;
            });

           })

        }
        if (this.opened !== null) {
            this.isOpened = this.opened;
        }
        
    }

    formatResponse(res, lang){

        if(!res.name) {
            res.name = this.getName(res, lang);
        }
        if(!res.additionalInformations) {
            res.additionalInformations = this.getInformation(res, lang);
        }

        return res;
    }

    getName(res, lang) {
        let returnValue = "";
        res.i18n.forEach(item => {
            if(item.locale == lang){
                item.keys.forEach(obj => {
                    if(obj.name == "name"){
                        return returnValue = obj.value;
                    }
                });
            }
        });
        return returnValue
    }

    getInformation(res, lang) {
        const returnValue = [];
        res.i18n.forEach(item => {
            if(item.locale == lang){
                item.keys.forEach(obj => {
                    returnValue.push(obj)
                });
            }
        });
        return returnValue
    }

    getDistance() {
        if ("geolocation" in navigator && this.access) {
            navigator.geolocation.getCurrentPosition(position => {
                this.gmapLoader.isLoaded.then(() => {
                    let service = new google.maps.DistanceMatrixService();
                    service.getDistanceMatrix(
                        <google.maps.DistanceMatrixRequest>{
                            origins: [
                                new google.maps.LatLng(position.coords.latitude, position.coords.longitude)
                            ],
                            destinations: [
                                new google.maps.LatLng(this.access.coordinates.latitude, this.access.coordinates.longitude)
                            ],
                            unitSystem: google.maps.UnitSystem.METRIC,
                            travelMode: google.maps.TravelMode.WALKING
                        }, (res) => {
                            this.userDistance = res.rows[0].elements[0].distance.text;
                            this.cdRef.detectChanges();
                        }
                    );
                });
            });
        }
    }

    toggleCard() {
        this.isOpened = !this.isOpened;
    }
}
