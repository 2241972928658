<div class="header" >
    <div class="title">{{ 'RESERVATION.CLIENT.CONFIRM.title' | translate }}</div>
    <div class="subtitle">
        <fa-icon class="green" [icon]="['far', 'check-circle']" *ngIf="booking?.status === 'RESERVATION_COMPLETE' || booking?.status === 'RESERVATION_CLOSED'"></fa-icon>
        <fa-icon class="red" [icon]="['far', 'times-circle']" *ngIf="booking?.status === 'RESERVATION_CANCELED'"></fa-icon>
        {{ 'RESERVATION.STATUS.WAITING_FOR_PAYMENT'| translate }}
    </div>
</div>

<!-- Dates -->
<div class="card" *ngIf="booking">
    <div class="dates">
        <fa-icon class="calendar" [icon]="['fas', 'calendar-day']"></fa-icon>
        <div class="date">
            <span>{{ booking?.start | date:'dd MMM yyy' }}</span>
            <span>{{ booking?.start | date:'HH:mm' }}</span>
        </div>
        <fa-icon class="arrow" [icon]="['far', 'long-arrow-right']"></fa-icon>
        <div class="date">
            <span>{{ booking?.end | date:'dd MMM yyy' }}</span>
            <span>{{ booking?.end | date:'HH:mm' }}</span>
        </div>
        <fa-icon class="modify" (click)="showModifyDates = !showModifyDates" [icon]="['fas', 'pen']" *ngIf="canModifyEndDate"></fa-icon>
        <fa-icon class="cant-modify" [icon]="['fas', 'pen']" *ngIf="!canModifyEndDate"></fa-icon>
    </div>
</div>

<!--  -->
<div class="card pricing" *ngIf="booking">
    <pricing [booking-id]="booking.id" [opened]="cards['pricing']"></pricing>
</div>

<!-- Payment -->
<div class="payment">
    <div class="label">
        {{ 'RESERVATION.CLIENT.CONFIRM.payment'  | translate }}
    </div>
    <div class="checkbox">
        <label (click)="cguAccepted = !cguAccepted">
            <span class="checkmark">
                <fa-icon [icon]="['far', 'check']" [ngClass]="{ 'checked': cguAccepted }"></fa-icon>
            </span>
            {{ 'RESERVATION.CLIENT.DETAILS.accept' | translate }}
        </label>
        <a style="margin-left: 5px;" href="assets/help/CONDITIONS_GENERALES_DE_LOCATION_DECEMBRE_2021.pdf" target="_blank">{{ 'RESERVATION.CLIENT.DETAILS.cgv' | translate }}</a>
    </div>
    <div class="confirm">
        <div class="overlay" *ngIf="!cguAccepted" (click)="warningCGU()"></div>
        <transaction #refAnanasSeed type="payment" [booking-id]="bookingID" (CGUClicked)="clickedCGU($event)"></transaction>
    </div>
</div>

<!-- Vehicle infos -->
<div class="card vehicle-infos" *ngIf="category">
    <vehicle [category-id]="category.id" [opened]="cards['vehicle']"></vehicle>
</div>

<!-- Location -->
<div class="card agency-infos" *ngIf="accessID">
    <access-point [id]="accessID" [opened]="cards['location']"></access-point>
</div>


