<div class="header">
    {{ 'RESERVATION.CLIENT.LIST.title' | translate }}
</div>

<div class="loading" *ngIf="loading">
    <fa-icon [icon]="['far', 'circle-notch']" [spin]="true"></fa-icon>
</div>

<!-- Current bookings -->
<div class="list-title" *ngIf="reservations?.current.length && !loading">
    <span class="title">{{ 'RESERVATION.CLIENT.LIST.current_list' | translate }}</span>
    <span class="number">({{ reservations?.current.length }})</span>
</div>
<div class="booking current" *ngFor="let resa of reservations?.current" (click)="seeBooking(resa)">
    <div class="row">
        <div class="column">
            <div class="car-brand" *ngIf="resa.itemId !== 'NOT_DEFINED'">{{ resa.vehicleInfos?.brand }} {{ resa.vehicleInfos?.model }}</div>

            <div class="booking-id" *ngFor="let attribute of resa?.attributes">
                <div *ngIf="attribute.name === 'CLIENT_CONTRACT_ID' || attribute.name === 'CLIENT_RESERVATION_ID'" >
                    {{ attribute.value}}
                </div>  
            </div>
        </div>
        <div class="button">
            <fa-icon [icon]="['far', 'arrow-right']"></fa-icon>
        </div>
    </div>

    <div class="row" [ngClass]="{'warning-orange': resa.warning === 'late' || resa.warning === 'deposit', 'warning-silver': resa.warning === 'expired' }">
        <fa-icon class="warning-icon" [icon]="['far', 'hourglass-half']" *ngIf="resa.warning === 'late'"></fa-icon>
        <div class="warning-text" *ngIf="resa.warning === 'late'" [innerHtml]="'RESERVATION.CLIENT.LIST.reservation_not_closed' | translate"></div>
    </div>

    <div class="row">
        <fa-icon class="calendar" [icon]="['fas', 'calendar-day']"></fa-icon>
        <div class="date">
            <span>{{ resa.start | date:'dd MMM yyyy' }}</span>
            <span>{{ resa.start | date:'HH:mm' }}</span>
        </div>
        <fa-icon class="arrow" [icon]="['far', 'long-arrow-right']"></fa-icon>
        <div class="date" [ngClass]="{'warning': resa.warning === 'late'}">
            <span>{{ resa.end | date:'dd MMM yyyy' }}</span>
            <span>{{ resa.end | date:'HH:mm' }}</span>
        </div>
    </div>
</div>

<!-- Future bookings -->
<div class="list-title" *ngIf="reservations?.future.length && !loading">
    <span class="title">{{ 'RESERVATION.CLIENT.LIST.future_list' | translate }}</span>
    <span class="number">({{ reservations?.future.length }})</span>
</div>
<div class="booking future" *ngFor="let resa of reservations?.future" (click)="seeBooking(resa)">
    <div class="row">
        <div class="column">
            <div class="car-brand" *ngIf="resa.itemId !== 'NOT_DEFINED'">{{ resa.vehicleInfos?.brand }} {{ resa.vehicleInfos?.model }}</div>
            <!-- <div class="booking-id">{{ 'RESERVATION.CLIENT.LIST.reservation_id' | translate:{value:resa.id} }}</div> -->
            <div class="booking-id" *ngFor="let attribute of resa?.attributes">
                <div *ngIf="attribute.name === 'CLIENT_CONTRACT_ID' || attribute.name === 'CLIENT_RESERVATION_ID'" >
                    {{ attribute.value}}
                </div>  
            </div>
        </div>
        <div class="button">
            <fa-icon [icon]="['far', 'arrow-right']"></fa-icon>
        </div>
    </div>

    <div class="row" [ngClass]="{'warning-orange': resa.warning === 'late' || resa.warning === 'deposit', 'warning-silver': resa.warning === 'expired' }">
        <div class="warning-text" *ngIf="resa.warning === 'deposit'">{{ 'RESERVATION.CLIENT.LIST.waiting_for_deposit' | translate }}</div>
    </div>

    <div class="row">
        <fa-icon class="calendar" [icon]="['fas', 'calendar-day']"></fa-icon>
        <div class="date">
            <span>{{ resa.start | date:'dd MMM yyyy' }}</span>
            <span>{{ resa.start | date:'HH:mm' }}</span>
        </div>
        <fa-icon class="arrow" [icon]="['far', 'long-arrow-right']"></fa-icon>
        <div class="date">
            <span>{{ resa.end | date:'dd MMM yyyy' }}</span>
            <span>{{ resa.end | date:'HH:mm' }}</span>
        </div>
    </div>
</div>

<!-- Past bookings -->
<div class="list-title" *ngIf="reservations?.previous.length && !loading">
    <span class="title">{{ 'RESERVATION.CLIENT.LIST.previous_list' | translate }}</span>
    <span class="number">({{ reservations?.previous.length }})</span>
</div>
<div class="booking previous" *ngFor="let resa of reservations?.previous" (click)="seeBooking(resa)">
    <div class="row">
        <div class="column">
            <div class="car-brand" *ngIf="resa.itemId !== 'NOT_DEFINED'">{{ resa.vehicleInfos?.brand }} {{ resa.vehicleInfos?.model }}</div>
            <!-- <div class="booking-id">{{ 'RESERVATION.CLIENT.LIST.reservation_id' | translate:{value:resa.id} }}</div> -->
            <div class="booking-id" *ngFor="let attribute of resa?.attributes">
                <div *ngIf="attribute.name === 'CLIENT_CONTRACT_ID' || attribute.name === 'CLIENT_RESERVATION_ID'" >
                    {{ attribute.value}}
                </div>  
            </div>
        </div>
        <div class="button">
            <fa-icon [icon]="['far', 'arrow-right']"></fa-icon>
        </div>
    </div>

    <div class="row" [ngClass]="{'warning-orange': resa.warning === 'late', 'warning-orange': resa.warning === 'deposit', 'warning-silver': resa.warning === 'expired' }">
        <fa-icon class="warning-icon" [icon]="['far', 'hourglass-half']" *ngIf="resa.warning === 'expired'"></fa-icon>
        <div class="warning-text" *ngIf="resa.warning === 'expired'">{{ 'RESERVATION.CLIENT.LIST.reservation_expired' | translate }}</div>
    </div>

    <div class="row">
        <fa-icon class="calendar" [icon]="['fas', 'calendar-day']"></fa-icon>
        <div class="date">
            <span>{{ resa.start | date:'dd MMM yyyy' }}</span>
            <span>{{ resa.start | date:'HH:mm' }}</span>
        </div>
        <fa-icon class="arrow" [icon]="['far', 'long-arrow-right']"></fa-icon>
        <div class="date">
            <span>{{ resa.end | date:'dd MMM yyyy' }}</span>
            <span>{{ resa.end | date:'HH:mm' }}</span>
        </div>
    </div>
</div>

<!-- Canceled bookings -->
<div class="list-title" *ngIf="reservations?.canceled.length && !loading">
    <span class="title">{{ 'RESERVATION.CLIENT.LIST.canceled_list' | translate }}</span>
    <span class="number">({{ reservations?.canceled.length }})</span>
</div>
<div class="booking canceled" *ngFor="let resa of reservations?.canceled" (click)="seeBooking(resa)">
    <div class="row">
        <div class="column">
            <div class="car-brand" *ngIf="resa.itemId !== 'NOT_DEFINED'">{{ resa.vehicleInfos?.brand }} {{ resa.vehicleInfos?.model }}</div>
            <!-- <div class="booking-id">{{ 'RESERVATION.CLIENT.LIST.reservation_id' | translate:{value:resa.id} }}</div> -->
            <div class="booking-id" *ngFor="let attribute of resa?.attributes">
                <div *ngIf="attribute.name === 'CLIENT_CONTRACT_ID' || attribute.name === 'CLIENT_RESERVATION_ID'" >
                    {{ attribute.value}}
                </div>  
            </div>
        </div>
        <div class="button">
            <fa-icon [icon]="['far', 'arrow-right']"></fa-icon>
        </div>
    </div>

    <div class="row warning-silver">
        <div class="warning-text">{{ 'RESERVATION.CLIENT.LIST.reservation_canceled' | translate }}</div>
    </div>

    <div class="row">
        <fa-icon class="calendar" [icon]="['fas', 'calendar-day']"></fa-icon>
        <div class="date">
            <span>{{ resa.start | date:'dd MMM yyyy' }}</span>
            <span>{{ resa.start | date:'HH:mm' }}</span>
        </div>
        <fa-icon class="arrow" [icon]="['far', 'long-arrow-right']"></fa-icon>
        <div class="date">
            <span>{{ resa.end | date:'dd MMM yyyy' }}</span>
            <span>{{ resa.end | date:'HH:mm' }}</span>
        </div>
    </div>
</div>

<!-- No bookings -->
<h3 *ngIf="!reservations?.current.length && !reservations?.future.length && !reservations?.previous.length && !reservations?.canceled.length && !loading">
    {{ 'RESERVATION.CLIENT.LIST.no_booking' | translate }}
</h3>
