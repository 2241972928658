<div class="title">{{ 'HELP.title' | translate }}</div>
<div class="pdf-link">
    <div class="label">{{ 'HELP.document_validation' | translate }} :</div>
    <div class="link">
        <a target="_blank" href="assets/help/Carmoov+-+Ajout+des+documents.pdf">
            {{ 'GENERIC.download' | translate }}
        </a>
    </div>
</div>
<div class="pdf-link">
    <div class="label">{{ 'HELP.deposit' | translate }} :</div>
    <div class="link">
        <a target="_blank" href="assets/help/Carmoov+-+D%C3%A9p%C3%B4t+de+garantie.pdf">
            {{ 'GENERIC.download' | translate }}
        </a>
    </div>
</div>
<div class="pdf-link">
    <div class="label">{{ 'HELP.rental_process' | translate }} :</div>
    <div class="link">
        <a target="_blank" href="assets/help/Carmoov+-+D%C3%A9roul%C3%A9+de+la+location.pdf">
            {{ 'GENERIC.download' | translate }}
        </a>
    </div>
</div>
<div class="pdf-link">
    <div class="label">{{ 'HELP.FAQ' | translate }} :</div>
    <div class="link">
        <a target="_blank" href="assets/help/Carmoov+-+FAQ+WebApp+Presto.pdf">
            {{ 'GENERIC.download' | translate }}
        </a>
    </div>
</div>
