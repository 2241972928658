<div class="toast" *ngFor="let notification of notifications" @fadeIn @fadeOut>
    <div class="toast__container" [ngClass]="notification.type">
        <div class="toast__canvas">
            <div class="toast__icon">
                <fa-icon [icon]="['far', notification.icon]"></fa-icon>
            </div>
            <div class="toast__content">
                <p class="toast__type">{{ 'NOTIFICATION.' + notification.type | translate }}</p>
                <p class="toast__message">{{ notification.title }}</p>
            </div>
            <div class="toast__close" (click)="removeNotification(notification.id)" *ngIf="notification.closeIcon">
                <fa-icon [icon]="['far', 'times']"></fa-icon>
            </div>
        </div>
    </div>
</div>
