import { Injectable } from "@angular/core";

@Injectable({
    providedIn: 'root'
})
export class EDLStorage {

    private storage = window.localStorage;

    constructor() {}

    public getCurrentNewDamages(): any {
        let currentNewDamages = this.storage.getItem('current_new_damages');
        if (currentNewDamages) {
            return JSON.parse(currentNewDamages);
        }
        return [];
    }

    public setCurrentNewDamages(newDamages): void {
        this.storage.setItem('current_new_damages', JSON.stringify(newDamages));
    }

    public getReturnPics(): any {
        let returnPics = this.storage.getItem('return_pics');
        if (returnPics) {
            return JSON.parse(returnPics);
        }
        return [];
    }

    public setReturnPics(returnPics): void {
        this.storage.setItem('return_pics', JSON.stringify(returnPics));
    }

    public getUserStorage(): any {
        return Object.keys(this.storage).reduce((obj, str) => {
            obj[str] = this.storage.getItem(str);
            return obj;
        }, {});
    }

    public clearStorage() {
        this.storage.removeItem('current_new_damages');
        this.storage.removeItem('return_pics');
    }
}
