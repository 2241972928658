import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

let frFR = require('src/assets/i18n/fr-FR.json');
let enUS = require('src/assets/i18n/en-US.json');

@Injectable({
    providedIn: "root"
})
export class LanguageService {
    private currentLanguage;
    private languageSubscriber: Subject<any>;

    private languages = [{
        type: "FR",
        label: "Français",
        flag: "iti__fr",
        i18n: "fr-FR"
    }, {
        type: "EN",
        label: "English",
        flag: "iti__gb",
        i18n: "en-US"
    }];

    constructor(private translate: TranslateService) {
        this.translate.setTranslation('fr-FR', frFR);
        this.translate.setTranslation('en-US', enUS);
        this.translate.setDefaultLang('fr-FR');
        this.languageSubscriber = new Subject<any>();
        this.setLang(this.getDefaultType().type);
    }

    getLang() {
        if (this.currentLanguage) {
            return this.currentLanguage;
        } else {
            return this.getDefaultType();
        }
    }

    getDefaultType() {
        let langJSON = localStorage.getItem('lang');
        if (langJSON) {
            let storedLang = JSON.parse(langJSON);
            return storedLang;
        } else {
            return this.languages[0]; // FR
        }
    }

    getObservable() {
        return this.languageSubscriber.asObservable();
    }

    getLanguageList() {
        return this.languages;
    }

    setLang(locale: string) {
        for (let i = 0; i < this.languages.length; i++) {
            if (locale === this.languages[i].type) {
                this.currentLanguage = this.languages[i];
            }
        }

        localStorage.setItem("lang", JSON.stringify(this.currentLanguage));
        this.translate.use(this.currentLanguage.i18n);
        this.languageSubscriber.next(this.currentLanguage);
    }
}
