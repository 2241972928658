import { Component, ViewChild, ElementRef, OnInit, AfterViewInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';

import moment from 'moment-timezone';

import { GMapLoader } from 'src/app/services/gmapLoader';
import { AccessService } from 'src/app/services/access';
import { BookingService } from 'src/app/services/booking';
import { ReservationStorage } from 'src/app/services/reservation.storage';
import { HeaderMenuService } from 'src/app/services/header-menu';
import { LanguageService } from 'src/app/services/language';

import { NotificationManager } from 'src/app/notification/notification';

import { MomentLocales } from 'src/app/interfaces/moment';
import { AccessPoint } from 'src/app/interfaces/models/access';
import { Categories } from 'src/app/interfaces/categories';

@Component({
    selector: 'access-point-map',
    templateUrl: './access-point-map.html',
    styleUrls: ['./access-point-map.scss'],
})
export class AccessPointMapComponent implements OnInit, AfterViewInit {
    @ViewChild('searchInput') searchElementRef: ElementRef;

    public accessPoints: [AccessPoint];

    // Map / search attributes
    public categories = Categories;
    public loading = false;

    public search = {
        location: '',
        onlyPresto: false,
        categories: [],
        agencySelected: null,
        distance: '',
        start_date: '',
        end_date: '',
        origin: null,
    };

    public map = {
        zoom: 12,
        center: null,
        options: {
            mapTypeId: 'roadmap',
            zoomControl: true,
            scrollwheel: true,
            disableDoubleClickZoom: true,
            maxZoom: 15,
            minZoom: 8,
        }
    };

    public pinpoints: Array<any> = [];
    public minStartDate: moment.Moment = this.roundTimeQuarterHour(moment()).toDate();
    public minEndDate: moment.Moment = this.roundTimeQuarterHour(moment().add(1, 'days')).toDate();
    private autocomplete: google.maps.places.Autocomplete;

    // Form
    public startSearchForm = new FormGroup({
        position: new FormControl('', { validators: [Validators.required], updateOn: 'blur' }),
        start_date: new FormControl(this.roundTimeQuarterHour(moment()).toDate(), {
            validators: [Validators.required],
        }),
        end_date: new FormControl(this.roundTimeQuarterHour(moment().add(1, 'days')).toDate(), {
            validators: [Validators.required],
        }),
        categories: new FormControl(''),
    });
    get startSearchFormValues() {
        return {
            position: this.startSearchForm.get('position'),
            start_date: this.startSearchForm.get('start_date'),
            end_date: this.startSearchForm.get('end_date'),
            categories: this.startSearchForm.get('categories'),
        };
    }

    // View Modifiers
    public en; public fr;
    public noVehicle: string = 'loading';
    public minPrice: number = 0;
    public state = 'start-search';
    public touchUI = true;
    public momentLocale = MomentLocales['fr'];

    constructor(
        private router: Router,
        private _activatedRoute: ActivatedRoute,
        private location: Location,
        private accessService: AccessService,
        private bookingService: BookingService,
        private gmapLoader: GMapLoader,
        private translateService: TranslateService,
        private headerMenuService: HeaderMenuService,
        private languageService: LanguageService,
        private reservationStorage: ReservationStorage,
        private notificationManager: NotificationManager
    ) {
        this.languageService.getObservable().subscribe((lang) => {
            moment.locale(lang.i18n);
            this.momentLocale = MomentLocales[lang.type.toLowerCase()];
        });
        moment.locale('fr-FR');
    }

    ngOnInit() {
        // Cleaning up
        localStorage.removeItem('category');
        localStorage.removeItem('payment');
        localStorage.removeItem('idBooking');
        localStorage.removeItem('agency');
        localStorage.removeItem('startDate');
        localStorage.removeItem('endDate');
        localStorage.removeItem('options');

        this.startSearchFormValues.start_date.valueChanges.subscribe(() => {
            this.checkTimes(true);
        });

        this.startSearchFormValues.end_date.valueChanges.subscribe(() => {
            this.checkTimes();
        });

        this.headerMenuService.setShowMenu(true);
        this.headerMenuService.setHeader({
            color: 'default',
            showLogo: true,
            showBack: false,
            showIcon: true
        });

        if (window.innerWidth > 1023) {
            // Desktop
            this.touchUI = false;
        }
    }

    ngAfterViewInit() {
        // Map configuration
        this.gmapLoader.isLoaded.then(() => {
            this.initAccessPoints();
            // POC: setParisBercy instead of setAutocomplete
            this.setAutocomplete();
            // this.setParisBercy();

            // Check if search has been made already
            this._activatedRoute.queryParams.subscribe((params) => {
                if (params['location'] && params['start'] && params['end']) {
                    this.startSearchFormValues.start_date.setValue(moment(params['start']).toDate());
                    this.startSearchFormValues.end_date.setValue(moment(params['end']).toDate());

                    if (params['location'] !== "around-me" && params['lat'] && params['lon']) {
                        this.startSearchFormValues.position.setValue(params['location']);
                        this.search.origin = { lat: params['lat'], lon: params['lon'] };
                    }

                    this.setSearchMap(params['location']);
                } else {
                    this.setLocation();
                }
            });
        });
    }

    // POC: Pour le moment on prérempli avec rien et on renvoie vers cube.
    setParisBercy() {
        this.searchElementRef.nativeElement.value = " ";
        this.searchElementRef.nativeElement.disabled = true;
        this.startSearchFormValues.position.setValue(" ");
    }

    // POC: formattage des dates et redirection vers cube v2
    goToCube() {
        // let startDate = encodeURIComponent(moment(this.startSearchFormValues.start_date.value).format("YYYY-MM-DDThh:mm:ss"));
        // let endDate = encodeURIComponent(moment(this.startSearchFormValues.end_date.value).format("YYYY-MM-DDThh:mm:ss"));
        // let url = `https://www.rentacar.fr/v2/reservation/categories?RideType=RoundTrip&Pickup.Latitude=48.837732&Pickup.Longitude=2.382306&Pickup.MaxNumberOfAgencies=4&Radius=50&VehicleType=Truck&PickupDate=${startDate}&DropoffDate=${endDate}&pickupDescription=PARIS+12+-+BERCY&isAgency=true`;
        let url = `https://www.rentacar.fr/`;
        window.open(url);
    }

    setAutocomplete() {
        this.autocomplete = new google.maps.places.Autocomplete(this.searchElementRef.nativeElement, {
            componentRestrictions: { country: 'fr' }
        });

        // Change location listener
        this.autocomplete.addListener('place_changed', () => {
            let place: google.maps.places.PlaceResult = this.autocomplete.getPlace();

            if (!place || place.geometry === undefined || place.geometry === null) {
                return;
            }

            this.search.origin = {
                lat: place.geometry.location.lat(),
                lon: place.geometry.location.lng(),
            };

            this.startSearchFormValues.position.setValue(place.formatted_address);

            // Reset agencySelected
            this.search.agencySelected = null;
        });

        // Trigger the change location
        this.searchLocation = () => {
            google.maps.event.trigger(this.autocomplete, 'place_changed');
        };
    }

    async setSearchMap(type: string) {
        this.search.start_date = this.startSearchFormValues.start_date.value;
        this.search.end_date = this.startSearchFormValues.end_date.value;
        this.search.categories = this.startSearchFormValues.categories.value;

        if (type === "around-me") {
            this.startSearchFormValues.position.setValue('');
            this.useMyLocation();
            this.setLocation("around-me");
        } else {
            this.search.location = this.startSearchFormValues.position.value;
            this.setLocation(this.search.location);
            // Define map attributes
            this.map.center = new google.maps.LatLng(this.search.origin.lat, this.search.origin.lon);
            this.map.zoom = 13;
        }

        // Show the map
        this.state = 'search-map';

        // Look for the closest access point and auto click-it
        let currentDist = {
            id: -1,
            value: null
        };

        // console.log(this.pinpoints);

        for (let i = 0; i < this.pinpoints.length; i++) {
            if (this.pinpoints[i].status === 'CLOSE') continue;
            let distance = await this.getDistance(this.pinpoints[i].position.latitude, this.pinpoints[i].position.longitude);
            console.log(distance)
            if (!currentDist.value || currentDist.value > distance.value) {
                let categories = await this.accessService.getBookingCategoryAvailable({
                    accessID: this.pinpoints[i].id,
                    start: this.startSearchFormValues.start_date.value.valueOf(),
                    end: this.startSearchFormValues.end_date.value.valueOf()
                }).toPromise();

                if (categories['result'] && categories['result'].length) {
                    currentDist.value = distance.value;
                    currentDist.id = i;
                }
            }
        }

        if (currentDist.value) {
            this.markerClick(this.pinpoints[currentDist.id]);
        }
    }

    editSearch() {
        this.state = 'start-search';
        setTimeout(() => {
            this.startSearchFormValues.position.setValue('');
            this.search.agencySelected = null;
            this.setAutocomplete();
        }, 500);
    }

    // Initialize function before assigning it
    searchLocation() {}

    // Auto find user location if permitted
    useMyLocation() {
        if ('geolocation' in navigator) {
            navigator.geolocation.getCurrentPosition((position) => {
                this.search.origin = {
                    lat: position.coords.latitude,
                    lon: position.coords.longitude,
                };
                // Define map attributes
                this.map.center = new google.maps.LatLng(this.search.origin.lat, this.search.origin.lon);
                this.map.zoom = 13;
                this.search.location = '';
            });
        }
    }

    setLocation(type?: string) {
        let url;
        if (!type) {
            url = this.router.createUrlTree([], { relativeTo: this._activatedRoute }).toString();
        } else if (type === "around-me") {
            url = url = this.router.createUrlTree([], { relativeTo: this._activatedRoute, queryParams: {
                location: "around-me",
                start: moment(this.search.start_date).toDate(),
                end: moment(this.search.end_date).toDate()
            }}).toString();
        } else {
            url = this.router.createUrlTree([], { relativeTo: this._activatedRoute, queryParams: {
                location: this.startSearchFormValues.position.value,
                lat: this.search.origin.lat,
                lon: this.search.origin.lon,
                start: moment(this.search.start_date).toDate(),
                end: moment(this.search.end_date).toDate()
            }}).toString();
        }

        this.location.go(url);
    }

    onCheckChanges() {
        this.search.onlyPresto = !this.search.onlyPresto;
    }

    // Init access points
    initAccessPoints() {
        this.accessService.getAccessList().subscribe((res: any) => {
            if (res) {
                this.accessPoints = res.result
                if(this.accessPoints){
                    for (let i = 0; i < this.accessPoints.length; i++) {
                        this.addPinPoint({
                            id: this.accessPoints[i].id,
                            name: this.parseName("fr", this.accessPoints[i]),
                            type: this.accessPoints[i].type,
                            position: this.accessPoints[i].coordinates,
                            hours: this.recoverBusinessTime(this.accessPoints[i]),
                            status: this.accessPoints[i].status,
                            contact: this.recoverContact(this.accessPoints[i]),
                            address: this.accessPoints[i].address,
                        });
                    }
                }
            } else {
                this.notificationManager.addNotification(this.translateService.instant("INTERFACES.ERROR.API.unknown"), "warning", 6000, true);
            }
        });
    }

    parseName(locale, access) {
        if (access.name) {
            return access.name;
        } else {
            let returnValue = "";
            access.i18n.forEach(item => {
                if(item.locale == locale){
                    item.keys.forEach(obj => {
                        if(obj.name == "name"){
                            return returnValue = obj.value;
                        }
                    });
                }
            });
            return returnValue
        }
    }

    recoverBusinessTime(access) {
        if (access.businessTimeDeprecated) {
            return access.businessTimeDeprecated.open;
        } else {
            if (access.businessTime) {
                return access.businessTime;
            }
        }
    }

    recoverContact(access) {
        if (access.contacts) {
            return access.contacts;
        } else {
            if (access.contact) {
                return access.contact;
            }
        }
    }

    parseOpeningHours(cronSchedule: string) {
        let parsedSchedule = [];
        if (typeof cronSchedule == "string") {
            let cronDays = cronSchedule.split(' ');
            for (let i = 0; i < cronDays.length; i++) {
                if (cronDays[i] === '*') {
                    parsedSchedule.push({
                        id: i + 1,
                        allDay: true
                    });
                } else if (cronDays[i] === 'x') {
                    parsedSchedule.push({
                        id: i + 1,
                        allDay: false,
                        hours: null
                    });
                } else {
                    let cronHours = cronDays[i].split(',');
                    parsedSchedule.push({
                        id: i + 1,
                        allDay: false,
                        hours: []
                    });
                    for (let j = 0; j < cronHours?.length; j++) {
                        parsedSchedule[i].hours.push({
                            from: cronHours[j].split('-')[0],
                            to: cronHours[j].split('-')[1]
                        });
                    }
                }
            }
        } else {
            parsedSchedule = this.parseBusinessTime(cronSchedule);
        }

        return parsedSchedule;
    }

    parseBusinessTime (schedule) {
        const parsedSchedule = [];
        schedule?.forEach(item => {
            const day: any = {};
            day.id = item.day;
            if(item.open == 0 && item.close == 86400000) {
                day.allDay = true;
            } else {
                day.allDay = false;
            }
            parsedSchedule.push(day);
        });
        return parsedSchedule;
    }

    // Add pinpoint with all informations
    addPinPoint(data: any) {
        let pinpoint = {
            id: data.id,
            name: data.name,
            schedule: this.parseOpeningHours(data.hours?.open),
            position: data.position,
            center: new google.maps.LatLng(data.position.latitude, data.position.longitude),
            contact: data.contact,
            address: data.address,
            status: data.status,
            type: data.type,
            size: new google.maps.Size(20, 32),
            options: {
                animation: google.maps.Animation.DROP,
                icon: {
                    url: '../assets/img/grand@3x.png',
                    scaledSize: new google.maps.Size(32, 32)
                }
                
            },
            icon: '../assets/img/grand@3x.png',
            price: '0.00',
        };

        this.pinpoints.push(pinpoint);
    }

    // Filter agencies for customers
    filterAgencies() {
        if (this.search.onlyPresto) {
            // Filter onlyPresto
        }

        if (this.search.categories.length) {
            // Filter categories
        }

        this.search.agencySelected = null;
    }

    async markerClick(agency: any) {

        // console.log("Marker clicked: ", agency);

        // Get distance from user
        this.search.agencySelected = agency;
        let distance = await this.getDistance(this.search.agencySelected.position.latitude, this.search.agencySelected.position.longitude);
        this.search.distance = distance.text;

        this.noVehicle = 'loading';

        this.accessService.getBookingCategoryAvailable({
            accessID: this.search.agencySelected.id,
            start: this.startSearchFormValues.start_date.value.valueOf(),
            end: this.startSearchFormValues.end_date.value.valueOf()
        }).subscribe(async (res: any) => {
            this.search.agencySelected['categories'] = [];
            let pricesRequests = [];
            if (res.result && res.result.length > 0) {
                for (let i = 0; i < res.result.length; i++) {
                    let price = await this.bookingService.getPrice({
                        accessId: this.search.agencySelected.id,
                        itemCategory: res.result[i].category,
                        start: this.startSearchFormValues.start_date.value.valueOf(),
                        end : this.startSearchFormValues.end_date.value.valueOf(),
                        kilometers: 100
                    }).toPromise();

                    pricesRequests.push(price);
                }
                for (let x = 0; x < pricesRequests.length; x++) {
                    this.search.agencySelected['categories'].push({ id: res.result[x].category });
                    this.search.agencySelected['categories'][x]['vehiclePrice'] = pricesRequests[x].paymentPrice / 100;
                    this.minPrice = this.minPrice > 0 && this.minPrice < pricesRequests[x].paymentPrice / 100 ? this.minPrice : pricesRequests[x].paymentPrice / 100;
                    this.search.agencySelected['categories'][x]['depositPrice'] = pricesRequests[x].depositPrice / 100;
                }
                this.noVehicle = 'false';
            } else {
                this.noVehicle = 'true';
            }
        });
    }

    // Find the next closest quarter hour
    roundTimeQuarterHour(time) {
        let timeToReturn = time;
        timeToReturn.seconds(Math.ceil(timeToReturn.seconds() / 60) * 60);
        timeToReturn.minutes(Math.ceil(timeToReturn.minutes() / 30) * 30);
        return timeToReturn;
    }

    detectChangesAsync(changeStart: boolean = false) {
        setTimeout(() => {
            this.checkTimes(changeStart);
        }, 50);
    }

    checkTimes(changeStart: boolean = false) {
        // Make sure startDate doesn't go below today
        if (moment(this.startSearchFormValues.start_date.value).diff(this.minStartDate, 'minutes') < 0) {
            this.startSearchFormValues.start_date.setValue(this.roundTimeQuarterHour(moment()).toDate());
        }
        // Make sure that endDate is minimum 1 day after startDate
        if (moment(this.startSearchFormValues.start_date.value).diff(moment(this.startSearchFormValues.end_date.value), 'days') > -1) {
            this.startSearchFormValues.end_date.setValue(moment(this.startSearchFormValues.start_date.value).add(1, 'days').toDate());
        }
        // Reset minEndDate according to startDate
        if (changeStart) {
            this.minEndDate = moment(this.startSearchFormValues.start_date.value).add(1, 'days').toDate();
        }
    }

    async getDistance(lon, lat) {
        return new Promise<any>((resolve) => {
            let service = new google.maps.DistanceMatrixService();
            service.getDistanceMatrix(
                <google.maps.DistanceMatrixRequest>{
                    origins: [new google.maps.LatLng(this.search.origin.lat, this.search.origin.lon)],
                    destinations: [new google.maps.LatLng(lon, lat)],
                    unitSystem: google.maps.UnitSystem.METRIC,
                    travelMode: google.maps.TravelMode.WALKING,
                }, (res) => {
                    resolve(res.rows[0].elements[0].distance);
                }
            );
        });
    }

    getCategoryName(id) {
        return this.categories[id].title;
    }

    confirmAgency() {
        if (this.noVehicle === 'true') return;

        // If agency is mixed or presto
        this.reservationStorage.setAgency(this.search.agencySelected);

        // Format dates
        let start = this.startSearchFormValues.start_date.value;
        let end = this.startSearchFormValues.end_date.value;
        this.reservationStorage.setDates(start, end);
        this.router.navigate(['vehicle']);
    }
}
