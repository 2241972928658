import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuardKeycloak } from './services/authGuardKeycloak'

// Customers
import { SelectVehicleComponent } from './components/vehicle/select-vehicle/select-vehicle';
import { DeclareCheckComponent } from './components/check/declare-check/declare-check';
import { CheckPDFComponent } from './components/pdf/check.pdf';
import { AccessPointMapComponent } from './components/access/access-point-map/access-point-map';
import { KeyComponent } from './components/key/key';
import { ClientListComponent } from './components/reservation/list/list';
import { ConfirmPaymentComponent } from './components/reservation/confirm-payment/confirm-payment';
import { ConfirmDepositComponent } from './components/reservation/confirm-deposit/confirm-deposit';
import { PayDepositComponent } from './components/paydeposit/paydeposit/paydeposit';
import { DetailReservationComponent } from './components/reservation/details/details';
import { UserComponent } from './components/user/user';
import { LinkPaymentComponent } from './components/link/payment/payment';
import { LinkPaymentConfirmationComponent } from './components/link/confirmation/confirmation';

// Others
import { HelpComponent } from './components/help/help';

const routes: Routes = [
    // Customers
    { path: 'help', component: HelpComponent },
    { path: 'map', component: AccessPointMapComponent },
    { path: 'vehicle', component: SelectVehicleComponent },
    { path: 'payment', component: ConfirmPaymentComponent, canActivate: [AuthGuardKeycloak] },
    { path: 'deposit', component: ConfirmDepositComponent, canActivate: [AuthGuardKeycloak] },
    { path: 'token/deposit', component: PayDepositComponent },
    { path: 'detail', component: DetailReservationComponent, canActivate: [AuthGuardKeycloak] },
    { path: 'edl', component: DeclareCheckComponent, canActivate: [AuthGuardKeycloak] },
    { path: 'list-bookings', component: ClientListComponent, canActivate: [AuthGuardKeycloak] },
    { path: 'key', component: KeyComponent, canActivate: [AuthGuardKeycloak] },
    { path: 'profile', component: UserComponent, canActivate: [AuthGuardKeycloak] },
    { path: 'document', component: CheckPDFComponent, canActivate: [AuthGuardKeycloak] },
    { path: 'additional-payment', component: LinkPaymentComponent, canActivate: [AuthGuardKeycloak] },
    { path: 'additional-payment/confirm', component: LinkPaymentConfirmationComponent, canActivate: [AuthGuardKeycloak] },
    
    // Default
    { path: '', redirectTo: 'map', pathMatch: 'full' },
    { path: '**', redirectTo: 'map' }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {
        scrollPositionRestoration: 'enabled'
    })],
    exports: [RouterModule],
})
export class AppRoutingModule {}
