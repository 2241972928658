import {
    Input,
    Component,
    ViewChild,
    ElementRef,
    OnInit,
    Output,
    EventEmitter
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router, ActivatedRoute } from '@angular/router';


import { NotificationManager } from 'src/app/notification/notification';
import { PayDepositService } from 'src/app/services/paydeposit';
import { LanguageService } from 'src/app/services/language';

import { environment } from 'src/environments/environment';

interface Window {
    onStatusChange: Function;
}

@Component({
    selector: 'paydeposit-transaction',
    templateUrl: './paydeposit-transaction.html',
    styleUrls: ['./paydeposit-transaction.scss']
})
export class PayDepositTransactionComponent implements OnInit {
    @Input() type: String;
    @Input('booking-id') bookingID: string;
    @Input('reservation-id') reservationID: string;
    @Input('email-address') emailAddress: string;
    @ViewChild('PaylineWidget', { static: false }) paylineWidget: ElementRef;
    @Output() CGUClicked = new EventEmitter<any>();

    public token: string
    public tokenParam: string
    public window: Window;
    public loading: boolean;

    constructor(
        private translateService: TranslateService,
        private notificationManager: NotificationManager,
        private payDepositService: PayDepositService,
        private languageService: LanguageService,
        private router: Router,
        private activatedRoute: ActivatedRoute
    ) {
        this.activatedRoute.queryParams.subscribe(params => {

            if (params.paymentEndpoint){
                this.tokenParam = params.token;
                localStorage.setItem('3DS', "true");
            }
        });
    }

    async ngOnInit() {
        let self = this;
        let check = true;
        this.loading = false;

        window['onStatus'] = (params) => {
            // console.log("window['onStatus']", params);
        };

        window['onStatusChange'] = (params) => {
            // console.log("onStatusChange - params: ", params);
            if (params.state === "PAYMENT_SUCCESS" && self.type === "deposit" && check) {
                this.CGUClicked.emit("true")
                this.loading = true;
                //console.log('deposit payment success', self.token ,self.reservationID );
                self.payDepositService.verifyTokenDeposit({ token: self.token, reservationId: self.reservationID }).toPromise().then((res) => {
                    this.loading = false;
                    // console.log("tokenDepositeService.verifyTokenDeposit: ", res);
                    if (res.result === "success") {
                        localStorage.setItem("reservationId", self.reservationID);
                        localStorage.setItem("payment", "deposit");
                        localStorage.setItem("deposit", "true");
                    } else {
                        if (localStorage.getItem("depositError")) {
                            localStorage.removeItem("depositError");
                            this.notificationManager.addNotification(this.translateService.instant("INTERFACES.ERROR.API.too_many_failed_payments"), "error", 5000, true);
                            //this.router.navigate(['map']);
                            setTimeout(() => {this.router.navigate(['token/deposit', { queryParams: { id: this.reservationID, email: this.emailAddress }}]);}, 5000)
                        } else {
                            localStorage.setItem("depositError", "true");
                            this.notificationManager.addNotification(this.translateService.instant("INTERFACES.ERROR.API.unknown"), "error", 5000, true);
                            //window.location.reload();
                            setTimeout(() => {this.router.navigate(['token/deposit', { queryParams: { id: this.reservationID, email: this.emailAddress }}]);}, 5000)
                        }
                    }
                }).catch((e) => {
                    this.loading = false;
                    localStorage.setItem("reservationId", self.reservationID);
                    localStorage.setItem("payment", "deposit");
                    localStorage.setItem("deposit", "true");
                    localStorage.setItem("email_deposit", self.emailAddress);
                    console.log("payDepositService.verifyTokenDeposit - catch: ", e);
                });
            } else if (params.state === "TOKEN_EXPIRED") {
                this.CGUClicked.emit("true")
                localStorage.setItem("token_expired", "true");
                localStorage.setItem("payment", "false");
                localStorage.setItem("deposit", "false");
                setTimeout(() => {this.router.navigate(['token/deposit', { queryParams: { id: this.reservationID, email: this.emailAddress }}]);}, 5000)
            }else if (params.state === "PAYMENT_FAILURE") {
                this.CGUClicked.emit("true")
                localStorage.setItem("PAYMENT_FAILURE", "true");
                localStorage.setItem("payment", "false");
                localStorage.setItem("deposit", "false");
                setTimeout(() => {this.router.navigate(['token/deposit', { queryParams: { id: this.reservationID, email: this.emailAddress }}]);}, 5000)
            }
        };

        this.initPayment();
    }

    setWidget() {
        let script = document.createElement('script');
        script.type = 'text/javascript';
        script.async = true;
        script.text = `function statusChange(status) { window['onStatusChange'](status) }; function status(status) { window['onStatus'](status) };`;
        this.paylineWidget.nativeElement.appendChild(script);

        let library = document.createElement('script');
        library.src = environment.paylineBaseUrl + '/scripts/widget-min.js';
        library.type = 'text/javascript';
        library.async = false;
        this.paylineWidget.nativeElement.appendChild(library);

        let style = document.createElement('link');
        style.setAttribute('href', environment.paylineBaseUrl + '/styles/widget-min.css');
        style.setAttribute('rel', 'stylesheet');
        this.paylineWidget.nativeElement.appendChild(style);
    }

    unsetWidget() {
        let elements = this.paylineWidget.nativeElement.children;
        for (let i = elements.length - 1; i >= 0; i--) {
            this.paylineWidget.nativeElement.removeChild(elements[i]);
        }
    }

    async initPayment() {
        if (this.type === 'deposit') {
            let input = {
                reservationId: this.reservationID,
                appInformation: {
                    onSuccess: window.location.origin + `/token/deposit?id=${this.reservationID}`,
                    onCancel: window.location.origin + `/token/deposit?id=${this.reservationID}`,
                    language: this.languageService.getLang().type.toLowerCase(), // enum [en, fr]
                    colorDepth: 48,
                    screen: {
                        width: 500,
                        height: 500
                    }
                }
            };
            this.payDepositService.getTokenDeposit(input, this.emailAddress).subscribe((res) => {
                if (this.tokenParam == undefined){
                    this.token = res.token;
                    this.setWidget();
                }else {
                    this.token = this.tokenParam;
                    this.setWidget()

                }
            });
        }
    }
}
