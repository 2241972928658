import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class HeaderMenuService {

    private headerMenuData: any = {
        showMenu: false,
        menuOptions: {
            mode: 'side',
            disableClose: false,
            opened: false
        },
        header: {
            color: 'default',
            showLogo: false,
            showBack: false,
            showIcon: false
        }
    };

    public headerMenuObserver: Subject<any>;

    constructor() {
        this.headerMenuObserver = new Subject<any>();
    }

    getHeaderMenuObserver() {
        return this.headerMenuObserver.asObservable();
    }

    getHeaderMenuData() {
        return this.headerMenuData;
    }

    setShowMenu(showMenu: boolean) {
        this.headerMenuData.showMenu = showMenu;
        this.headerMenuObserver.next(this.headerMenuData);
    }

    setMenuOptions(isLoggedIn: boolean) {
        if (window.innerWidth > 1023) {
            // Desktop
            this.headerMenuData.menuOptions = {
                mode: 'side',
                disableClose: true,
                opened: isLoggedIn
            };
        } else {
            // Mobile
            this.headerMenuData.menuOptions = {
                mode: 'over',
                disableClose: false,
                opened: false
            };
        }
        this.headerMenuObserver.next(this.headerMenuData);
    }

    setHeader(header: any) {
        this.headerMenuData.header = header;
        this.headerMenuObserver.next(this.headerMenuData);
    }
}
