import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from "src/environments/environment";

@Injectable()
export class UserService {

    constructor(
        private http: HttpClient
    ) {}

    getUser(): Observable<any> {
        return this.http.get<any>(environment.BFF + "user");
    }

    getUserByEmail(email: string): Observable<any> {
        return this.http.get<any>(environment.BFF + "user/" + email);
    }

    updateUser(input: any): Observable<any> {
        return this.http.put<any>(environment.BFF + "user/" + input.email, input);
    }

    createUser(input:any): Observable<any> {
        return this.http.post<any>(environment.BFF + "user", input);
    }
}
