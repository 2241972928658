import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from "src/environments/environment";

@Injectable()
export class UbbleService {

    constructor(private http: HttpClient) {}

    getLink(returnUrl: string): Observable<any> {
        return this.http.post<any>(environment.BFF + "ubble", {
            url: returnUrl
        });
    }
}
