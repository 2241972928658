import { Injectable } from '@angular/core';
import { environment } from "src/environments/environment";
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class PDFService {

    constructor(private http: HttpClient) {}

    public generatPDF(input): Observable<any> {
        return this.http.post<any>(environment.BFF + "pdf",input);
    }

}