import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { AccessService } from 'src/app/services/access';
import { CheckService } from 'src/app/services/check';
import { BookingService } from 'src/app/services/booking';
import { ItemService } from 'src/app/services/item';
import { OptionService } from 'src/app/services/option';
import { ReservationService } from 'src/app/services/reservation';
import { UserService } from 'src/app/services/user';
import { HeaderMenuService } from 'src/app/services/header-menu';

import * as moment from 'moment-timezone';
import { environment } from 'src/environments/environment';

import { Categories } from 'src/app/interfaces/categories';
import { Dommage } from 'src/app/interfaces/dommage';
import { VehicleParts, DamageTypes } from 'src/app/interfaces/check.infos';

import domtoimage from "dom-to-image";
import jsPDF from 'jspdf';
import { PDFService } from 'src/app/services/pdf';

@Component({
    selector: 'check-pdf',
    templateUrl: './check.pdf.html',
    styleUrls: ['./check.pdf.scss']
})
export class CheckPDFComponent implements OnInit {
    @Input('check-id') checkID: string;
    @Input('reservation-id') reservationID: string;
    @Input() type: string;
    @Input() generate: boolean;

    @Output() event: EventEmitter<string> = new EventEmitter<string>();

    public loading: boolean = true;
    public nodes = document.getElementsByClassName("page");
    public data: any;
    public previousDamages = [];
    public newDamages = [];
    public pageCount = [];
    public imageBucketUrl = `https://s3.eu-west-1.amazonaws.com/io.carmoov.damages.${environment.branch}/public/`;

    // Interfaces
    public categories = Categories;
    public vehicleParts = VehicleParts;
    public damageTypes = DamageTypes;
    public imagePieces = [];
    private dommage = Dommage

    constructor(
        private _activatedRoute: ActivatedRoute,
        private accessService: AccessService,
        private bookingService: BookingService,
        private checkService: CheckService,
        private itemService: ItemService,
        private optionService: OptionService,
        private reservationService: ReservationService,
        private userService: UserService,
        private headerMenuService: HeaderMenuService,
        private pdfService: PDFService

    ) {}

    ngOnInit() {
        if (this.generate) this.headerMenuService.setShowMenu(false);

        this._activatedRoute.queryParams.subscribe((params) => {
            if (!this.checkID) this.checkID = params['id'];
            if (!this.reservationID) this.reservationID = params['booking'];
        });

        this.initCheck();
    }

    async initCheck() {
        // Get all the datas pertaining to the check PDF
        let promisesData = await Promise.all([
            this.checkService.getCheck({ id: this.checkID }).toPromise(),
            this.reservationService.getReservation({ id: this.reservationID }).toPromise(),
            this.optionService.getOptionTypes().toPromise(),
            this.optionService.getOptionList({ id: this.reservationID }).toPromise()
        ]);

        let checkData = promisesData[0];
        // console.log("checkData: ", checkData);
        let reservationData = promisesData[1].result;
        console.log("reservationData: ", reservationData);
        let optionTypeList = promisesData[2].result;
        // console.log("optionTypeList: ", optionTypeList);
        let optionData = promisesData[3];
        // console.log("optionData: ", optionData);

        let bookingData;
        await this.bookingService.getBooking({ id: reservationData.bookingId }).toPromise().then((response) => {
            bookingData = response.result;
            console.log("bookingData: ", bookingData);
        });

        // Get Other datas
        promisesData = await Promise.all([
            this.itemService.getItem({ id: reservationData.itemId }).toPromise(),
            this.accessService.getAccess(bookingData.accessId).toPromise(),
            this.userService.getUser().toPromise(),
            this.checkService.getCheckList({ id: reservationData.itemId }).toPromise()
        ]);

        let itemData = promisesData[0].result;
        // console.log("itemData: ", itemData);
        let accessPointData = promisesData[1];
       
        let accessPoint = this.getAccessPoint("fr",accessPointData.i18n)
        let userProfile = promisesData[2].result;
        // console.log("userProfile: ", userProfile);
        let checkList = promisesData[3].result;
        // console.log("checkList: ", checkList);

        // let zoneDifference = 3600000;
        // if (moment.tz("Europe/Paris").format("Z") === "+02:00") zoneDifference *= 2;

        // Set start and end dates
        let start = moment(reservationData.start); // + zoneDifference
        let end = moment(reservationData.end); // + zoneDifference

        // Duration calculated with an hour of floatment (ISIRAC rule).
        let duration = Math.ceil((+moment(end).diff(moment(start), 'minutes', true).toFixed(0) - 59) / 60 / 24);
        // console.log("duration: ", duration);

        let kmPlan = +reservationData.attributes.filter(attr => attr.name === 'KILOMETERS')[0].value;

        // Setting up global infos
        this.data = {
            type: this.type || checkData.type,
            plate: itemData.id,
            category: itemData.category,
            name: userProfile.firstName.charAt(0).toUpperCase() + userProfile.firstName.slice(1) + ' ' + userProfile.lastName.toUpperCase(),
            accessPoint: accessPoint,
            booking: reservationData,
            start_date: start,
            end_date: end,
            duration: duration,
            kmIncluded: kmPlan
        };

        // Setting up start / end - odometer | gauge | validation date w/ tz
        if (this.data.type === "IN") {
            let validation = moment(checkData.creationDatetime); // + zoneDifference
            validation._offset *= -1;
            this.data.start = {
                km: +checkData.itemStates.filter(item => item.name === "ODOMETER")[0].value,
                fuel: +checkData.itemStates.filter(item => item.name === "FUEL_TANK")[0].value,
                validation: validation
            };
        } else if (this.data.type === "OUT") {
            // Set Real Duration
            let realDuration = Math.ceil((+moment(checkData.creationDatetime).diff(moment(start), 'minutes', true).toFixed(0) - 59) / 60 / 24);
            // if (realDuration > 1) console.log("Durée réèlle: ", realDuration); // LATE !

            // Get CheckIn data
            await this.checkService.getCheck({ id: reservationData.checkInId }).toPromise().then((checkIn) => {
                let validation_start = moment(checkIn.creationDatetime); // + zoneDifference
                validation_start._offset *= -1;

                this.data.start = {
                    km: +checkIn.itemStates.filter(item => item.name === "ODOMETER")[0].value,
                    fuel: +checkIn.itemStates.filter(item => item.name === "FUEL_TANK")[0].value,
                    validation: validation_start
                };

                let validation_end = moment(checkData.creationDatetime); // + zoneDifference
                validation_end._offset *= -1;

                this.data.end = {
                    km: +checkData.itemStates.filter(item => item.name === "ODOMETER")[0].value,
                    fuel: +checkData.itemStates.filter(item => item.name === "FUEL_TANK")[0].value,
                    validation: validation_end,
                    late: realDuration > this.data.duration,
                    realDuration: realDuration
                };

                // Check if kilometers used > included
                this.data.km_total = (this.data.end.km - this.data.start.km) || 0;
            });
        }

        this.getDamages(checkData.damages, checkList);

        // Count damages for page formatting
        let damagePageCount = 0;
        let newDamagePageCount = 0;

        if (this.previousDamages) {
            damagePageCount = Math.ceil((this.previousDamages.length - 5) / 8);
        }

        if (this.newDamages) {
            newDamagePageCount = Math.ceil((this.newDamages.length - 5) / 8);
        }

        let max = Math.max(damagePageCount, newDamagePageCount) + 1;
        for (let i = 1; i < max; i++) {
            this.pageCount.push({
                id: i + 1,
                damages: this.previousDamages.slice(5 + 8 * (i - 1), 5 + 8 * i),
                newDamages: this.newDamages.slice(5 + 8 * (i - 1), 5 + 8 * i),
            });
        }

        this.loading = false;



        if (this.generate) {
            this.data.previousDamages = this.previousDamages;
            this.data.pageCount = this.pageCount;
            this.data.newDamages = this.newDamages;
            console.log(JSON.stringify(this.data, undefined, 2))
            this.pdfService.generatPDF(this.data).subscribe(data => {
                console.log(data)
                this.event.emit(data);
            })
            
        }
    }
    
    getDamages(newDamages, checkList) {
        this.newDamages = newDamages || [];

        // Filtering previous checks that aren't null
        checkList = checkList.filter((item) => { return !!item; });
        // Ordering damage from recent to ancient
        checkList = checkList.sort((prev, next) => {
            return moment(prev.creationDatetime).diff(next.creationDatetime) * -1;
        });
        
        
        // Extracting damages

        for (let i = 0; i < checkList.length ; i++) {
            
            if (checkList[i].damages) {
                checkList[i].damages.forEach( list => {
                    var type = list.type.split(".");
                    var itemPart = list.itemPart.split(".")
                    list.type = this.dommage.DAMAGE_TYPE[type[1]]
                    list.itemPart = this.dommage.VEHICLE_PART[itemPart[1]]
                });
                this.previousDamages.push(...checkList[i].damages);
            }
        }
       
        
        this.newDamages.forEach( list => {
            var type = list.type.split(".");
            var itemPart = list.itemPart.split(".")
            list.type = this.dommage.DAMAGE_TYPE[type[1]]
            list.itemPart = this.dommage.VEHICLE_PART[itemPart[1]]
        });

        // Setting preview picture
        for (let i = 0; i < this.newDamages.length; i++) {

            if (!!this.newDamages[i].mediaPath) this.newDamages[i].preview = this.imageBucketUrl + this.newDamages[i].mediaPath;
        }
        for (let i = 0; i < this.previousDamages.length; i++) {
            if (!!this.previousDamages[i].mediaPath) this.previousDamages[i].preview = this.imageBucketUrl + this.previousDamages[i].mediaPath;
        }

        // Remove duplicates from previous
        for (let i = 0; i < this.newDamages.length; i++) {
            this.previousDamages = this.previousDamages.filter((e) => {
                return e.creationDatetime !== this.newDamages[i].creationDatetime && e.mediaPath !== this.newDamages[i].mediaPath;
            });
        }
    }

    generatePages(): Promise<any> {
        return new Promise<any>((resolve) => {
            setTimeout(async () => {
                let images = new Array(this.nodes.length);
                for (let i = 0; i < this.nodes.length; i++) {
                    await domtoimage.toPng(this.nodes[i], {
                        style: { margin: 0 },
                        quality: 0.30 
                        
                    }).then((result) => {
                        images[i] = result;
                    }).catch((error) => {
                        console.log("domtoimage error: ", error);
                    });
                }

                resolve(images);
            }, 2000);
        });
    }

    getAccessPoint(locale,access): any {
        console.log(locale)
        let res
        access.forEach(element => {
            if (element.locale ==locale){
                element.keys.forEach(ele => {
                    if (ele.name == "name"){
                        res = ele.value
                        
                    }
                })
            }
        });
        return res
    }


}

