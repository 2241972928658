import { Injectable } from '@angular/core';
import { environment } from "../../environments/environment";
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class ItemService {

    constructor(private http: HttpClient) {}

    public getItem(input: any): Observable<any> {
        return this.http.get<any>(environment.BFF + "item/" + input.id);
    }
}
