<div *ngIf="!loading">
    <div class="label" (click)="toggleCard()">
        {{ 'ACCESS_POINT.location' | translate }}
        <fa-icon [icon]="['far', isOpened ? 'angle-up' : 'angle-down']"></fa-icon>
    </div>

    <div class="main-infos" *ngIf="isOpened">
        <div class="sub-infos">
            <div class="agency">
                <div class="name">{{ access?.name }}</div>
                <div class="user-distance" *ngIf="userDistance">
                    {{ 'ACCESS_POINT.distance_from_user' | translate: { value: userDistance } }}
                </div>
            </div>
            <a class="gmap-link" [href]="gmapLink" target="_blank">
                <fa-icon [icon]="['far', 'map-marked-alt']"></fa-icon>
            </a>
        </div>
        <div class="address">
            {{ access?.address.streetNumber + ' ' + access?.address.street + ', ' + access?.address.zipCode + ' ' + access?.address.city + ' ' + access?.address.country }}
        </div>
    </div>

    <div class="contacts-infos" *ngIf="isOpened">
        <div class="phone" *ngIf="access?.contact?.phoneNumber">
            <fa-icon [icon]="['fal', 'phone-square-alt']"></fa-icon>
            <a href="tel:{{ access?.contact?.phoneNumber }}">{{ access?.contact?.phoneNumber }}</a>
        </div>
        <div class="mail" *ngIf="access?.contact?.email">
            <fa-icon [icon]="['fal', 'envelope-square']"></fa-icon>
            <a href="mailto:{{ access?.contact?.email }}">{{ access?.contact?.email }}</a>
        </div>
        <div class="fax" *ngIf="access?.contact?.fax">
            <fa-icon [icon]="['fal', 'fax']"></fa-icon>
            {{ access?.contacts.fax }}
        </div>
    </div>

    <div class="additional-infos" *ngIf="isOpened">
        <div class="info" *ngFor="let i of access?.additionalInformations">
            <span *ngIf="i.name === 'parking' && i.value">
                {{ 'ACCESS_POINT.parking' | translate: { value: i.value } }}
            </span>
            <span *ngIf="i.name === 'digicode' && i.value">
                {{ 'ACCESS_POINT.digicode' | translate: { value: i.value } }}
            </span>
            <span *ngIf="i.name === 'level' && i.value">
                {{ 'ACCESS_POINT.level' | translate: { value: i.value } }}
            </span>
            <span *ngIf="i.name === 'places' && i.value">
                {{ 'ACCESS_POINT.places' | translate: { value: i.value } }}
            </span>
            <span *ngIf="i.name === 'other' && i.value">
                {{ 'ACCESS_POINT.others' | translate: { value: i.value } }}
            </span>
            <span *ngIf="i.name === 'instructions' && i.value">
                {{ 'ACCESS_POINT.others' | translate: { value: i.value } }}
            </span>
        </div>
    </div>
</div>
