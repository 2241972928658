import { Component, OnChanges, Input } from '@angular/core';

@Component({
    selector: 'paydeposit-detail',
    templateUrl: './paydeposit-detail.html',
    styleUrls: ['./paydeposit-detail.scss']
})
export class PayDepositDetailComponent implements OnChanges {
    @Input('reservation-id') reservationID: string;
    @Input('booking-id') bookingID: string;

    public isOpened: boolean = true;

    constructor(
    ) {}

    ngOnChanges() {
    }

    toggleCard() {
        this.isOpened = !this.isOpened;
    }
}
