
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';

@Injectable()
export class SessionService {
    constructor(private http: HttpClient) {}

        public getSessionId(): Observable<any> {
            return this.http.post<any>(environment.BFF + "session",{});
        }

        public addSessionId(){
            let sessionId = localStorage.getItem('sessionId');
            if (sessionId == null) { 
                this.getSessionId().subscribe(data => {
                    localStorage.setItem('sessionId',data.sessionId)
                    return data.sessionId
                });
            }
            return sessionId
        }
        
}