import {
    Component,
    OnInit
} from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { ModalManager } from 'src/app/modal/global/global';
import { NotificationManager } from 'src/app/notification/notification';

import { BookingService } from 'src/app/services/booking';
import { ReservationStorage } from 'src/app/services/reservation.storage';

import { HeaderMenuService } from 'src/app/services/header-menu';
import { LanguageService } from 'src/app/services/language';

import * as moment from 'moment';
import TrackingService from 'src/app/utils/trackingService';

@Component({
    selector: 'confirm-payment',
    templateUrl: './confirm-payment.html',
    styleUrls: ['./confirm-payment.scss']
})
export class ConfirmPaymentComponent implements OnInit {
    public accessID : string
    public bookingID: string;
    public category: any;
    public booking: any;
    public prices = {
        totalPrice: 0,
        deposit: 0,
        addedKm: 0,
    };
    public cguAccepted: boolean = false;
    public cards = {
        "pricing": true,
        "deposit": true,
        "vehicle": false,
        "location": false,
        "payment": true
    };

    constructor(
        private reservationStorage: ReservationStorage,
        private bookingService: BookingService,
        private headerMenuService: HeaderMenuService,
        private translateService: TranslateService,
        private modalManager : ModalManager,
        private router: Router,
        private notificationManager: NotificationManager,
        private languageService: LanguageService,
        private trackingService: TrackingService
    ) {
        this.languageService.getObservable().subscribe((lang) => {
            moment.locale(lang.i18n);
        });

        this.headerMenuService.setShowMenu(true);
        this.headerMenuService.setHeader({
            color: 'default',
            showLogo: true,
            showBack: false,
            showIcon: true
        });

        let payment = localStorage.getItem("payment");
        let token_expired = localStorage.getItem("token_expired");

        if (payment === "true") {
            let reservationId = localStorage.getItem("reservationId");
            localStorage.setItem("payment", "false");
            this.router.navigate(["deposit"], { queryParams: { id: reservationId } });
        }

        if (token_expired === "true") {
            // console.log("session exper");
            localStorage.setItem("token_expired", "false");
            this.notificationManager.addNotification(this.translateService.instant("RESERVATION.CLIENT.DETAILS.booking_expired"), "success", 3000, true);
            this.router.navigate(["map"]);
        }
    }

    ngOnInit() {
        this.accessID = this.reservationStorage.getAgency().id;
        this.bookingID = localStorage.getItem('idBooking');

        this.bookingService.getSession(this.bookingID).subscribe((res) => {
            this.booking = res;
            // Duration calculated with an hour of floatment (ISIRAC rule).
            // this.duration = Math.ceil((+moment(this.booking.end).diff(moment(this.booking.start), 'minutes', true).toFixed(0) - 59) / 60 / 24);
            this.category = this.reservationStorage.getCategory();

            this.trackingService.trackPaymentConfirmStep(this.category, this.reservationStorage, this.booking);
        });

    }

    warningCGU() {
        this.modalManager.openModal(this.translateService.instant("GENERIC.warning"), this.translateService.instant("RESERVATION.accept_cgu"), {});
    }

    toggleCard(cardType: string) {
        this.cards[cardType] = !this.cards[cardType];
    }
    clickedCGU(data){
        this.cguAccepted = data
    }
}
