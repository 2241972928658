import {
    Component,
    Input,
    Output,
    EventEmitter,
    OnInit,
    ViewChild,
    ElementRef
} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { AccessService } from 'src/app/services/access';
import { OptionService } from 'src/app/services/option';
import { BoxService } from 'src/app/services/box';
import { CheckService } from 'src/app/services/check';
import { ReservationService } from 'src/app/services/reservation';
import { ItemService } from 'src/app/services/item';

import { HeaderMenuService } from 'src/app/services/header-menu';

import { ModalManager } from 'src/app/modal/global/global';
import { NotificationManager } from 'src/app/notification/notification';

import * as moment from 'moment';
import { TransactionService } from 'src/app/services/transaction';

@Component({
    selector: 'key',
    templateUrl: './key.html',
    styleUrls: ['./key.scss']
})
export class KeyComponent implements OnInit {
    public accessID: string;
    public reservationID: string;

    @Output('event') event = new EventEmitter<any>();
    @ViewChild('externalLink', { static: false }) externalLink: ElementRef;

    private optionTypes: any;

    public accessData: any;
    public reservationData: any;
    public boxData: any;
    public itemData: any;
    public optionData: any;
    public checks: any = { in: null, out: null };
    public doorsStatus: boolean;
    public loading: boolean = true;
    public keyAction: string = 'none';

    public fuelStatus = {
        unit: 'L',
        type: '',
        percent: 0,
        current: 0,
        capacity: 0,
        start: 0,
        start_percent: 0
    };

    public distStatus = {
        unit: 'km',
        percent: 0,
        current: 0,
        plan: 0,
        start: 0
    };

    constructor(
        private _activatedRoute: ActivatedRoute,
        private router: Router,
        private translate: TranslateService,

        private accessService: AccessService,
        private boxService: BoxService,
        private checkService: CheckService,
        private itemService: ItemService,
        private optionService: OptionService,
        private reservationService: ReservationService,

        private headerMenuService: HeaderMenuService,
        private modalManager: ModalManager,
        private notificationManager: NotificationManager,
        private transactionService:TransactionService
    ) {
        this._activatedRoute.queryParams.subscribe((params) => {
            this.accessID = params['agency'];
            this.reservationID = params['reservation'];
        });
        this.transactionService.verifyBalance(this.reservationID).subscribe( res => {
            if (res.result == false){
                console.log("createCheck error: ");
                this.notificationManager.addNotification(this.translate.instant("CHECK.DECLARE.pdf_sent_notification"), "error", 3000, true);
                this.router.navigate(['detail'], { queryParams: { id: this.reservationID }});
            }
        })

    }

    ngOnInit() {
        this.headerMenuService.setShowMenu(true);
        this.headerMenuService.setHeader({
            color: 'default',
            showLogo: true,
            showBack: false,
            showIcon: true
        });

        

        this.inputsLoaded().then(() => {
            Promise.all([
                this.accessService.getAccess(this.accessID).toPromise(),
                this.reservationService.getReservation({ id: this.reservationID }).toPromise(),
                this.optionService.getOptionTypes().toPromise(),
                this.optionService.getOptionList({ id: this.reservationID }).toPromise()
            ]).then(async (responses) => {
                this.accessData = responses[0];
                // console.log("accessData: ", this.accessData);
                this.reservationData = responses[1].result;
                // console.log("reservationData: ", this.reservationData);
                await this.itemService.getItem({ id: this.reservationData.itemId }).toPromise().then((response) => {
                    this.itemData = response.result;
                    // console.log("itemData: ", this.itemData);
                }),
                await this.boxService.getBox({ id: this.reservationData.itemId }).toPromise().then((response) => {
                    this.boxData = response.result;
                    // console.log("boxData: ", this.boxData);
               }),

                this.optionTypes = responses[2].result;
                // console.log("optionTypes: ", this.optionTypes);
                this.optionData = responses[3];
                // console.log("optionData: ", this.optionData);
                this.getChecks();
            })
        });
    }

    inputsLoaded = (): Promise<any> => {
        return new Promise<any>((resolve) => {
            let check = setInterval(() => {
                if (this.accessID && this.reservationID) {
                    clearInterval(check);
                    resolve(true);
                } else {
                    console.log("Not loaded: ", this.accessID, this.reservationID);
                }
            }, 500);
        });
    };

    setDoorsLock(lock: boolean) {
        if (this.keyAction !== 'none') return;

        this.keyAction = lock ? 'close' : 'open';

        this.boxService.setDoorsLock({ id: this.reservationData.itemId, lock: lock }).toPromise().then((response) => {
            // console.log("setDoorsLock - " + this.keyAction + ": ", response);
            if (response.result === 'success') {
                this.doorsStatus = lock;
            } else {
                this.notificationManager.addNotification("Erreur lors de " + (lock ? "la fermeture" : "l'ouverture") + " des portes", "error", 3000, true);
            }
            this.keyAction = 'none';
        }).catch(() => {
            this.notificationManager.addNotification("Erreur lors de " + (lock ? "la fermeture" : "l'ouverture") + " des portes", "error", 3000, true);
            this.keyAction = 'none';
        });
    }

    async getChecks() {
        if (this.reservationData && this.reservationData.checkInId) {
            await this.checkService.getCheck({ id: this.reservationData.checkInId }).toPromise().then((response) => {
                this.checks.in = response;
            }).catch((error) => {
                console.log("Check not found: ", error);
            });
        }
        if (this.reservationData && this.reservationData.checkOutId) {
            await this.checkService.getCheck({ id: this.reservationData.checkOutId }).toPromise().then((response) => {
                this.checks.out = response;
            }).catch((error) => {
                console.log("Check not found: ", error);
            });
        }

        this.setFuelStatus();
        this.setDistanceStatus();
        this.setDoorsStatus();
        this.loading = false;
    }

    setFuelStatus() {
        if (this.itemData && this.itemData.attributes.length) {
            this.fuelStatus.capacity = +this.itemData.attributes.filter(attr => attr.name === 'FUEL_TANK_CAPACITY')[0].value;
            this.fuelStatus.type = this.itemData.attributes.filter(attr => attr.name === 'FUEL_TYPE')[0].value;
            this.fuelStatus.current = +this.boxData.states.filter(state => state.name === 'FUEL_TANK')[0].value;

            if (this.checks.in) {
                this.fuelStatus.start = +this.checks.in.itemStates.filter(state => state.name === 'FUEL_TANK')[0].value;
            }
            if (this.checks.out) {
                this.fuelStatus.current = +this.checks.out.itemStates.filter(state => state.name === 'FUEL_TANK')[0].value;
            }

            this.fuelStatus.start_percent = Math.floor((this.fuelStatus.start * 100) / this.fuelStatus.capacity);
            this.fuelStatus.percent = Math.floor(((this.fuelStatus.current) * 100) / this.fuelStatus.capacity);

            // console.log("fuelStatus: ", this.fuelStatus);
        }
    }

    setDistanceStatus() {
        if (this.itemData && this.itemData.attributes.length) {
            this.distStatus.plan = +this.reservationData.attributes.filter(attr => attr.name === 'KILOMETERS')[0].value;
            this.distStatus.current = +this.boxData.states.filter(state => state.name === 'ODOMETER')[0].value;

            if (this.checks.in) {
                this.distStatus.start = Number(this.checks.in.itemStates.filter(state => state.name === 'ODOMETER')[0].value);
                this.distStatus.percent = Math.floor(((this.distStatus.current - this.distStatus.start) * 100) / this.distStatus.plan);
            }
            if (this.checks.out) {
                this.distStatus.current = +this.checks.out.itemStates.filter(state => state.name === 'ODOMETER')[0].value;
            }

            // console.log("distStatus: ", this.distStatus);
        }
    }

    setDoorsStatus() {
        for (let j = 0; j < this.boxData.states.length; j++) {
            if (this.boxData.states[j].name === 'DOORS') {
                this.doorsStatus = this.boxData.states[j].value === 'CLOSED';
            }
        }
    }

    doCheck(type) {
        let checkCallback = () => {
            this.router.navigate(['edl'], {
                queryParams: { type: type, agency: this.accessID, vehicle: this.reservationData.itemId, booking: this.reservationID }
            });
        };

        if (type === 'IN') {
            checkCallback();
        } else if (type === 'OUT') {
            this.modalManager.openModal(
                this.translate.instant("CHECK.DECLARE.confirm_check_out"),
                `<div>${this.translate.instant("CHECK.DECLARE.template_out")}</div>`,
                { warning: this.translate.instant("CHECK.DECLARE.warning_out") },
                true,
                false,
                checkCallback
            );
        }
    }
}
