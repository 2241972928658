import {
    Component,
    ViewChild,
    ElementRef,
    OnInit
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { HeaderMenuService } from 'src/app/services/header-menu';
import { ReservationStorage } from 'src/app/services/reservation.storage';
import { BookingService } from 'src/app/services/booking';
import { NotificationManager } from 'src/app/notification/notification';

import { Categories } from 'src/app/interfaces/categories';
import * as moment from 'moment';
import { AccessService } from 'src/app/services/access';
import TrackingService from 'src/app/utils/trackingService';

@Component({
    selector: 'select-vehicle',
    templateUrl: './select-vehicle.html',
    styleUrls: ['./select-vehicle.scss']
})
export class SelectVehicleComponent implements OnInit {
    @ViewChild('externallink', { static: false }) externalLink: ElementRef;

    public startDate: any;
    public endDate: any;
    public agency: any;
    public category: any;
    public vehicles: any[];
    public optionsCount: number = 0;
    public baseKilometers: number = 100;
    public additionalKilometers: number = 0;
    public additionalDrivers: number = 0;
    public categorySelected: any;
    public vehicleConfirmed: boolean = false;
    public price: number = 0;
    public duration: number = 1;
    public categories =  Categories;
    public accessID
    public priceVehicle :any

    constructor(
        private translateService: TranslateService,
        private reservationStorage: ReservationStorage,
        private headerMenuService: HeaderMenuService,
        private bookingService : BookingService,
        private router: Router,
        private notificationManager: NotificationManager,
        private activatedRoute: ActivatedRoute,
        private accessService: AccessService,
        private trackingService: TrackingService
    ) {
        this.activatedRoute.queryParams.subscribe(params => {
            this.startDate = params['startdate'];
            this.endDate = params['enddate'];
            this.accessID = params['accessid'];
            this.category = params['category'];
        });
    }

    async ngOnInit () {
        window.scroll(0,0);
        window["visibilitychange(test)"];

        this.vehicles = [];
        if (!this.startDate) {
            this.startDate = this.reservationStorage.getDates().startDate;
        }
        if (!this.endDate) {
            this.endDate = this.reservationStorage.getDates().endDate;
        }
        if (!this.accessID) {
            this.agency = this.reservationStorage.getAgency();
        } else {
            this.agency = await this.accessService.getAccess(this.accessID).toPromise();
            this.agency['name'] = this.getName(this.agency, "fr");
            this.reservationStorage.setAgency(this.agency);

            if (this.category) {
                // Duration calculated with an hour of floatment (ISIRAC rule).
                this.duration = Math.ceil((+moment(this.endDate).diff(moment(this.startDate), 'minutes', true).toFixed(0) - 59) / 60 / 24);
                this.chooseCategory(this.category);
                return;
            }

            await this.accessService.getBookingCategoryAvailable({
                accessID: this.accessID,
                start: moment(this.startDate).valueOf(),
                end: moment(this.endDate).valueOf()
            }).subscribe(async (res: any) => {
                this.agency['categories'] = [];
                let pricesRequests = [];
                if (res.result && res.result.length > 0) {
                    for (let i = 0; i < res.result.length; i++) {
                        let price = await this.bookingService.getPrice({
                            accessId: this.accessID,
                            itemCategory: res.result[i].category,
                            start: moment(this.startDate).valueOf(),
                            end : moment(this.endDate).valueOf(),
                            kilometers: 100
                        }).toPromise();

                        pricesRequests.push(price);
                    }
                    for (let x = 0; x < pricesRequests.length; x++) {
                        this.agency['categories'].push({ id: res.result[x].category });
                        this.agency['categories'][x]['vehiclePrice'] = pricesRequests[x].paymentPrice / 100;
                        // this.minPrice = this.minPrice > 0 && this.minPrice < pricesRequests[x].paymentPrice / 100 ? this.minPrice : pricesRequests[x].paymentPrice / 100;
                        this.agency['categories'][x]['depositPrice'] = pricesRequests[x].depositPrice / 100;
                    }
                    this.reservationStorage.setAgency(this.agency);
                }
            });
         }

        // Duration calculated with an hour of floatment (ISIRAC rule).
        this.duration = Math.ceil((+moment(this.endDate).diff(moment(this.startDate), 'minutes', true).toFixed(0) - 59) / 60 / 24);

        this.headerMenuService.setShowMenu(false);
        this.headerMenuService.setHeader({
            color: 'blue',
            showLogo: true,
            showBack: false,
            showIcon: true
        });

        if (!this.accessID) {
            // Filter categories to remove ones with a null price
            this.agency.categories = this.agency.categories.filter((item) => {
                return item.vehiclePrice && item.vehiclePrice > 0;
            });
        }
    }

    chooseCategory(catId, price?) {
        this.priceVehicle = price;
        window.scroll(0,0);
        document.querySelector("mat-sidenav-content").scrollTo(0, 0);

        this.vehicleConfirmed = true;
        this.categorySelected = this.categories[catId];
        this.categorySelected.id = catId;
        let start = moment(this.startDate).valueOf()
        let end = moment(this.endDate).valueOf()
        this.bookingService.createBooking({"start": start, "end": end, "accessId": this.agency.id, "itemCategory": catId}).subscribe((res : any) => {
            if (res.result === "success") {
                window.localStorage.setItem('idBooking', res.id);
                window.localStorage.setItem('payment', "false");
                this.categorySelected.idBooking = res.id;
                this.getBookingPrices()
                    .then(() => {
                        this.trackingService.trackVehicleRecap(this.categorySelected, this.reservationStorage, {
                            start,
                            end,
                            bookingPrice: {
                                paymentPrice: this.price * 100
                            },
                        });
                    });
            }
        });
        this.headerMenuService.setShowMenu(false);
        this.headerMenuService.setHeader({
            color: 'default',
            showLogo: true,
            showBack: false,
            showIcon: false
        });
    }

    showPic(category, index) {
        this.categories[category].viewPic = index;
    }

    changeKMPlan(type: string) {
        if (type === 'plus') {
            this.additionalKilometers += 50;
        } else if (type === 'minus' && this.additionalKilometers > 0) {
            this.additionalKilometers -= 50;
        }

        this.getBookingPrices();
    }

    changeDriverPlan(type: string) {
        if (type === 'plus' && this.additionalDrivers < 5) {
            this.additionalDrivers++;
        } else if (type === 'minus' && this.additionalDrivers > 0) {
            this.additionalDrivers--;
        }

        this.getBookingPrices();
    }

    getBookingPrices() {
        let input = {
            "id": this.categorySelected.idBooking,
            "bookingOption": {
                "quantity": this.baseKilometers + this.additionalKilometers
            }
        };
        return new Promise<void>((resolve) => {
            this.bookingService.addOptionToSession(input).subscribe((response: any) => {
                this.price = response.bookingPrice.paymentPrice / 100;
                this.priceVehicle = this.price;
                resolve();
            });
        });

    }

    confirmVehicleAndOptions() {
        this.reservationStorage.setCategory(this.categorySelected);
        this.reservationStorage.setOptions({
            additionalKilometers: this.additionalKilometers,
            additionalDrivers: this.additionalDrivers
        });
        if (this.checkTimeBooking({id : this.categorySelected.idBooking})) {
            this.bookingService.updateBooking({ id : this.categorySelected.idBooking }).subscribe((res : any) => {
                let start = moment(this.startDate).valueOf()
                let end = moment(this.endDate).valueOf()
                this.trackingService.trackProductAdded(this.categorySelected, this.reservationStorage, {
                    start,
                    end,
                    bookingPrice: {
                        paymentPrice: this.price * 100
                    },
                });
                this.router.navigate(['payment']);
            });
        } else {
            this.notificationManager.addNotification(this.translateService.instant("INTERFACES.ERROR.prebooking_expired"), "warning", 3000, true);
        }
    }

    async checkTimeBooking(id) {
        let booking = await this.bookingService.getBooking(id).toPromise();
        return (booking.result.expirationDatetime - Date.now()) > (60 *1000);
    }

    getName(res, lang) {
        let returnValue = "";
        res.i18n.forEach(item => {
            if(item.locale == lang){
                item.keys.forEach(obj => {
                    if(obj.name == "name"){
                        return returnValue = obj.value;
                    }
                });
            }
        });
        return returnValue
    }
}
