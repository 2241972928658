export const Dommage = {
    "DAMAGE_TYPE": {
		damaged_fabric: "Tissu endommagé",
		deep_scratch: "Rayure profonde",
		impact: "Impact",
		missing: "Manquant(e)",
		stained_fabric: "Tissu tâché",
		stripe: "Rayure",
		stripe_and_sink: "Rayure et enfoncement"
	},
    "VEHICLE_PART": {
		bonnet: "Capot moteur",
		calender: "Calandre",
		front_bumper: "Pare-choc avant",
		ignition_key: "Clé de contact",
		left_front_door: "Porte avant gauche",
		left_front_light: "Feu avant gauche",
		left_front_rim: "Jante avant gauche",
		left_front_wheel: "Roue avant gauche",
		left_front_wing: "Aile avant gauche",
		left_mirror: "Rétroviseur gauche",
		left_rear_door: "Porte arrière gauche",
		left_rear_light: "Feu arrière gauche",
		left_rear_rim: "Jante arrière gauche",
		left_rear_wheel: "Roue arrière gauche",
		left_rear_wing: "Aile arrière gauche",
		left_sill: "Bas de caisse gauche",
		left_taillight: "Clignotant gauche",
		radio_antenna: "Antenne radio",
		rear_bumper: "Pare-choc arrière",
		rear_windshield: "Pare-brise arrière",
		rearview_mirror: "Rétroviseur intérieur",
		right_front_door: "Porte avant droite",
		right_front_light: "Feu avant droit",
		right_front_wheel: "Jante avant droite",
		right_front_wing: "Aile avant droite",
		right_mirror: "Rétroviseur droit",
		right_rear_door: "Porte arrière droite",
		right_rear_light: "Feu arrière droit",
		right_rear_wheel: "Roue arrière droite",
		right_rear_wing: "Aile arrière droite",
		right_sill: "Bas de caisse droit",
		right_taillight: "Clignotant droit",
		roof: "Toit",
		security_kit: "Kit de secours",
		spare_tire_puncture_protection_kit: "Roue de secours / kit anti-crevaison",
		vehicle_interior: "Intérieur véhicule",
		vehicle_papers: "Papiers du véhicule",
		windshield: "Pare-brise"
	}
};