<div class="header-category" *ngIf="!vehicleConfirmed && agency">
    <div class="label">{{ agency?.name }}</div>
    <div class="sub-label">{{ 'ACCESS_POINT.TYPE.' + agency?.type | translate }}</div>
    <div class="category-count">
        <span *ngIf="agency?.categories?.length <= 1">
            {{ 'VEHICLE.SELECT.category_count' | translate:{value:agency?.categories?.length} }}
        </span>
        <span *ngIf="agency?.categories?.length > 1">
            {{ 'VEHICLE.SELECT.categories_count' | translate:{value:agency?.categories?.length} }}
        </span>
    </div>
</div>
<div class="vehicle-selection" *ngIf="!vehicleConfirmed">
    <div class="vehicle-card" *ngFor="let cat of agency?.categories; let i = index" (click)="chooseCategory(cat.id, cat.vehiclePrice)">
        <div class="category">{{ categories[cat.id].title | translate }}</div>
        <div class="row">
            <div class="type">
                {{ categories[cat.id].carTypes.join(', ') }} {{ 'VEHICLE_CARD.or_similar' | translate }}
            </div>
            <div class="seats">
                <fa-icon [icon]="['fas', 'user']"></fa-icon>
                <span class="bold-blue">{{ categories[cat.id].seats || 4 }}</span>
                <span>{{ 'VEHICLE_CARD.seats' | translate }}</span>
            </div>
        </div>
        <div class="vehicle-picture">
            <img [src]="'assets/img/cube_pic/' + cat.id + '.png'" alt="Car" />
        </div>
        <div class="row">
            <div>
                <div class="price">{{ cat.vehiclePrice }}€</div>
                <div class="row">
                    <div class="duration">
                        <fa-icon [icon]="['fas', 'calendar-day']"></fa-icon>
                        <span class="bold-blue">{{ duration }}</span>
                        <span *ngIf="duration === 1">{{ 'VEHICLE_CARD.day' | translate }}</span>
                        <span *ngIf="duration > 1">{{ 'VEHICLE_CARD.days' | translate }}</span>
                    </div>
                    <div class="km-included">
                        <fa-icon [icon]="['fas', 'road']"></fa-icon>
                        <span class="bold-blue">{{ duration * 100 }}</span>
                        <span>{{ 'VEHICLE_CARD.km' | translate }}</span>
                    </div>
                </div>
            </div>
            <div class="select-button" (click)="chooseCategory(cat.id, cat.vehiclePrice)">
                <fa-icon [icon]="['far', 'arrow-right']"></fa-icon>
            </div>
        </div>
        <hr class="separator" />
        <div class="row">
            <div class="transmission">
                <div class="label">{{ 'VEHICLE_CARD.transmission' | translate }}</div>
                <div class="value">{{ categories[cat.id].transmission | translate }}</div>
            </div>
            <div class="engine">
                <div class="label">{{ 'VEHICLE_CARD.engine' | translate }}</div>
                <div class="value">
                    <span *ngFor="let type of categories[cat.id].fuelType">{{ type | translate }} </span>
                </div>
            </div>
            <div class="doors">
                <div class="label">{{ 'VEHICLE_CARD.doors' | translate }}</div>
                <div class="value">{{ categories[cat.id].doors || 5 }}</div>
            </div>
        </div>
    </div>
</div>
<div class="header-options" *ngIf="vehicleConfirmed && agency">
    <div class="title">
        {{ agency.name }}
    </div>
    <div class="dates">
        <fa-icon [icon]="['far', 'calendar-alt']"></fa-icon>
        <div class="date">
            <span>{{ startDate | date:'dd MMM yyyy' }}</span>
            <span>{{ startDate | date:'HH:mm' }}</span>
        </div>
        <fa-icon [icon]="['far', 'long-arrow-right']"></fa-icon>
        <div class="date">
            <span>{{ endDate | date:'dd MMM yyyy' }}</span>
            <span>{{ endDate | date:'HH:mm' }}</span>
        </div>
    </div>
    <div class="vehicle">
        <div class="vehicle-label">
            <div class="category">{{ categories[categorySelected.id].title | translate }}</div>
            <div class="separator"></div>
            <div class="model">
                {{ categories[categorySelected.id].carTypes.join(', ') }} {{ 'VEHICLE_CARD.or_similar' | translate }}
            </div>
        </div>
        <div class="vehicle-image">
            <img [src]="'assets/img/cube_pic/' + categorySelected.id + '.png'" alt="Car" />
        </div>
        <div class="vehicle-infos">
            <div class="info">{{ categories[categorySelected.id].transmission | translate }}</div>
            <div class="info" *ngFor="let type of categories[categorySelected.id].fuelType">{{ type | translate }}</div>
            <div class="info">{{ categories[categorySelected.id].seats || 4 }} {{ 'VEHICLE_CARD.seats' | translate }}</div>
        </div>
    </div>
</div>

<div class="options-selection" *ngIf="vehicleConfirmed ">
    <div class="counter">
        <div>
            <div class="title">{{ 'OPTIONS_SELECT.kilometers_plan' | translate }}</div>
            <div class="count">
                <span class="bold-blue">{{ baseKilometers + additionalKilometers }}</span> {{ 'OPTIONS_SELECT.km_per_day' | translate }}
            </div>
        </div>
        <div class="add-remove">
            <fa-icon [icon]="['fas', 'minus-circle']" (click)="changeKMPlan('minus')"></fa-icon>
            <fa-icon [icon]="['fas', 'plus-circle']" (click)="changeKMPlan('plus')"></fa-icon>
        </div>
    </div>

    <div class="summary">
        <div class="summary-booking">
            <div class="title">{{ 'OPTIONS_SELECT.summary_booking' | translate }}</div>
            <div class="price">{{ priceVehicle }} €</div>
            <div class="infos">
                <div class="info">
                    <fa-icon [icon]="['fas', 'calendar-day']"></fa-icon>
                    <span class="bold-blue">{{ duration }}</span>
                    <span *ngIf="duration === 1">{{ 'VEHICLE_CARD.day' | translate }}</span>
                    <span *ngIf="duration > 1">{{ 'VEHICLE_CARD.days' | translate }}</span>
                </div>
                <div class="info">
                    <fa-icon [icon]="['fas', 'road']"></fa-icon>
                    <span class="bold-blue">{{ (baseKilometers + additionalKilometers) * duration }}</span>
                    <span>{{ 'VEHICLE_CARD.km' | translate }}</span>
                </div>
                <!-- <div class="info">
                    <span class="bold-blue">+ {{ optionsCount }}</span>
                    <span *ngIf="optionsCount <= 1">{{ 'OPTIONS_SELECT.option' | translate }}</span>
                    <span *ngIf="optionsCount > 1">{{ 'OPTIONS_SELECT.options' | translate }}</span>
                </div> -->
            </div>
        </div>
    </div>
    <div class="confirm">
        <button class="confirm-button" (click)="confirmVehicleAndOptions()">
            {{ 'VEHICLE.SELECT.confirm' | translate }}
        </button>
    </div>
</div>
