import { Component, ViewChild, ElementRef, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

import { UserService } from 'src/app/services/user';
import { GMapLoader } from 'src/app/services/gmapLoader';
import { HeaderMenuService } from 'src/app/services/header-menu';
import { LanguageService } from 'src/app/services/language';
import { SessionService } from 'src/app/services/session';

import { NotificationManager } from 'src/app/notification/notification';

import { Countries } from 'src/app/interfaces/countries';

@Component({
    selector: 'user',
    templateUrl: './user.html',
    styleUrls: ['./user.scss'],
})
export class UserComponent implements OnInit {
    @ViewChild('addressAutocomplete', { static: false }) addressElementRef: ElementRef;
    @ViewChild('cityAutocomplete', { static: false }) cityElementRef: ElementRef;

    public loading: boolean = true;
    public submitted: boolean = false;
    public sendingEmail: boolean = false;
    public countries: any = Countries;
    public selectedValue: any
    form: FormGroup = new FormGroup({});

    public profileInfosForm = new FormGroup({
        gender: new FormControl('', [Validators.required]),
        family_name: new FormControl('', { validators: [Validators.required], updateOn: 'blur' }),
        given_name: new FormControl('', { validators: [Validators.required], updateOn: 'blur' }),
        phone: new FormControl('', { validators: [Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")], updateOn: 'blur' }),
        street_address: new FormControl('', { validators: [Validators.required], updateOn: 'blur' }),
        additional: new FormControl(''),
        post_code: new FormControl('', { validators: [Validators.required], updateOn: 'blur' }),
        city: new FormControl('', { validators: [Validators.required], updateOn: 'blur' }),
        country: new FormControl('France', [Validators.required]),
        language: new FormControl(''),
    });
    get profileInfosFormValues() {
        return {
            gender: this.profileInfosForm.get('gender').value,
            family_name: this.profileInfosForm.get('family_name').value,
            given_name: this.profileInfosForm.get('given_name').value,
            phone: this.profileInfosForm.get('phone').value,
            street_address: this.profileInfosForm.get('street_address').value,
            additional: this.profileInfosForm.get('additional').value,
            post_code: this.profileInfosForm.get('post_code').value,
            city: this.profileInfosForm.get('city').value,
            country: this.profileInfosForm.get('country').value,
            language: this.profileInfosForm.get('language'),
        };
    }

    public profile: any;
    public documentStatus: string;
    public isReadOnly: boolean;

    public profileInfosAwsErrors = [];

    constructor(
        private translate: TranslateService,
        private userService: UserService,
        private languageService: LanguageService,
        private gmapLoader: GMapLoader,
        private headerMenuService: HeaderMenuService,
        private notificationManager: NotificationManager,
        private sessionService: SessionService,
       
    ) {
    }
  
    async ngOnInit() {
        
        let sessionId = localStorage.getItem('sessionId');
        if (sessionId === null) {
            let id = await this.sessionService.getSessionId().toPromise();
            localStorage.setItem('sessionId', id.sessionId);
        }

        this.headerMenuService.setShowMenu(true);
        this.headerMenuService.setHeader({
            color: 'default',
            showLogo: true,
            showBack: false,
            showIcon: true
        });

        let peachLoaded = setInterval(() => {
            clearInterval(peachLoaded);
        }, 200);

        // Profile and comparison setup
        this.userService.getUser().toPromise().then((response) => {
            this.profile = response.result || {};
            //console.log("=======================", this.profile);
            this.initFormValues(this.profile);
            let country = this.getCountryFromAlpha2Code(this.profile.address?.country);
            this.selectedValue = {name: country};
            this.documentStatus = this.getDocumentStatus(this.profile.documents);
            // console.log(this.documentStatus);
            this.loading = false;
        });

        // Loading map / Set addresse autocompletion
        this.gmapLoader.isLoaded.then(() => {
            this.inputsLoaded().then(() => {
                this.setAutocomplete();
            });
        });
    }

    numericOnly(event) {    
        console.log(event)
        let patt = /^([0-9])$/;
        let result = patt.test(event.key);
        if (result) {
            this.profileInfosForm.controls.phone.setErrors({ minLenght: true });
            
        } else {
            this.profileInfosForm.controls.phone.setErrors(null);
        }
        return result;
      }

    getDocumentStatus(documents) {
        if (!documents?.length) {
            this.isReadOnly = false;
            return "NOT_VERIFIED";
        }

        let docs = [];
        for (let i = 0; i < documents?.length; i++) {
            const element = documents[i];
            if (
                element?.status == "PENDING_VERIFICATION"
                || element?.status == "VERIFICATION_FAILURE"
                || element?.status == "VERIFICATION_INITIATED"
            ) docs.push(element?.status);
        }

        if (docs.length === 0) {
            this.isReadOnly = true;
            return "VERIFIED";
        } else if (docs.includes("VERIFICATION_FAILURE") || docs.includes("VERIFICATION_INITIATED")) {
            this.isReadOnly = false;
            return "NOT_VERIFIED";
        } else if (docs.includes("PENDING_VERIFICATION")) {
            this.isReadOnly = false;
            return "WAITING_FOR_VERIFICATION";
        }
    }

    inputsLoaded = (): Promise<any> => {
        return new Promise<any>((resolve) => {
            let check = setInterval(() => {
                if (google.maps.places && this.addressElementRef) {
                    clearInterval(check);
                    resolve(true);
                }
            }, 200);
        });
    };

    initFormValues(profile: any) {
        let country = this.getCountryFromAlpha2Code(profile.address?.country)
        if(profile.gender == "0"){
            this.profileInfosForm.controls.gender.setValue(null);
        }else {
            this.profileInfosForm.controls.gender.setValue(profile.gender);
        }
        this.profileInfosForm.controls.family_name.setValue(profile.lastName);
        this.profileInfosForm.controls.given_name.setValue(profile.firstName);
        this.profileInfosForm.controls.phone.setValue(profile.phoneNumber);
        this.profileInfosForm.controls.language.setValue(profile.locale);
        this.profileInfosForm.controls.street_address.setValue(profile.address?.street);
        this.profileInfosForm.controls.additional.setValue(profile.additional);
        this.profileInfosForm.controls.post_code.setValue(profile.address?.zipCode);
        this.profileInfosForm.controls.city.setValue(profile.address?.city);
        if(country){
            this.profileInfosForm.controls.country.setValue(country);
        }

        this.profileInfosForm.controls.language.setValue(this.getLanguageFromId(profile.locale));
    }

    setAutocomplete() {
        let addressAutocomplete = new google.maps.places.Autocomplete(this.addressElementRef.nativeElement);
        addressAutocomplete.addListener('place_changed', () => {
            let place: google.maps.places.PlaceResult = addressAutocomplete.getPlace();
            if (!place || place.geometry === undefined || place.geometry === null) {
                return;
            }

            let addressInput = {
                street_address: '',
                post_code: '',
                city: '',
                country: '',
                ISO_country:'',
            };

            for (let i = 0; i < place.address_components.length; i++) {
                if (place.address_components[i].types.includes('street_number')) {
                    // number
                    addressInput.street_address += place.address_components[i].long_name;
                } else if (place.address_components[i].types.includes('prefix')) {
                    // prefix
                    addressInput.street_address += ' ' + place.address_components[i].long_name;
                } else if (place.address_components[i].types.includes('route')) {
                    // street
                    addressInput.street_address += ' ' + place.address_components[i].long_name;
                } else if (place.address_components[i].types.includes('locality')) {
                    // city
                    addressInput.city = place.address_components[i].long_name;
                } else if (place.address_components[i].types.includes('postal_code')) {
                    // post_code
                    addressInput.post_code = place.address_components[i].long_name;
                } else if (place.address_components[i].types.includes('country')) {
                    // country
                    addressInput.ISO_country = place.address_components[i].short_name;
                }
            }
            let country = this.getCountryFromAlpha2Code(addressInput.ISO_country)
            this.profileInfosForm.controls.street_address.setValue(addressInput.street_address);
            this.profileInfosForm.controls.post_code.setValue(addressInput.post_code);
            this.profileInfosForm.controls.city.setValue(addressInput.city);
            this.profileInfosForm.controls.country.setValue(country);
            this.profileInfosForm.markAsDirty();
        });
    }

    phoneNumberHasError(isValid) {
        console.log("phone",this.profileInfosForm.controls.phone, isValid )
        if (this.profileInfosForm.controls.phone.errors && this.profileInfosForm.controls.phone.errors.required) {
            return;
        }

        if (!isValid) {
            this.profileInfosForm.controls.phone.setErrors({ invalid: true });
            
        } else {
            this.profileInfosForm.controls.phone.setErrors(null);
        }
    }
    capitalize(s: string): string {
        if (!s) return;
        s = s.toLowerCase();
        return s[0].toUpperCase() + s.slice(1);
    }

    getLanguageFromId(id) {
        let languageList = this.languageService.getLanguageList();
        for (let i = 0; i < languageList.length; i++) {
            if (languageList[i].type === id) {
                return languageList[i];
            }
        }

        return null;
    }

    getCountryFromAlpha2Code(id) {
        for (let i = 0; i < this.countries.length; i++) {
            if (this.countries[i].alpha2Code === id) {
                return this.countries[i].name;
            }
        }

        return null;
    }

    getCountryFromName(name) {
        for (let i = 0; i < this.countries.length; i++) {
            if (this.countries[i].name === name) {
                return this.countries[i].alpha2Code;
            }
        }

        return null;
    }

    validateForm(formRef) {
        if (formRef.valid && !this.loading) {
            return true;
        } else {
            Object.keys(formRef.controls).forEach((field) => {
                const control = formRef.get(field);
                control.markAsTouched({ onlySelf: true });
            });
            return false;
        }
    }

    updateProfile() {
        if (!this.validateForm(this.profileInfosForm) || this.submitted) {
            return;
        }
        
        this.submitted = true;
        this.profileInfosAwsErrors = [];

        this.userService.updateUser({
            email: this.profile.email,
            lastname: this.profileInfosFormValues.family_name,
            firstname: this.capitalize(this.profileInfosFormValues.given_name),
            address: {
                street:     this.profileInfosFormValues.street_address,
                additional: this.profileInfosFormValues.additional,
                zipCode:    this.profileInfosFormValues.post_code,
                city:       this.profileInfosFormValues.city,
                country:    this.getCountryFromName(this.profileInfosFormValues.country)
            },
            gender: this.profileInfosFormValues.gender,
            phoneNumber: this.profileInfosFormValues.phone
        }).toPromise().then(() => {
            this.notificationManager.addNotification(this.translate.instant("USER.profile_updated_success"), "success", 3000, true);
            this.submitted = false;
            this.profileInfosForm.markAsPristine();
        }).catch(() => {
            this.notificationManager.addNotification(this.translate.instant("USER.profile_updated_error"), "error", 3000, true);
            this.submitted = false;
        });
    }

    resendEmail() {
        if (this.sendingEmail) return;

        this.sendingEmail = true;
        // Resend email here
    }

    deleteAccount() {
        console.log('User - Delete: Not implemented yet');
    }
}
