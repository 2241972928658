import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from "src/environments/environment";

@Injectable()
export class PayDepositService {

    constructor(private http: HttpClient) {}

    public getReservation(input: any): Observable<any> {
        return this.http.get<any>(environment.BFF + "paydeposit/" + input.id);
    }

    public reservationBalance(input: any): Observable<any> {
        return this.http.get<any>(environment.BFF + "paydeposit/" + input.id + "/balance");
    }

    public getTokenDeposit(input: any, email: string): Observable<any> {
        return this.http.post<any>(environment.BFF + "paydeposit/deposit/token?email=" + email, input);
    }

    public verifyTokenDeposit(input: any): Observable<any> {
        return this.http.post<any>(environment.BFF + "paydeposit/deposit/verify", input);
    }
}