import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from 'src/environments/environment';

@Injectable()
export class ReservationService {
    constructor(private http: HttpClient) {}

    public getReservationList(): Observable<any> {
        return this.http.get<any>(environment.BFF + "reservation");
    }

    public getReservation(input: any): Observable<any> {
        return this.http.get<any>(environment.BFF + "reservation/" + input.id);
    }

    public reservationBalance(input: any): Observable<any> {
        return this.http.get<any>(environment.BFF + "reservation/" + input.id + "/balance");
    }

    public openReservation(input: any):Observable<any> {
        return this.http.put<any>(environment.BFF + "reservation/" + input.id + "/open", input);
    }

    public closeReservation(input: any):Observable<any> {
        return this.http.put<any>(environment.BFF + "reservation/" + input.id + "/close", input);
    }
}
