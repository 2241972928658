<div class="title" (click)="toggleCard()">
  {{ 'ADDITIONAL.LABEL.detail' | translate }}
  <fa-icon [icon]="['far', isOpened ? 'angle-up' : 'angle-down']"></fa-icon>
</div>

<div class="title-detail" *ngIf="details.delay || details.driverDelay || details.kmFees || details.fuelFees || details.fuelService || details.cleaningService">
  {{ 'ADDITIONAL.LABEL.optionDetail' | translate }}
</div>

<div class="price" *ngIf='details && isOpened'>
  <div class="total" *ngIf='total'>
    <div class="name">
        {{ 'ADDITIONAL.PRICING.total' | translate }}
    </div>
    <div class="value" [ngClass]="{'dark-gray': done}">
        {{ total }} {{ 'GENERIC.euros' | translate }}
    </div>
  </div>

  <div class="container" *ngIf='details.extension'>
    <div class="added">
        <div class="name">- {{ "ADDITIONAL.PRICING.extension" | translate }}</div>
        <div class="value">{{ details.extension.amount }} {{ 'GENERIC.euros' | translate }}</div>
    </div>
    <div class="quantity" [ngClass]="{'dark-gray': done}">
        {{ details.extension.datas.quantity }} {{ "ADDITIONAL.UNITY.day" | translate  }} +{{details.extension.datas.quantity * 100}} {{"ADDITIONAL.UNITY.km" | translate  }}
    </div>
  </div>

  <div class="container" *ngIf='details.addedKM'>
    <div class="added">
      <div class="name">- {{ "ADDITIONAL.PRICING.addedKM" | translate }}</div>
      <div class="value">{{ details.addedKM.amount }} {{ 'GENERIC.euros' | translate }}</div>
    </div>
    <div class="quantity" [ngClass]="{'dark-gray': done}">
        +{{ details.addedKM.quantity }} {{ "ADDITIONAL.UNITY.km" | translate }}
    </div>
  </div>

  <div class="container" *ngIf='details.addedDriver || details.newDriver'>
    <div class="container" *ngFor='let driver of addedDriver; let index = index'>
      <div class="added" *ngIf="driver.amount">
        <div class="name">- {{ "ADDITIONAL.PRICING.addedDriver" | translate }} {{ index + 1 }}</div>
        <div class="value">{{ driver.amount | number : '1.0-2' }} {{ 'GENERIC.euros' | translate }}</div>
      </div>
      <div class="quantity" *ngIf="driver.amount" [ngClass]="{'dark-gray': done}">
          {{ driver.duration }} {{ "ADDITIONAL.UNITY.day" | translate }}
      </div>
    </div>
  </div>

  <div class="title-detail" *ngIf="details.delay || details.driverDelay || details.kmFees || details.fuelFees || details.fuelService || details.cleaningService">
    {{ 'ADDITIONAL.LABEL.additionalDetail' | translate }}
  </div>

  <div class="container" *ngIf='details.delay'>
    <div class="added">
      <div class="name">- {{ "ADDITIONAL.PRICING.delay" | translate }}</div>
      <div class="value">{{ details.delay.amount }} {{ 'GENERIC.euros' | translate }}</div>
    </div>
    <div class="quantity" [ngClass]="{'dark-gray': done}">
        +{{ details.delay.datas.quantity }} {{ "ADDITIONAL.UNITY.day" | translate }}
    </div>
  </div>

  <div class="container" *ngIf='details.driverDelay'>
    <div class="added">
      <div class="name">- {{ "ADDITIONAL.PRICING.driverDelay" | translate }}</div>
      <div class="value">{{ details.driverDelay.amount }} {{ 'GENERIC.euros' | translate }}</div>
    </div>
    <div class="quantity" [ngClass]="{'dark-gray': done}">
        +{{ details.driverDelay.datas.quantity }} {{ "ADDITIONAL.UNITY.day" | translate }}
    </div>
  </div>

  <div class="container" *ngIf='details.kmFees'>
    <div class="added">
      <div class="name">- {{ "ADDITIONAL.PRICING.kmFees" | translate }}</div>
      <div class="value">{{ details.kmFees.amount }} {{ 'GENERIC.euros' | translate }}</div>
    </div>
    <div class="quantity" [ngClass]="{'dark-gray': done}">
        +{{ details.kmFees.datas.quantity }} {{ "ADDITIONAL.UNITY.km" | translate }}
    </div>
  </div>

  <div class="container" *ngIf='details.fuelFees'>
    <div class="added">
      <div class="name">- {{ "ADDITIONAL.PRICING.fuelFees" | translate }}</div>
      <div class="value">{{ details.fuelFees.amount }} {{ 'GENERIC.euros' | translate }}</div>
    </div>
    <div class="quantity" [ngClass]="{'dark-gray': done}">
        {{ details.fuelFees.datas.quantity }} {{ "ADDITIONAL.UNITY.liters" | translate }}
    </div>
  </div>

  <div class="container" *ngIf='details.fuelService'>
    <div class="added">
      <div class="name">- {{ "ADDITIONAL.PRICING.fuelService" | translate }}</div>
      <div class="value">{{ details.fuelService.amount }} {{ 'GENERIC.euros' | translate }}</div>
    </div>
  </div>

  <div class="container" *ngIf='details.cleaningService'>
    <div class="added">
      <div class="name">- {{ "ADDITIONAL.PRICING.cleaningService" | translate }}</div>
      <div class="value">{{ details.cleaningService.amount }} {{ 'GENERIC.euros' | translate }}</div>
    </div>
  </div>
</div>

<div *ngIf="done" class="label">
  <div class="text">{{ 'ADDITIONAL.LABEL.done' | translate }}</div>
  <div class="text">{{ 'ADDITIONAL.LABEL.thanks' | translate }}</div>
  <button class="confirm-button" (click)="goToKey()">
  {{ 'ADDITIONAL.LABEL.goToLocation'  | translate }}
  </button>
  <!-- <a [href]='"/detail?id="+reservationID' class="confirm-button">{{ 'ADDITIONAL.LABEL.goToLocation' | translate }}</a> -->
</div>
