import {
    Component,
    ViewChild,
    ElementRef,
    OnInit
} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { ItemService } from 'src/app/services/item';
import { ReservationService } from 'src/app/services/reservation';

import { HeaderMenuService } from 'src/app/services/header-menu';
import { LanguageService } from 'src/app/services/language';
import { ModalManager } from 'src/app/modal/global/global';

import * as moment from 'moment';
import { NotificationManager } from 'src/app/notification/notification';
import TrackingService from 'src/app/utils/trackingService';

@Component({
    selector: 'confirm-deposit',
    templateUrl: './confirm-deposit.html',
    styleUrls: ['./confirm-deposit.scss']
})
export class ConfirmDepositComponent implements OnInit {
    @ViewChild('externalLink', { static: false }) externalLink: ElementRef;

    public accessID: string;
    public vehicleID: any;
    public reservationID: string;
    public reservation: any;
    public booking: any;
    public prices: number;

    // View Modifiers
    public cguAccepted: boolean = false;
    public nameConfrmed : boolean = false
    public cards = {
        "pricing": true,
        "deposit": true,
        "vehicle": false,
        "location": false,
        "payment": true
    };

    constructor(
        private router: Router,
        private _activatedRoute: ActivatedRoute,
        private reservationService: ReservationService,
        private modalManager: ModalManager,
        private translateService: TranslateService,
        private itemService: ItemService,
        private languageService: LanguageService,
        private headerMenuService: HeaderMenuService,
        private notificationManager: NotificationManager,
        private trackingService: TrackingService
    ) {
        this.languageService.getObservable().subscribe((lang) => {
            moment.locale(lang.i18n);
        });
    }

    ngOnInit() {
        this.headerMenuService.setShowMenu(true);
        this.headerMenuService.setHeader({
            color: 'default',
            showLogo: true,
            showBack: false,
            showIcon: true
        });

        this._activatedRoute.queryParams.subscribe((params) => {
            this.reservationID = params['id'];
            if (this.reservationID == undefined){
                this.reservationID = localStorage.getItem('reservationId')
            }
            this.reservationService.getReservation({ id: this.reservationID }).toPromise().then((response) => {
                this.reservation = response.result;
                // console.log("confirm-deposit - getReservation: ", this.reservation);

                this.reservationService.reservationBalance({ id: this.reservation.id}).toPromise().then((response) => {
                    let prices = response.result;
                    for (let i = 0; i < prices.length; i++) {
                        const element = prices[i];
                        if (element.type == "DEPOSIT") {
                            this.prices = element.amount.value;
                        }
                    }
                });

                this.vehicleID = this.reservation.itemId
                this.itemService.getItem({ id: this.vehicleID }).toPromise().then((response) => {
                    this.accessID = response.result.accessId;
                });

                this.checkIfExpired();
                this.trackingService.trackDepositStep(this.reservation);
            });
        });
    }

    warningCGU() {
        this.modalManager.openModal(this.translateService.instant("GENERIC.warning"), this.translateService.instant("RESERVATION.accept_cgu"), {});
    }

    roundTimeQuarterHour(time) {
        // Find the next closest quarter hour
        let timeToReturn = time;
        timeToReturn.seconds(Math.ceil(timeToReturn.seconds() / 60) * 60);
        timeToReturn.minutes(Math.ceil(timeToReturn.minutes() / 15) * 15);
        return timeToReturn;
    }

    checkIfExpired() {
        let interval = setInterval(() => {
            if (moment().isAfter(this.reservation.end) && this.reservation.status !== 'OPEN') {
                this.router.navigate(['detail'], { queryParams: { id: this.reservationID } });
                clearInterval(interval);
            }
        }, 1000);
    }

    toggleCard(cardType: string) {
        this.cards[cardType] = !this.cards[cardType];
    }
    clickedCGU(data){
        this.cguAccepted = data
        this.nameConfrmed = data
    }
}
