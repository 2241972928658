import { Component, OnChanges, Input } from '@angular/core';
import { BookingService } from 'src/app/services/booking';
import { ReservationService } from 'src/app/services/reservation';
import { TransactionService } from 'src/app/services/transaction';

import PricingDetails from "src/app/components/link/pricing.json";

import { Router } from '@angular/router';

import * as moment from 'moment';

@Component({
    selector: 'pricing',
    templateUrl: './pricing.html',
    styleUrls: ['./pricing.scss']
})
export class PricingComponent implements OnChanges {
    @Input('reservation-id') reservationID: string;
    @Input('booking-id') bookingID: string;
    @Input('opened') opened: boolean;

    public price: any = {
        total: 0,
        paid: 0,
        rental: 0,
        extension: 0,
        addedKM: 0,
        addedDriver: 0,
        securityPack: 0,
        totalExcess: 0,
        paidExcess: 0
    };
    public duration: number = 0;
    public extension: number = 0;
    public includedKM: number = 0;
    public addedKM: number = 0;
    public addedDriver: number = 0;
    public securityPack: number = 0;

    public excessList: any[] = [];

    public isOpened: boolean = true;
    public loading: boolean = true;
    public expired: boolean = true;
    private deposit : boolean = false
    public paymentDeposit : boolean = false

    constructor(
        private bookingService: BookingService,
        private reservationService: ReservationService,
        private transactionService: TransactionService,
        private router: Router,
    ) {}

    ngOnChanges() {
        if (this.reservationID) {
            this.reservationService.getReservation({ id: this.reservationID }).toPromise().then((response) => {
                let reservation = response.result;
                
                this.resaExpired(reservation)

                this.addedDriver = reservation.renters.length - 1;

                // Duration calculated with an hour of floatment (ISIRAC rule).
                this.duration = Math.ceil((+moment(reservation.end).diff(moment(reservation.start), 'minutes', true).toFixed(0) - 59) / 60 / 24);
                this.includedKM = 100 * this.duration;

                try {
                    this.price.rental = +reservation.attributes.filter(attr => attr.name === 'PRICE')[0].value;
                    this.addedKM = +reservation.attributes.filter(attr => attr.name === 'KILOMETERS')[0].value - this.includedKM;
                    if (this.addedKM < 0) this.addedKM = 0;
                } catch(e) {}
                this.loading = false;
            });
            this.transactionService.getTransactionList({ id: this.reservationID }).toPromise().then((response) => {
                this.price.total = this.getTotal(response);
                this.price.paid = this.getTotalPaid(response);
                this.price.totalExcess = this.getTotalExcess(response);
                this.price.paidExcess = this.getTotalExcessPaid(response);
                this.deposit = this.getBalanceDposit(response)
                this.paymentDeposit = this.getBalancePayment(response, false)
                if (this.deposit){
                    this.router.navigate(['deposit'], { queryParams: {  id: this.reservationID } });
                }
                const transactionsDetails = this.getDetails(response);
                this.setDetails(transactionsDetails);
                if(this.price.total !== this.price.paid ) {
                    if (this.expired == false){
                        this.opened = false;
                        this.isOpened = false;
                    }else{
                        this.opened = true;
                        this.isOpened = true;
                    }
                }
                if(this.price.totalExcess !== this.price.paidExcess ) {
                    if (this.expired == false){
                        this.opened = false;
                        this.isOpened = false;
                    }else{
                        this.opened = true;
                        this.isOpened = true;
                    }
                   
                }
            });
        } else if (this.bookingID) {
            this.bookingService.getSession(this.bookingID).toPromise().then((res) => {
                let booking = res;
                // Duration calculated with an hour of floatment (ISIRAC rule).
                this.duration = Math.ceil((+moment(booking.end).diff(moment(booking.start), 'minutes', true).toFixed(0) - 59) / 60 / 24);

                this.paymentDeposit = this.getBalancePayment(res, true)
                this.price.rental = booking.bookingPrice.paymentPrice / 100;
                this.price.paid = this.price.rental 
                this.includedKM = 100 * this.duration;
                this.addedKM = (booking.bookingOption.quantity * this.duration) - this.includedKM;
                if (this.addedKM < 0) this.addedKM = 0;
                this.price.total = this.price.rental + this.price.addedDriver;
                this.loading = false;
                
            });

        }
        if (this.opened !== null) {
            this.isOpened = this.opened;
        }
    }

    getTotal(liste) {
        let total = 0;
        liste.forEach(item => {
            if(item.type == "PAYMENT" && item.amount.value > 0){
                    total += item.amount.value / 100;
                
            }
        });
        return total;
    }

    getTotalPaid (liste) {
        console.log(liste)
        let total = 0;
        for (const item of liste) {
            if(item.type == "PAYMENT" ){
                    total += item.amount.value / 100;
            }
        };
        return total;
    }

    getTotalExcess(liste) {
        let total = 0;
        liste.forEach(item => {
            if(item.type == "PAYMENT"){
                if(
                    item.label.indexOf("DELAY") == 0 ||
                    item.label.indexOf("DRIVER_DELAY") == 0 ||
                    item.label.indexOf("KM_FEES") == 0 ||
                    item.label.indexOf("FUEL_FEES") == 0 ||
                    item.label.indexOf("FUEL_SERVICE") == 0 ||
                    item.label.indexOf("CLEANING_SERVICE") == 0
                ){
                    total += item.amount.value / 100;
                }
            }
        });
        return total;
    }

    getTotalExcessPaid (liste ) {
        let total = 0;
        for (const item of liste) {
            if(
                item.label.indexOf("DELAY") == 0 ||
                item.label.indexOf("DRIVER_DELAY") == 0 ||
                item.label.indexOf("KM_FEES") == 0 ||
                item.label.indexOf("FUEL_FEES") == 0 ||
                item.label.indexOf("FUEL_SERVICE") == 0 ||
                item.label.indexOf("CLEANING_SERVICE") == 0
            ){
                if (liste.some(t => t.label == item.id)) {
                    total += item.amount.value / 100;
                }
            }
        };
        return total;
    }

    getDetails(datas: any[]) {
        // take an array with all transactions payed
        const transactionsDetails = {
            extension : {},
            addedKM : {},
            addedDriver : {},
            newDriver : {},
            securityPack : {},
            delay : {},
            driverDelay : {},
            kmFees : {},
            fuelFees : {},
            fuelService : {},
            cleaningService: {},
        };
        for (const item in transactionsDetails) {
            transactionsDetails[item].value = 0;
            transactionsDetails[item].quantity = 0;
        }

        datas.forEach(t => {

          for (const r in PricingDetails) {
            const reason = PricingDetails[r];

            if(t.label.indexOf(reason.label) == 0 ) {

            // checking if t is already existing in transactionsDetails
              const actualValue = transactionsDetails[reason.name];

              if(actualValue.label){
                const valueAddition = actualValue.value + t.amount.value/100;
                actualValue.value = (Math.round(valueAddition * 100) / 100);
                actualValue.quantity = actualValue.quantity + this.getQuantity(t);
              } else {
                const details = reason;

                details.value = (Math.round((t.amount.value / 100) * 100) / 100);
                details.quantity = this.getQuantity(t);

                transactionsDetails[reason.name] = details;
              }
            }
          }
        });
        // return an array of transactions payed with details sorted by type
        return transactionsDetails;
    }

    getBalanceDposit(liste) {
        let total = 0;
        liste.forEach(item => {
            if (item.type === "DEPOSIT") {
                total += item.amount.value;
            }
        });
        return total > 0 ? true : false;
    }
    
    getBalancePayment(liste, isBooking) {
        console.log("liste", liste)
        if(isBooking){
            return true
        }
        let total = 0;
        liste.forEach(item => {
            if (item.type === "PAYMENT") {
                total += item.amount.value;
            }
        });
        if (total > 0){
            this.toggleCard()
        }
        return total > 0 ? true : false;
    }

    getQuantity(transac: any): number{
        const index = transac.label.indexOf("Q");
        let quantity = "0";
        if(index > 0) {
            quantity = transac.label.substring(index + 1);
        }
        const res = (Math.round(parseFloat(quantity) * 100) / 100);

        return res;
    };

    setDetails(datas: any) {

        // set option value
        this.price.extension = datas.extension.value ;
        this.extension = datas.extension.quantity;

        this.price.addedKM += datas.addedKM.value ;
        this.addedKM += datas.addedKM.quantity;

        this.price.addedDriver = datas.addedDriver.value + datas.newDriver.value;
        //this.addedDriver = datas.addedDriver.quantity;

        this.price.securityPack = datas.securityPack.value;
        this.securityPack = datas.securityPack.quantity;

        // set excessList
        this.excessList.push(datas.delay, datas.driverDelay, datas.kmFees, datas.fuelFees, datas.fuelService, datas.cleaningService);

    }

    toggleCard() {
        this.isOpened = !this.isOpened;
    }

    goToPayment() {
        this.router.navigate([`additional-payment`], {queryParams: {reservationID: this.reservationID}});
    }
    resaExpired(resa){
        let now = Date.now()
        if (resa.status === "WAITING" && resa.end < now ){
            this.expired = false
        }else{
            this.expired = true 
        }
    }
}
