<form class="start-search-form" [formGroup]="startSearchForm" *ngIf="state === 'start-search'">
    <div class="title">{{ 'ACCESS_POINT.MAP.subtitle' | translate }}</div>
    <div class="input-text" [ngClass]="{ 'input-error': startSearchForm.controls.position.touched && startSearchForm.controls.position.status === 'INVALID' }">
        <div class="input-group">
            <div class="input-prefix">
                <fa-icon [icon]="['far', 'map-marked-alt']" [ngClass]="{ 'red': startSearchForm.controls.position.status === 'VALID' }"></fa-icon>
            </div>
            <input class="prefixed" type="text" #searchInput formControlName="position" (keyup.enter)="searchInput.blur()" [placeholder]="'FORM.placeholder.position' | translate">
        </div>
        <div class="error" *ngIf="startSearchForm.controls.position.touched && startSearchForm.controls.position.errors?.required">
            <fa-icon [icon]="['fas', 'times-circle']"></fa-icon>
            {{ 'CUSTOM_ERROR.required' | translate }}
        </div>
    </div>
    <div class="input-text" [ngClass]="{ 'input-error': startSearchForm.controls.start_date.touched && startSearchForm.controls.start_date.status === 'INVALID' }">
        <div class="input-group">
            <div class="input-prefix">
                <fa-icon [icon]="['far', 'calendar-alt']"></fa-icon>
            </div>
            <p-calendar #start formControlName="start_date" [locale]="momentLocale" [dateFormat]="momentLocale.dateFormat" [showTime]="true"
                [stepMinute]="30" [minDate]="minStartDate" [touchUI]="touchUI" [readonlyInput]="true" inputStyleClass="input-style prefixed"
                [hideOnDateTimeSelect]="false" (onSelect)="detectChangesAsync(true)" (onFocus)="detectChangesAsync(true)" (onBlur)="detectChangesAsync(true)"
                (click)="detectChangesAsync(true)" (onMonthChange)="detectChangesAsync(true)">
                <p-footer><button pButton label="OK" (click)="start.overlayVisible = false; detectChangesAsync(true)"></button></p-footer>
            </p-calendar>
        </div>
        <div class="error"
            *ngIf="startSearchForm.controls.start_date.touched && startSearchForm.controls.start_date.errors?.required">
            <fa-icon [icon]="['fas', 'times-circle']"></fa-icon>
            {{ 'CUSTOM_ERROR.required' | translate }}
        </div>
    </div>
    <div class="input-text" [ngClass]="{ 'input-error': startSearchForm.controls.end_date.touched && startSearchForm.controls.end_date.status === 'INVALID' }">
        <div class="input-group">
            <div class="input-prefix">
                <fa-icon [icon]="['far', 'calendar-alt']"></fa-icon>
            </div>
            <p-calendar #end formControlName="end_date" [locale]="momentLocale" [dateFormat]="momentLocale.dateFormat" [showTime]="true"
                [stepMinute]="30" [minDate]="minEndDate" [touchUI]="touchUI" [readonlyInput]="true" inputStyleClass="input-style prefixed"
                [hideOnDateTimeSelect]="false" (onSelect)="detectChangesAsync(false)" (onFocus)="detectChangesAsync(false)" (onBlur)="detectChangesAsync(false)"
                (click)="detectChangesAsync(false)" (onMonthChange)="detectChangesAsync(false)">
                <p-footer><button pButton label="OK" (click)="end.overlayVisible = false; detectChangesAsync(false)"></button></p-footer>
            </p-calendar>
        </div>
        <div class="error"
            *ngIf="startSearchForm.controls.end_date.touched && startSearchForm.controls.end_date.errors?.required">
            <fa-icon [icon]="['fas', 'times-circle']"></fa-icon>
            {{ 'CUSTOM_ERROR.required' | translate }}
        </div>
    </div>
    <div class="actions">
        <div class="confirm">
            <!-- POC: goToCube() instead of setSearchMap() -->
            <button class="confirm-button" [disabled]="!startSearchForm.valid" (click)="setSearchMap()">
                <span *ngIf="!loading">{{ 'ACCESS_POINT.MAP.submit_search' | translate }}</span>
                <fa-icon *ngIf="loading" [icon]="['far', 'sync']" [spin]="true"></fa-icon>
            </button>
        </div>
        <div class="action-separator">{{ 'GENERIC.or' | translate }}</div>
        <div class="confirm">
            <!-- POC: disabled -->
            <button class="confirm-button" (click)="setSearchMap('around-me')">
                <span *ngIf="!loading">{{ 'ACCESS_POINT.MAP.submit_searchAround' | translate }}</span>
                <fa-icon *ngIf="loading" [icon]="['far', 'sync']" [spin]="true"></fa-icon>
            </button>
        </div>
    </div>
</form>

<div class="search-map" *ngIf="state === 'search-map'">
    <div class="header">
        <div class="header-container">
            <div class="search-infos">
                <div class="search-infos-position">
                    <fa-icon class="red" [icon]="['far', 'map-marked-alt']"></fa-icon>
                    {{ search.location || ('ACCESS_POINT.MAP.around_me' | translate)}}
                </div>
                <div class="search-infos-dates">
                    <fa-icon [icon]="['far', 'calendar-alt']"></fa-icon>
                    <div class="dates">
                        <div>{{ search.start_date | date:'dd MMM yyyy' }}</div>
                        <fa-icon [icon]="['far', 'long-arrow-right']"></fa-icon>
                        <div>{{ search.end_date | date:'dd MMM yyyy' }}</div>
                    </div>
                </div>
            </div>
            <div class="search-edit" (click)="editSearch()">
                <fa-icon [icon]="['fas', 'pen']"></fa-icon>
            </div>
        </div>
        <div class="legend">{{ 'ACCESS_POINT.MAP.marker_legend' | translate }} <fa-icon [icon]="['fas', 'map-marker-alt']">
            </fa-icon>
        </div>
    </div>

    <google-map height="100%" width="100%" [zoom]="map.zoom" [center]="map.center" [options]="map.options">
        <map-marker #markerElem *ngFor="let pin of pinpoints | marker:search.onlyPresto:search.startDate:search.endDate" [position]="pin.center" [options]="pin.options" [icon]="pin.icon" (mapClick)="markerClick(pin)"></map-marker>
    </google-map>

    <div class="search-result" *ngIf="search.agencySelected">
        <div class="confirm" [ngClass]="{ 'no-vehicle': noVehicle === 'true' }" (click)="confirmAgency()">
            <fa-icon [icon]="['far', 'arrow-right']" *ngIf="noVehicle === 'false'"></fa-icon>
            <fa-icon [icon]="['far', 'sync']" [spin]="true" *ngIf="noVehicle === 'loading'"></fa-icon>
            <fa-icon [icon]="['far', 'times']" *ngIf="noVehicle === 'true'"></fa-icon>
        </div>
        <div class="distance">
            {{ 'ACCESS_POINT.distance_from_user' | translate:{value: search.distance} }}
        </div>
        <div class="agency-details">
            <div class="agency-details-name">
                {{ search.agencySelected.name }}
            </div>
            <div class="agency-details-address">
                {{ (search.agencySelected.address.addressPrefix || "") + " "
                    + (search.agencySelected.address.street || "") + ", "
                    + (search.agencySelected.address.zipCode || "") + " "
                    + (search.agencySelected.address.city || "") }}
            </div>
            <div class="agency-details-categories" *ngIf="noVehicle === 'false' && search?.agencySelected?.categories && search?.agencySelected?.categories.length > 0">
                <span *ngIf="search?.agencySelected?.categories?.length === 1">{{ 'ACCESS_POINT.MAP.category' | translate }}</span>
                <span *ngIf="search?.agencySelected?.categories?.length > 1">{{ 'ACCESS_POINT.MAP.categories' | translate }}</span>
                <span *ngFor="let cat of search?.agencySelected?.categories; let i = index"> {{ (getCategoryName(cat.id) | translate) + (i < (search.agencySelected.categories.length - 1) ? ", " : " ") }}</span>
                <span *ngIf="search?.agencySelected?.categories?.length">{{ 'ACCESS_POINT.MAP.available' | translate }}</span>
            </div>
            <div class="agency-details-price" *ngIf="noVehicle === 'false'">
                {{ 'ACCESS_POINT.MAP.prices_from' | translate:{ value: minPrice } }}
            </div>
            <div class="agency-details-categories" *ngIf="noVehicle === 'true'">
                <span>{{ 'ACCESS_POINT.MAP.no_category_avail' | translate }}</span>
            </div>
        </div>
    </div>
</div>
