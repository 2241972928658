import {
    Component,
    ViewChild,
    ElementRef,
    OnInit
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { LanguageService } from 'src/app/services/language';
import { HeaderMenuService } from 'src/app/services/header-menu';
import { BookingService } from 'src/app/services/booking';
import { ItemService } from 'src/app/services/item';
import { ReservationService } from 'src/app/services/reservation';
import { UserService } from 'src/app/services/user';

import * as moment from 'moment';

// http://localhost:4200/detail?id=TT000032EG
// http://localhost:4200/document?booking=TT000032QG&id=1612971932940_409ba59a-73bd-42f1-a290-3f6dcf4c72ed

@Component({
    selector: 'details-reservation',
    templateUrl: './details.html',
    styleUrls: ['./details.scss']
})
export class DetailReservationComponent implements OnInit {
    @ViewChild('externalLink', { static: false }) externalLink: ElementRef;

    public accessID: string;
    public reservationID: string;
    public reservation: any;
    public vehicleID: any;
    public status: string;
    public loading = true

    // View Modifiers
    public isBookingNow: string;
    public isPaymentReady: boolean = false;
    public missingPaye: boolean = false;
    public viewOptions: boolean = false;
    public cguAccepted: boolean = false;
    public isVehicleAvailable: boolean = false;
    public canModifyStartDate: boolean = false;
    public canModifyEndDate: boolean = false;
    public showModifyDates: boolean = false;
    public touchUI: boolean = true;
    public idBooking: string;
    public cards = {
        "pricing": false,
        "deposit": false,
        "vehicle": true,
        "location": false
    };

    constructor(
        private bookingService: BookingService,
        private itemService: ItemService,
        private languageService: LanguageService,
        private reservationService: ReservationService,
        private userService: UserService,
        private router: Router,
        private _activatedRoute: ActivatedRoute,
        private headerMenuService: HeaderMenuService
    ) {
        this.languageService.getObservable().subscribe((lang) => {
            moment.locale(lang.i18n);
        });

        this._activatedRoute.queryParams.subscribe((params) => {
            this.reservationID = params['id'];
        });
    }

    ngOnInit() {
        localStorage.setItem('isVehicleAvailable', "true");
        this.headerMenuService.setShowMenu(true);
        this.headerMenuService.setHeader({
            color: 'default',
            showLogo: true,
            showBack: false,
            showIcon: true
        });

        this.reservationService.getReservation({ id: this.reservationID }).toPromise().then((response) => {
            this.reservation = response.result;
            // console.log("details getReservation: ", this.reservation);

            this.vehicleID = this.reservation.itemId;
            this.itemService.getItem({ id: this.vehicleID }).toPromise().then((response) => {
                let vehicle = response.result;
                this.accessID = vehicle.accessId;
            });

            this.userService.getUser().toPromise().then((response) => {
                let user = response.result;
                this.status = this.getUserStatus(user);
            });

            this.checkBookingTime();
        });

        this.reservationService.reservationBalance({ id: this.reservationID }).toPromise().then((response) => {
            console.log("reservationService.reservationBalance: ", response);
            let prices = response.result;
            for (let i = 0; i < prices.length; i++) {
                if (prices[i].amount.value > 0) {
                    this.missingPaye = true;
                }
            }
        });
    }

    getUserStatus(user) {
        if (!user.address || !user.gender || !user.phoneNumber) return "MISSING_INFORMATIONS";

        if (!user.documents.length) return "NOT_VERIFIED";

        let docs = [];
        for (let i = 0; i < user.documents.length; i++) {
            const element = user.documents[i];
            if (
                element?.status == "PENDING_VERIFICATION"
                || element?.status == "VERIFICATION_FAILURE"
                || element?.status == "VERIFICATION_INITIATED"
            ) docs.push(element?.status);
        }

        if (docs.length === 0) {
            return "VERIFIED";
        } else if (docs.includes("VERIFICATION_FAILURE") || docs.includes("VERIFICATION_INITIATED")) {
            return "NOT_VERIFIED";
        } else if (docs.includes("PENDING_VERIFICATION")) {
            return "WAITING_FOR_VERIFICATION";
        }
    }

    checkBookingTime() {
        let check = 0
        setInterval(() => {
            let isVehicleAvailable = localStorage.getItem('isVehicleAvailable');
            if (moment().isBefore(this.reservation.start)) {
                this.loading = false
                this.isBookingNow = 'not-yet';
            } else if (moment().isAfter(this.reservation.end) && this.reservation.status !== 'OPEN') {
                this.loading = false
                this.isBookingNow = 'expired';
            }  else if (moment().isAfter(this.reservation.end) && this.reservation.status === 'OPEN') {
                this.loading = false
                this.isBookingNow = 'late';
            } else {
                this.isBookingNow = 'now';
                
                if (isVehicleAvailable != "false" && check === 0) {
                    check = 1
                    this.bookingService.itemAvailability({ start: this.reservation.start - 300, end: this.reservation.start - 1, itemId: this.vehicleID }).toPromise().then((response) => {
                        this.isVehicleAvailable = response.result;
                        this.loading = false
                        localStorage.setItem('isVehicleAvailable', response.result);
                    });
                }
            }
        },30);

        
    }

    getBalance(liste) {
        let total = 0;
        liste.forEach(item => {
            if (item.type === "PAYMENT") {
                total += item.amount.value;
            }
        });
        return total;
    }

    goToKey() {
        if (this.status === 'VERIFIED') {
            this.router.navigate(['key'], {
                queryParams: { agency: this.accessID, reservation: this.reservationID }
            });
        }
    }

    goToAdditionalPayment() {
        this.router.navigate(['/additional-payment'], { queryParams: { reservationID: this.reservation.id } });
    }

    toggleCard(cardType: string) {
        this.cards[cardType] = !this.cards[cardType];
    }
}
