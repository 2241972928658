var _extract = (str) => str;

export const APIErrors = {
    // API Error codes
    '-31100': {
        i18n: _extract('INTERFACES.ERROR.API.-31100'),
        action: null
    },
    '-32602': {
        i18n: _extract('INTERFACES.ERROR.API.-32602'),
        action: null
    },
    '-32000': {
        i18n: _extract('INTERFACES.ERROR.API.-32000'),
        action: null
    },

    '1000': {
        i18n: _extract('INTERFACES.ERROR.API.1000'),
        action: null
    },
    '1001': {
        i18n: _extract('INTERFACES.ERROR.API.1001'),
        action: null
    },

    '1100': {
        i18n: _extract('INTERFACES.ERROR.API.1100'),
        action: null
    },
    '1101': {
        i18n: _extract('INTERFACES.ERROR.API.1101'),
        action: 'return-map'
    },
    '1102': {
        i18n: _extract('INTERFACES.ERROR.API.1102'),
        action: null
    },
    '1103': {
        i18n: _extract('INTERFACES.ERROR.API.1103'),
        action: 'return-map'
    },

    '1200': {
        i18n: _extract('INTERFACES.ERROR.API.1200'),
        action: null
    },
    '1201': {
        i18n: _extract('INTERFACES.ERROR.API.1201'),
        action: null
    },
    '1250': {
        i18n: _extract('INTERFACES.ERROR.API.1250'),
        action: null
    },
    '1251': {
        i18n: _extract('INTERFACES.ERROR.API.1251'),
        action: null
    },
    '1252': {
        i18n: _extract('INTERFACES.ERROR.API.1252'),
        action: null
    },
    '1253': {
        i18n: _extract('INTERFACES.ERROR.API.1253'),
        action: null
    },

    '1300': {
        i18n: _extract('INTERFACES.ERROR.API.1300'),
        action: null
    },
    '1350': {
        i18n: _extract('INTERFACES.ERROR.API.1350'),
        action: null
    },

    '1400': {
        i18n: _extract('INTERFACES.ERROR.API.1400'),
        action: null
    },
    '1450': {
        i18n: _extract('INTERFACES.ERROR.API.1450'),
        action: null
    },
    '1454': {
        i18n: _extract('INTERFACES.ERROR.API.1454'),
        action: null
    },

    '1500': {
        i18n: _extract('INTERFACES.ERROR.API.1500'),
        action: null
    },
    '1501': {
        i18n: _extract('INTERFACES.ERROR.API.1501'),
        action: null
    },
    '1503': {
        i18n: _extract('INTERFACES.ERROR.API.1503'),
        action: null
    },
    '1549': {
        i18n: _extract('INTERFACES.ERROR.API.1549'),
        action: 'return-map'
    },
    '1550': {
        i18n: _extract('INTERFACES.ERROR.API.1550'),
        action: null
    },
    '1551': {
        i18n: _extract('INTERFACES.ERROR.API.1551'),
        action: null
    },
    '1552': {
        i18n: _extract('INTERFACES.ERROR.API.1552'),
        action: null
    },
    '1553': {
        i18n: _extract('INTERFACES.ERROR.API.1553'),
        action: null
    },
    '1554': {
        i18n: _extract('INTERFACES.ERROR.API.1554'),
        action: null
    },
    '1555': {
        i18n: _extract('INTERFACES.ERROR.API.1555'),
        action: null
    },
    '1556': {
        i18n: _extract('INTERFACES.ERROR.API.1556'),
        action: null
    },
    '1557': {
        i18n: _extract('INTERFACES.ERROR.API.1557'),
        action: null
    },
    '1558': {
        i18n: _extract('INTERFACES.ERROR.API.1558'),
        action: null
    },
    '1559': {
        i18n: _extract('INTERFACES.ERROR.API.1559'),
        action: null
    },
    '1560': {
        i18n: _extract('INTERFACES.ERROR.API.1560'),
        action: null
    },
    '1561': {
        i18n: _extract('INTERFACES.ERROR.API.1561'),
        action: null
    },
    '1570': {
        i18n: _extract('INTERFACES.ERROR.API.1570'),
        action: null
    },
    '1571': {
        i18n: _extract('INTERFACES.ERROR.API.1571'),
        action: null
    },

    '1600': {
        i18n: _extract('INTERFACES.ERROR.API.1600'),
        action: null
    },
    '1601': {
        i18n: _extract('INTERFACES.ERROR.API.1601'),
        action: null
    },

    '1700': {
        i18n: _extract('INTERFACES.ERROR.API.1700'),
        action: null
    },

    '1800': {
        i18n: _extract('INTERFACES.ERROR.API.1800'),
        action: null
    },
    '1801': {
        i18n: _extract('INTERFACES.ERROR.API.1801'),
        action: null
    },
    '1802': {
        i18n: _extract('INTERFACES.ERROR.API.1802'),
        action: null
    },
    '1803': {
        i18n: _extract('INTERFACES.ERROR.API.1803'),
        action: null
    },
    '1804': {
        i18n: _extract('INTERFACES.ERROR.API.1804'),
        action: null
    },
    '1805': {
        i18n: _extract('INTERFACES.ERROR.API.1805'),
        action: null
    },

    '1900': {
        i18n: _extract('INTERFACES.ERROR.API.1900'),
        action: null
    },
    '1901': {
        i18n: _extract('INTERFACES.ERROR.API.1901'),
        action: null
    },
    '1902': {
        i18n: _extract('INTERFACES.ERROR.API.1902'),
        action: null
    },
    '1903': {
        i18n: _extract('INTERFACES.ERROR.API.1903'),
        action: null
    },
    '1904': {
        i18n: _extract('INTERFACES.ERROR.API.1904'),
        action: null
    },
    '1905': {
        i18n: _extract('INTERFACES.ERROR.API.1905'),
        action: null
    },
    '1906': {
        i18n: _extract('INTERFACES.ERROR.API.1906'),
        action: null
    },

    '2000': {
        i18n: _extract('INTERFACES.ERROR.API.2000'),
        action: null
    },
    '2001': {
        i18n: _extract('INTERFACES.ERROR.API.2001'),
        action: null
    },
    '2002': {
        i18n: _extract('INTERFACES.ERROR.API.2002'),
        action: null
    },
    '2003': {
        i18n: _extract('INTERFACES.ERROR.API.2003'),
        action: null
    },
    '2004': {
        i18n: _extract('INTERFACES.ERROR.API.2004'),
        action: null
    },
    '2005': {
        i18n: _extract('INTERFACES.ERROR.API.2005'),
        action: null
    },
    '2006': {
        i18n: _extract('INTERFACES.ERROR.API.2006'),
        action: null
    },
    '2101': {
        i18n: _extract('INTERFACES.ERROR.API.2101'),
        action: null
    }
};
