// Ngx Translate
let _extract = str => str;

export const Categories = {
    A: {
        title: _extract("INTERFACES.CATEGORIES.A.title"), // 'Economique'
        carTypes: ['108', 'Up!'],
        pictures: ['CATA-1', 'CATA-2'],
        viewPic: 0,
        fuelType: [_extract("FUEL_TYPE.GASOLINE")],
        transmission: _extract("ENGINE_TYPE.MANUAL"),
        doors: 3,
        seats: 4,
        luggage: 1,
        licenseYears: 3,
        equipments: [],
        damagePrice: 900,
        theftPrice: 1800,
        kmPrice: 0.24
    },
    B: {
        title: _extract("INTERFACES.CATEGORIES.B.title"), // 'Citadine'
        carTypes: ['Clio', 'Polo', '208'],
        pictures: ['CATB-1', 'CATB-2', 'CATB-3'],
        viewPic: 0,
        fuelType: [_extract("FUEL_TYPE.GASOLINE"), _extract("FUEL_TYPE.DIESEL")],
        transmission: _extract("ENGINE_TYPE.MANUAL"),
        doors: 5,
        seats: 5,
        luggage: 2,
        licenseYears: 2,
        equipments: [_extract("INTERFACES.CATEGORIES.equipment.air_conditioning"), _extract("INTERFACES.CATEGORIES.equipment.blutooth_radio")],
        damagePrice: 900,
        theftPrice: 1800,
        kmPrice: 0.24
    },
    C: {
        title: _extract("INTERFACES.CATEGORIES.C.title"), // 'Affaire',
        carTypes: ['Golf', '308', 'Mégane'],
        pictures: ['CATC-1', 'CATC-2', 'CATC-3'],
        viewPic: 0,
        fuelType: [_extract("FUEL_TYPE.GASOLINE"), _extract("FUEL_TYPE.DIESEL")],
        transmission: _extract("ENGINE_TYPE.MANUAL"),
        doors: 5,
        seats: 5,
        luggage: 3,
        licenseYears: 3,
        equipments: [_extract("INTERFACES.CATEGORIES.equipment.air_conditioning"), _extract("INTERFACES.CATEGORIES.equipment.blutooth_radio")],
        damagePrice: 1200,
        theftPrice: 2400,
        kmPrice: 0.24
    },
    D: {
        title: _extract("INTERFACES.CATEGORIES.D.title"), // 'Confort',
        carTypes: ['3008', 'Qashqai', 'Renegade'],
        pictures: ['CATD-1', 'CATD-2', 'CATD-3'],
        viewPic: 0,
        fuelType: [_extract("FUEL_TYPE.DIESEL")],
        transmission: _extract("ENGINE_TYPE.MANUAL"),
        doors: 5,
        seats: 5,
        luggage: 4,
        licenseYears: 3,
        equipments: [_extract("INTERFACES.CATEGORIES.equipment.air_conditioning"), _extract("INTERFACES.CATEGORIES.equipment.blutooth_radio")],
        damagePrice: 1500,
        theftPrice: 3000,
        kmPrice: 0.24
    },
    E: {
        title: _extract("INTERFACES.CATEGORIES.E.title"), // 'Multispace',
        carTypes: ['Touran', '5008', 'X-trail'],
        pictures: ['CATE-1', 'CATE-2', 'CATE-3'],
        viewPic: 0,
        fuelType: [_extract("FUEL_TYPE.DIESEL")],
        transmission: _extract("ENGINE_TYPE.MANUAL"),
        doors: 5,
        seats: 5,
        luggage: 4,
        licenseYears: 3,
        equipments: [_extract("INTERFACES.CATEGORIES.equipment.air_conditioning"), _extract("INTERFACES.CATEGORIES.equipment.blutooth_radio")],
        damagePrice: 1500,
        theftPrice: 3000,
        kmPrice: 0.24
    },
    F: {
        title: _extract("INTERFACES.CATEGORIES.F.title"), // 'Minibus',
        carTypes: ['Traveler', 'SpaceTourer'],
        pictures: ['CATF-1', 'CATF-2'],
        viewPic: 0,
        fuelType: [_extract("FUEL_TYPE.DIESEL")],
        transmission: _extract("ENGINE_TYPE.MANUAL"),
        doors: 4,
        seats: 9,
        luggage: 4,
        licenseYears: 5,
        equipments: [_extract("INTERFACES.CATEGORIES.equipment.air_conditioning")],
        damagePrice: 1800,
        theftPrice: 3600,
        kmPrice: 0.24
    },
    G: {
        title: _extract("INTERFACES.CATEGORIES.G.title"), // 'Monospace',
        carTypes: ['Espace', 'Sharan'],
        pictures: ['CATG-1', 'CATG-2'],
        viewPic: 0,
        fuelType: [_extract("FUEL_TYPE.DIESEL")],
        transmission: _extract("ENGINE_TYPE.MANUAL"),
        doors: 5,
        seats: 7,
        luggage: 4,
        licenseYears: 5,
        equipments: [_extract("INTERFACES.CATEGORIES.equipment.air_conditioning"), _extract("INTERFACES.CATEGORIES.equipment.blutooth_radio")],
        damagePrice: 1800,
        theftPrice: 3600,
        kmPrice: 0.24
    },
    H: {
        title: _extract("INTERFACES.CATEGORIES.H.title"), // 'Berline',
        carTypes: ['Talisman', 'A5', 'Passat'],
        pictures: ['CATH-1', 'CATH-2', 'CATH-3'],
        viewPic: 0,
        fuelType: [_extract("FUEL_TYPE.DIESEL")],
        transmission: _extract("ENGINE_TYPE.MANUAL"),
        doors: 5,
        seats: 5,
        luggage: 5,
        licenseYears: 5,
        equipments: [_extract("INTERFACES.CATEGORIES.equipment.air_conditioning"), _extract("INTERFACES.CATEGORIES.equipment.blutooth_radio")],
        damagePrice: 1800,
        theftPrice: 3600,
        kmPrice: 0.24
    }
};

// List possible status for translations
_extract("RESERVATION.STATUS.WAITING_FOR_PAYMENT");
_extract("RESERVATION.STATUS.WAITING_FOR_DEPOSIT");
_extract("RESERVATION.STATUS.DEPOSIT_FAILED");
_extract("RESERVATION.STATUS.PAYMENT_FAILED");
_extract("RESERVATION.STATUS.DEPOSIT_DONE");
_extract("RESERVATION.STATUS.PAYMENT_DONE");

_extract("RESERVATION.STATUS.RESERVATION_WAITING");
_extract("RESERVATION.STATUS.RESERVATION_CANCELED");
_extract("RESERVATION.STATUS.RESERVATION_OPEN");
_extract("RESERVATION.STATUS.RESERVATION_RESERVED");
_extract("RESERVATION.STATUS.RESERVATION_CLOSED");

_extract("RESERVATION.STATUS.DELIVERY");
_extract("RESERVATION.STATUS.IMPRODUCTIF");
_extract("RESERVATION.STATUS.OPEN");

// List of transmissions
_extract("TRANSMISSION.MANUAL");
_extract("TRANSMISSION.AUTOMATIC");

// List of unused fuel types yet
_extract("FUEL_TYPE.ELECTRIC");
_extract("FUEL_TYPE.HYDROGEN");

// List of motives
_extract("RESERVATION.AGENT.DETAILS.motive.REPAIR");
_extract("RESERVATION.AGENT.DETAILS.motive.DELIVERY");


_extract("ENGINE_TYPE.MANUAL");
_extract("ENGINE_TYPE.AUTOMATIC");


_extract("ACCESS_POINT.TYPE.ACCESS_POINT");
_extract("ACCESS_POINT.TYPE.COUNTER");

_extract("ACCESS_POINT.STATUS.OPEN");
_extract("ACCESS_POINT.STATUS.PAUSE");
_extract("ACCESS_POINT.STATUS.CLOSE");
