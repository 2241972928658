import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';

import { NotificationManager } from 'src/app/notification/notification';

import { APIErrors } from 'src/app/interfaces/error.api';
import { KeycloakService } from 'keycloak-angular';
import { environment } from 'src/environments/environment';

let _extract = str => str;

@Injectable({
    providedIn: 'root'
})
export class ErrorManager {

    private APIErrors = APIErrors;

    constructor(
        private router: Router,
        private translate: TranslateService,
        private notificationManager: NotificationManager,
        private keycloakService: KeycloakService,
    ) {}

    handleCode(code: string) {
        console.log("handleCode: ", code);
        if (code == '401' ){
            console.log('auth')
            localStorage.clear();
        this.keycloakService.clearToken()
        this.keycloakService.logout(environment.URL_BACK);
        }
        let error = this.APIErrors[code];

        if (error) {
            this.notificationManager.addNotification(this.translate.instant("INTERFACES.ERROR.API." + code), "error", 10000, true);
            if (error.action === 'return-map') {
                this.router.navigate(['map']);
            } else if (error.action === 'return-auth') {
                this.router.navigate(['auth']);
            } else if (error.action === 'refresh') {
                window.location.reload();
            }
        } else {
            // this.notificationManager.addNotification(this.translate.instant(_extract("INTERFACES.ERROR.API.unknown")), "error", 10000, true);
        }
    }
}
