<div class="loading" *ngIf="loading">
    <fa-icon [icon]="['far', 'circle-notch']" [spin]="true"></fa-icon>
</div>

<div class="header" *ngIf="!loading">
    {{ 'RESERVATION.reservation_id' | translate: { value: reservationData?.id } }}
</div>

<div class="card dates row" *ngIf="!loading">
    <fa-icon [icon]="['far', 'calendar-alt']"></fa-icon>
    <div class="date">{{ reservationData?.start | date: 'dd MMM yyyy HH:mm' }}</div>
    <fa-icon [icon]="['far', 'long-arrow-right']"></fa-icon>
    <div class="date">{{ reservationData?.end | date: 'dd MMM yyyy HH:mm' }}</div>
</div>

<div class="card warning-edl" *ngIf="!loading && !reservationData.checkInId">
    <div class="row">
        <fa-icon [icon]="['far', 'exclamation-circle']"></fa-icon>
        <div class="info">{{ 'KEY.do_check_warning' | translate }}</div>
    </div>
    <hr class="separator" />
    <div class="row">
        <div class="label">{{ 'KEY.do_check_in' | translate }}</div>
        <div class="button" (click)="doCheck('IN')">
            <fa-icon [icon]="['far', 'arrow-right']"></fa-icon>
        </div>
    </div>
</div>

<div class="card vehicle-infos" *ngIf="!loading">
    <div class="row">
        <div class="title">{{ 'KEY.your_vehicle' | translate }}</div>
    </div>

    <div class="row">
        <div class="brand">{{ itemData?.brand + ' ' + itemData?.model }} - {{ itemData?.id }}</div>
        <div class="vehicle-location">
            
        </div>
    </div>

    <hr class="separator" />

    <div class="key">
        <div class="open" [ngClass]="{ active: !doorsStatus }" (click)="setDoorsLock(false)">
            <fa-icon [icon]="['fas', 'unlock-alt']" *ngIf="keyAction !== 'open'"></fa-icon>
            <fa-icon [icon]="['far', 'circle-notch']" [spin]="true" *ngIf="keyAction === 'open'"></fa-icon>
        </div>
        <div class="close" [ngClass]="{ active: doorsStatus }" (click)="setDoorsLock(true)">
            <fa-icon [icon]="['fas', 'lock-alt']" *ngIf="keyAction !== 'close'"></fa-icon>
            <fa-icon [icon]="['far', 'circle-notch']" [spin]="true" *ngIf="keyAction === 'close'"></fa-icon>
        </div>
    </div>
    <div class="doors-status">
        <span *ngIf="!doorsStatus">{{ 'KEY.doors_opened' | translate }}</span>
        <span *ngIf="doorsStatus">{{ 'KEY.doors_closed' | translate }}</span>
    </div>

    <div class="fuel-status" *ngIf="checks.in">
        <div class="type">{{ 'FUEL_TYPE.' + fuelStatus.type | translate }}</div>
        <div class="gauge">
            <mat-progress-bar [value]="fuelStatus.percent" [bufferValue]="'100'"></mat-progress-bar>
        </div>
        <div class="row">
            <div class="info">
                {{ fuelStatus.start }} {{ fuelStatus.unit }} {{ 'KEY.at_start' | translate }}
            </div>
            <div class="counter">
                {{ fuelStatus.current }} / {{ fuelStatus.capacity }} {{ fuelStatus.unit }}
            </div>
        </div>
    </div>

    <div class="km-status" *ngIf="checks.in">
        <div class="gauge">
            <mat-progress-bar [value]="distStatus.percent" [bufferValue]="'100'"></mat-progress-bar>
        </div>
        <div class="row">
            <div class="info">
                {{ 'KEY.dist_plan' | translate: { value: distStatus.plan } }}
            </div>
            <div class="type"></div>
            <div class="counter">
                {{ distStatus.current | number: '1.' }} / {{ (distStatus.start + distStatus.plan | number: '1.')?.replace(',', ' ') }} {{ distStatus.unit }}
            </div>
        </div>
    </div>
</div>

<div class="actions" *ngIf="!loading">
    <!-- EDL OUT & Edn Booking -->
    <div class="confirm" *ngIf="reservationData.checkInId && !reservationData.checkOutId">
        <button class="confirm-button" (click)="doCheck('OUT')">
            {{ 'RESERVATION.end' | translate }}
        </button>
    </div>

    <!-- External link to simulate click -->
    <a #externalLink style="opacity: 0"></a>
</div>

<div class="card agency-infos" *ngIf="!loading">
    <access-point [plate]="itemData?.id"></access-point>
</div>
