import { Injectable } from "@angular/core";
import { ActivatedRoute, Resolve } from "@angular/router";
import { Observable, of } from "rxjs";
import { ReservationService } from "../services/reservation";

@Injectable()
export class ReservationResolver implements Resolve<any> {
  idReservation: string
  constructor(private reservationService: ReservationService, private activatedRoute: ActivatedRoute) {}
    resolve(): Observable<any> {
      this.activatedRoute.queryParams.subscribe((params) => {
          this.idReservation = params['id'];
      });
      if(this.idReservation){
        return this.reservationService.getReservation({ id: this.idReservation});
      }
  }

}
