import { Component, OnInit } from '@angular/core';

import { Transactions } from 'src/app/interfaces/models/additional';

import { HeaderMenuService } from 'src/app/services/header-menu';

import { Router } from '@angular/router';

@Component({
  selector: 'app-link-payment-confirmation',
  templateUrl: './confirmation.html',
  styleUrls: ['./confirmation.scss'],
})
export class LinkPaymentConfirmationComponent implements OnInit {

  public init: boolean = false;
  public reservationID: number;
  public total: number;
  public transactionsData: Transactions[];

  constructor(
    private router: Router,
    private headerMenuService: HeaderMenuService,
  ) { }

  ngOnInit(): void {
    this.headerMenuService.setShowMenu(true);
    this.headerMenuService.setHeader({
        color: 'default',
        showLogo: true,
        showBack: false,
        showIcon: true
    });

    this.fromLocalStorage();
  }

  fromLocalStorage () {

    if(localStorage.reservationID &&
      localStorage.total &&
      localStorage.transactionsDetails)
    {
      localStorage.done = true;
      this.reservationID = localStorage.reservationID;
      this.total = localStorage.total;
      this.transactionsData = JSON.parse(localStorage.transactionsDetails);
      localStorage.removeItem("total");
      localStorage.removeItem("transactionsDetails");
      this.init = true;
    } else {
      if(localStorage.error){
        this.reservationID = localStorage.reservationID;
        localStorage.removeItem("reservationID");
        localStorage.removeItem("error");
        this.router.navigateByUrl(`additional-payment?reservationID=${this.reservationID}`);
      } else {
        this.router.navigate(["list-bookings"]);
      }
    }
  }

}
