import {
    Input,
    Component,
    ViewChild,
    ElementRef,
    OnInit,
    Output,
    EventEmitter
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Router, ActivatedRoute } from '@angular/router';


import { NotificationManager } from 'src/app/notification/notification';
import { TransactionService } from 'src/app/services/transaction';
import { LanguageService } from 'src/app/services/language';

import { environment } from 'src/environments/environment';
import { BookingService } from 'src/app/services/booking';
import TrackingService from 'src/app/utils/trackingService';

interface Window {
    onStatusChange: Function;
}

@Component({
    selector: 'transaction',
    templateUrl: './transaction.html',
    styleUrls: ['./transaction.scss']
})
export class TransactionComponent implements OnInit {
    @Input() type: String;
    @Input('booking-id') bookingID: string;
    @Input('reservation-id') reservationID: string;
    @Input('prices') prices: number;
    @ViewChild('PaylineWidget', { static: false }) paylineWidget: ElementRef;
    @Output() CGUClicked = new EventEmitter<any>();

    public token: string
    public tokenParam: string
    public window: Window;
    public loading: boolean;
    private counter = 0

    public transactionDone: boolean = false;

    constructor(
        private translateService: TranslateService,
        private notificationManager: NotificationManager,
        private transactionService: TransactionService,
        private languageService: LanguageService,
        private bookingService: BookingService,
        private router: Router,
        private activatedRoute: ActivatedRoute,
        private trackingService: TrackingService
    ) {
        this.activatedRoute.queryParams.subscribe(params => {

            if (params.paymentEndpoint) {
                this.tokenParam = params.token;
                localStorage.setItem('3DS', "true");
            }
        });
    }

    async ngOnInit() {
        let self = this;
        this.loading = false;
        if (this.type === "deposit"){
             let isPaid =  await this.isDepositPaid(this.reservationID)
             console.log("isPaid", isPaid)
            if (isPaid){
                this.router.navigate(['detail'], { queryParams: { id: this.reservationID } });
                return
            }
        }

        window['onStatusChange'] = (params) => {
            if (params.state == "PAYMENT_METHODS_LIST") {
                if (self.type === "payment") {
                    console.log("PAYMENT_METHODS_LIST", params)
                    self.transactionService.verifyTokenPayment({ token: self.token, bookingId: self.bookingID }).toPromise().then((res) => {
                        console.log(res)
                    }).catch((e) => {
                        console.log(e)
                        self.notificationManager.addNotification(this.translateService.instant("INTERFACES.ERROR.API.unknown"), "error", 5000, true);
                        this.router.navigate(['map'])

                    })
                } else if (self.type === "deposit") {
                    self.transactionService.verifyTokenDeposit({ token: self.token, reservationId: self.reservationID }).toPromise().then((res) => {
                        console.log(res)
                    }).catch((e) => {
                        console.log(e)
                        self.notificationManager.addNotification(this.translateService.instant("INTERFACES.ERROR.API.unknown"), "error", 5000, true);
                        this.router.navigate(['list-bookings'])
                    })
                }
            } else if (params.state === "PAYMENT_SUCCESS") {
                self.CGUClicked.emit("true")
                self.loading = true;

                if (self.type === "payment") {
                    this.bookingService.getSession(this.bookingID).toPromise().then((res) => {
                        this.trackingService.trackPaymentSuccess(res);
                    });
                } else if (self.type === "deposit") {
                    const idBooking = localStorage.getItem('idBooking');
                    this.bookingService.getSession(idBooking).toPromise().then((res) => {
                        this.trackingService.trackDepositSuccess(res)
                    });
                }
                this.recurse(self)
            } else if (params.state == "PAYMENT_FAILURE") {
                self.CGUClicked.emit("true")
                self.loading = true;
                if (self.type === "payment") {
                    this.bookingService.getSession(this.bookingID).toPromise().then((res) => {
                        if (res.reservationId) {
                            self.loading = false;

                            localStorage.setItem("reservationId", res.reservationId);
                            localStorage.setItem("payment", "true");
                            localStorage.setItem("deposit", "false");
                        } else if (res.error) {
                            self.loading = false;
                            localStorage.setItem('3DS', "false");
                            localStorage.setItem("PAYMENT_FAILURE", "true");
                            localStorage.setItem("payment", "false");
                            localStorage.setItem("deposit", "false");
                        } else {
                            self.loading = false;
                            localStorage.setItem('3DS', "false");
                            localStorage.setItem("PAYMENT_FAILURE", "true");
                            localStorage.setItem("payment", "false");
                            localStorage.setItem("deposit", "false");
                        }
                    });
                } else if (self.type === "deposit") {
                    this.bookingService.getSessionByResa(self.reservationID).toPromise().then((res) => {
                        if (res.depositTransactionPaid) {
                            self.loading = false;

                            localStorage.setItem("reservationId", self.reservationID);
                            localStorage.setItem("payment", "false");
                            localStorage.setItem("deposit", "true");
                        } else if (res.error) {
                            self.loading = false;
                            localStorage.setItem('3DS', "false");
                            localStorage.setItem("PAYMENT_FAILURE", "true");
                            localStorage.setItem("payment", "true");
                            localStorage.setItem("deposit", "false");
                        } else {
                            self.loading = false;
                            localStorage.setItem('3DS', "false");
                            localStorage.setItem("PAYMENT_FAILURE", "true");
                            localStorage.setItem("payment", "true");
                            localStorage.setItem("deposit", "false");
                        }
                    });
                }
            } else if (params.state === "TOKEN_EXPIRED") {
                localStorage.setItem('3DS', "true");
                self.CGUClicked.emit("true")
                localStorage.setItem("token_expired", "true");
                localStorage.setItem("payment", "false");
                localStorage.setItem("deposit", "false");
            }
        }

        this.initPayment();
    }

    setWidget() {
        let script = document.createElement('script');
        script.type = 'text/javascript';
        script.async = true;
        script.text = `function statusChange(status) { window['onStatusChange'](status) }; function status(status) { window['onStatus'](status) };`;
        this.paylineWidget.nativeElement.appendChild(script);

        let library = document.createElement('script');
        library.src = environment.paylineBaseUrl + '/scripts/widget-min.js';
        library.type = 'text/javascript';
        library.async = false;
        this.paylineWidget.nativeElement.appendChild(library);

        let style = document.createElement('link');
        style.setAttribute('href', environment.paylineBaseUrl + '/styles/widget-min.css');
        style.setAttribute('rel', 'stylesheet');
        this.paylineWidget.nativeElement.appendChild(style);
    }

    unsetWidget() {
        let elements = this.paylineWidget.nativeElement.children;
        for (let i = elements.length - 1; i >= 0; i--) {
            this.paylineWidget.nativeElement.removeChild(elements[i]);
        }
    }

    async initPayment() {
        if (this.type === 'payment') {
            let input = {
                bookingId: this.bookingID,
                appInformation: {
                    onSuccess: window.location.origin + "/deposit",
                    onCancel: window.location.href,
                    language: this.languageService.getLang().type.toLowerCase(), // enum [en, fr]
                    colorDepth: 48,
                    screen: {
                        width: 500,
                        height: 500
                    }
                }
            };

            this.transactionService.getTokenPayment(input).subscribe((res) => {
                if (this.tokenParam == undefined) {
                    this.token = res.token;
                    this.setWidget();
                } else {
                    this.token = this.tokenParam;
                    this.setWidget()
                }
            });


        } else {
            let input = {
                reservationId: this.reservationID,
                appInformation: {
                    onSuccess: window.location.origin + "/detail",
                    onCancel: window.location.href,
                    language: this.languageService.getLang().type.toLowerCase(), // enum [en, fr]
                    colorDepth: 48,
                    screen: {
                        width: 500,
                        height: 500
                    }
                }
            };
            this.transactionService.getTokenDeposit(input).subscribe((res) => {
                if (this.tokenParam == undefined) {
                    this.token = res.token;
                    this.setWidget();
                } else {
                    this.token = this.tokenParam;
                    this.setWidget()

                }
            });
        }
    }

    recurse(self) {
        this.counter++;
        if (this.counter <= 30 ){
            if (self.type === "payment") {
                this.bookingService.getSession(this.bookingID).toPromise().then((res) => {
                    console.log(JSON.stringify(res))
                    if (res.reservationId) {
                        self.loading = false;
                        localStorage.setItem("reservationId", res.reservationId);
                        localStorage.setItem("payment", "true");
                        localStorage.setItem("deposit", "false");
                    } else if (res.error) {
                        localStorage.setItem('3DS', "false");
                        self.loading = false;
                        self.notificationManager.addNotification(this.translateService.instant("INTERFACES.ERROR.API.unknown"), "error", 5000, true);
                        //window.location.reload();
                    } else {

                        setTimeout(() =>{
                            this.recurse(self)
                        }, 1000);
                    }
                });
            } else if (self.type === "deposit") {
                this.bookingService.getSessionByResa(self.reservationID).toPromise().then((res) => {
                    console.log(JSON.stringify(res))
                    if (res.depositTransactionPaid) {
                        self.loading = false;
                        localStorage.setItem("reservationId", self.reservationID);
                        localStorage.setItem("payment", "false");
                        localStorage.setItem("deposit", "true");
                    } else if (res.error) {
                        localStorage.setItem('3DS', "false");
                        self.loading = false;
                        self.notificationManager.addNotification(this.translateService.instant("INTERFACES.ERROR.API.unknown"), "error", 5000, true);
                        //window.location.reload();
                    } else {
                        setTimeout(() =>{
                            this.recurse(self)
                        }, 1000);
                    }
                }).catch(e => {
                    setTimeout(() =>{
                        this.recurse(self)
                    }, 1000);
                });
            }

        }else {
            localStorage.setItem('3DS', "false");
            if (self.type === "deposit"){
                this.router.navigate(['list-bookings'])
                localStorage.setItem("payment", "true");
                localStorage.setItem("deposit", "false");
            }else if (self.type === "payment"){
                this.router.navigate(['vehicle'])
                localStorage.setItem("payment", "false");
                localStorage.setItem("deposit", "false");
            }
        }

    }

    // TODO: remove once status READY is integrated in API
    isDepositPaid(id: string): Promise<boolean> {
        return new Promise<boolean>((resolve) => {
            this.transactionService.getTransactionList({ id: id }).toPromise().then(response => {
                if (response.length > 0) {
                    if (response.filter(t => t.type === "DEPOSIT" && t.amount.value < 0).length) resolve(true);
                    else resolve(false);
                } else {
                    resolve(false);
                }
            });
        });
    }
}
