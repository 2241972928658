import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

import { environment } from "src/environments/environment";

@Injectable()
export class LinkService {

  constructor (private http: HttpClient) {}

  public getLinkToken(input: any): Observable<any> {
    return this.http.post<any>(environment.BFF + "link/token", input);
  }

  public verifyTokenPaymentLink(input: any): Observable<any> {
    return this.http.post<any>(environment.BFF + "link/token/verify", input);
  }

}