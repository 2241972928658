import { Directive, Input, ElementRef } from "@angular/core";

@Directive({
    selector: '[img-cpr]'
})
export class ImageCompressed {
    public src
    @Input("img-cpr")
    set setSrc(value) {
        this.src = value;
        this.compressImage();
    }

    constructor(
        private el: ElementRef
    ) {}

    async compressImage() {
        // Create an off-screen canvas
        var canvas = document.createElement('canvas'),
        ctx = canvas.getContext('2d');

        // Set its dimension to target size
        canvas.width = 65;
        canvas.height = 65;

        // Draw source image into the off-screen canvas:
        let image = new Image();
        image.crossOrigin = "Anonymous";
        image.onload = () => {
            ctx.drawImage(image, 0, 0, 65, 65);
            this.el.nativeElement.appendChild(canvas);
        };
        image.src = this.src;
    }
}
